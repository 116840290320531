import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ACTION_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";

export const deleteActionParams = (actionId: number): AxiosRequestConfig =>
  ({
    url: ACTION_URL(actionId),
    method: "DELETE"
  } as AxiosRequestConfig);

export const deleteAction = async (
  reviewId: number
): Promise<AxiosResponse<void>> =>
  await axiosInstance.request(deleteActionParams(reviewId));
