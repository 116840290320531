import { ReactElement, useState } from "react";
import CategoryEditForm from "../../components/app/CategoryEditForm";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import SnackbarErrorContext from "../../components/shared/SnackbarErrorContext";
import { PageTitle } from "../../components/shared/text/TextElements";

const CategoryEdit = (): ReactElement => {
  const [snackbarErrorMessageKey, setSnackbarErrorMessageKey] = useState("");
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle>{t("categoryMenagement")}</PageTitle>
      <Snackbar
        open={snackbarErrorMessageKey.length !== 0}
        autoHideDuration={10000}
        onClose={() => setSnackbarErrorMessageKey("")}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          {t(snackbarErrorMessageKey)}
        </MuiAlert>
      </Snackbar>
      <SnackbarErrorContext.Provider
        value={{
          snackbarErrorMessageKey,
          setSnackbarErrorMessageKey
        }}
      >
        <CategoryEditForm />
      </SnackbarErrorContext.Provider>
    </PageContainer>
  );
};

export default CategoryEdit;
