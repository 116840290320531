import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { SideDrawerButtonContainer } from "../NavBarElements";
import {
  MButtonMaxWidth,
  MButtonWide
} from "../../../shared/buttons/ButtonElements";
import ConditionalHOC from "../../../../hoc/ConditionalHOC";
import ProfileLinkArray from "../../../shared/links/ProfileLinkArray";
import LogOut from "../../../shared/links/LogOut";
import useAuth from "../../../../hooks/useAuth";

const SmallButtonGroup = (): ReactElement => {
  const { t } = useTranslation();
  const { isLoggedIn, redirectToLogin, redirectToRegister } = useAuth();

  return (
    <ConditionalHOC
      condition={isLoggedIn}
      onFalse={() => (
        <SideDrawerButtonContainer>
          <MButtonMaxWidth
            onClick={redirectToRegister}
            variant="contained"
            color="secondary"
          >
            {t("registerAttempt")}
          </MButtonMaxWidth>
          <MButtonWide
            onClick={redirectToLogin}
            variant="contained"
            color="secondary"
          >
            {t("loginAttempt")}
          </MButtonWide>
        </SideDrawerButtonContainer>
      )}
      onTrue={() => (
        <>
          <ProfileLinkArray />
          <LogOut />
        </>
      )}
    />
  );
};

export default SmallButtonGroup;
