import { ReactElement, useContext } from "react";
import { getReviewsParams } from "../../../api/reviews";
import ReviewListProps from "./models/ReviewListProps";
import { useAxios } from "../../../api/useAxios";
import ReviewDTO from "./models/ReviewDTO";
import { useTranslation } from "react-i18next";
import { deleteReview } from "../../../api/deleteReview";
import ReviewListView from "./ReviewListView";
import ReviewContext from "./ReviewContext";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";

const ReviewList = ({ targetUserId }: ReviewListProps): ReactElement => {
  // conditional rendering should be done in the parent component (profile page)

  const { t } = useTranslation();
  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  const { data, setData, loading } = useAxios<undefined, ReviewDTO[]>(
    getReviewsParams(targetUserId),
    t("genericError"),
    setSnackbarErrorMessageKey
  );

  const onDelete = (reviewId: number) => {
    deleteReview(reviewId)
      .then(
        () => {
          const filteredData = data.filter((review) => review.id !== reviewId);
          setData(filteredData);
        },
        () => {
          setSnackbarErrorMessageKey(t("genericError"));
        }
      )
      .catch(() => {
        setSnackbarErrorMessageKey(t("genericError"));
      });
  };

  if (loading || !Array.isArray(data)) {
    return <div>Loading reviews...</div>;
  } else {
    return (
      <ReviewContext.Provider
        value={{
          reviews: data,
          setReviews: setData,
          onDelete
        }}
      >
        <ReviewListView />
      </ReviewContext.Provider>
    );
  }
};

export default ReviewList;
