import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ActionSignUpDialogProps from "./models/ActionSignUpDialogProps";

import { v4 as uuid } from "uuid";
import { MButton, MButtonContrast } from "../buttons/ButtonElements";
import { DialogContainer } from "./ActionSignUpDialogElements";

const ActionSignUpDialog = ({
  perks,
  alreadySelectedPerks,
  title,
  confirmButtonTitle,
  ...dialogProps
}: ActionSignUpDialogProps): ReactElement => {
  const { open, onClose, onSubmit, ...other } = dialogProps;
  const [selectedPerkIds, setSelectedPerkIds] = useState<number[]>(other.value);

  const { t } = useTranslation();

  const handleChange = (
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: ChangeEvent<any>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedPerkIds([
        ...selectedPerkIds,
        event.target.value as unknown as number
      ]);
    } else {
      setSelectedPerkIds(
        selectedPerkIds.filter(
          (id) => id != (event.target.value as unknown as number)
        )
      );
    }
  };

  const handleCancel = () => {
    onClose();
    setSelectedPerkIds([]);
  };

  const handleOk = () => {
    onSubmit(selectedPerkIds);
    setSelectedPerkIds([]);
  };

  useEffect(() => {
    setSelectedPerkIds(alreadySelectedPerks);
  }, []);

  return (
    <Dialog
      open={open}
      aria-labelledby="confirmation-dialog-title"
      maxWidth="xs"
      {...other}
      color="primary"
    >
      <DialogTitle id="confirmation-dialog-title">{t(title)}</DialogTitle>
      <DialogContent dividers>
        <DialogContainer>
          {perks
            .sort((perk1, perk2) => perk1.type.localeCompare(perk2.type))
            .map((perk) => {
              const perkJoined = `${perk.type} - ${perk.name}`;
              return (
                <FormControlLabel
                  control={<Checkbox key={uuid()} />}
                  label={perkJoined}
                  value={perk.id}
                  key={uuid()}
                  checked={selectedPerkIds.some((id) => id == perk.id)}
                  onChange={handleChange}
                />
              );
            })}
        </DialogContainer>
      </DialogContent>
      <DialogActions>
        <MButtonContrast
          variant="contained"
          color="secondary"
          onClick={handleOk}
          disabled={
            alreadySelectedPerks.length == 0 && selectedPerkIds.length == 0
          }
        >
          {alreadySelectedPerks.length > 0
            ? t("update")
            : t(confirmButtonTitle)}
        </MButtonContrast>
        <MButton
          variant="contained"
          color="secondary"
          autoFocus
          onClick={handleCancel}
        >
          {t("cancel")}
        </MButton>
      </DialogActions>
    </Dialog>
  );
};

export default ActionSignUpDialog;
