import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ADMIN_REPORT_URL } from "./endpoints";
import ReportDTO from "./models/ReportDTO";
import { convertDateToServerDateStringFormat } from "../utils/dateTimeUtils";

export const getActionReportForIntervalParams = (
  dateFrom: Date,
  dateTo: Date
): AxiosRequestConfig =>
  ({
    url: ADMIN_REPORT_URL,
    method: "GET",
    params: {
      dateFrom: convertDateToServerDateStringFormat(dateFrom),
      dateTo: convertDateToServerDateStringFormat(dateTo)
    },
    responseType: "json"
  } as AxiosRequestConfig);

export const getActionReportForInterval = async (
  dateFrom: Date,
  dateTo: Date
): Promise<AxiosResponse<ReportDTO>> => {
  return await axiosInstance.request(
    getActionReportForIntervalParams(dateFrom, dateTo)
  );
};
