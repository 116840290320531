export const getCutTextThreeDots = (
  value: string,
  maxCharacters: number
): string => {
  if (value.length > maxCharacters && maxCharacters >= 3) {
    return `${value.substring(0, maxCharacters - 3)}...`;
  } else {
    return value;
  }
};
