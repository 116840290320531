import { ReactElement } from "react";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import ActionFeed from "../../components/app/ActionFeed";

const ActionFeedPage = (): ReactElement => {
  return (
    <>
      <PageContainer>
        <ActionFeed />
      </PageContainer>
    </>
  );
};

export default ActionFeedPage;
