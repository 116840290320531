import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import { ProfileImageSmall } from "../../app/NavBar/SideDrawer/SideDrawerElements";
import { ReactElement } from "react";

const SmallProfileImage = (): ReactElement => {
  const { profilePicture } = useAppSelector((state) => state.UserState);
  return <ProfileImageSmall src={profilePicture} />;
};

export default SmallProfileImage;
