import { BlackBackround, MSpinner } from "./LoadingAnimationElements";
import LoadDragonSvg from "../../../assets/svg/loadingDragon.svg";
import { ReactElement } from "react";
import { DragonSpinnerProps } from "./models/DragonSpinnerProps";
import ConditionalHOC from "../../../hoc/ConditionalHOC";

const DragonSpinner = ({
  withBackdrop = false
}: DragonSpinnerProps): ReactElement => {
  const renderWithBackDrop = () => {
    return (
      <>
        <BlackBackround>
          <MSpinner src={LoadDragonSvg} />
        </BlackBackround>
      </>
    );
  };
  const renderDragon = () => {
    return <MSpinner src={LoadDragonSvg} />;
  };

  return (
    <ConditionalHOC
      condition={withBackdrop}
      onTrue={renderWithBackDrop}
      onFalse={renderDragon}
    />
  );
};

export default DragonSpinner;
