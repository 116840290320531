import styled from "styled-components";
import DescriptionIcon from "@material-ui/icons/Description";
import WarningIcon from "@material-ui/icons/Warning";
import { Button, Select } from "@material-ui/core";
import { theme } from "../../../styles/style";
import DeleteIcon from "@material-ui/icons/Delete";

export const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
  align-items: baseline;
`;

export const DocumentTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 160px;
  line-break: anywhere;
  word-wrap: break-word;
`;

export const BigDescriptionIcon = styled(DescriptionIcon)`
  font-size: 90px;
`;

export const HugeDescriptionIcon = styled(DescriptionIcon)`
  font-size: 250px;
`;

export const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FlexRowSpaceBetweenContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  gap: 30px;
`;

export const DocumentTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  @media (max-width: 980px) {
    font-size: 12px;
  }
`;

export const PopupFileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  align-items: center;
`;

export const WhiteWarningIcon = styled(WarningIcon)`
  color: white;
  width: 11px;
`;

export const WhiteDeleteIcon = styled(DeleteIcon)`
  color: white;
`;

export const DocumentAddContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 16px;
`;

export const DocumentAddFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  gap: 12px;
  color: black;
`;

export const AddDocumentButton = styled(Button)`
  background-color: ${theme.palette.warning.main};
  color: white;
  width: 100%;
  &:hover {
    background-color: ${theme.palette.warning.dark};
  }
`;

export const CloseAddDocumentFormButton = styled(Button)`
  width: 100%;
  color: white;
  background-color: ${theme.palette.warning.main};
  &:hover {
    background-color: ${theme.palette.warning.dark};
  }
`;

export const FullWidthSelect = styled(Select)`
  width: 100%;
`;

export const AfterDocumentsTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
`;

export const DocumentIconAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PopupContainer = styled.div`
  margin: 20px;
`;
