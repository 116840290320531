import { ReactElement, useEffect, useMemo, useState } from "react";
import InfiniteCalendar, {
  Calendar,
  withMultipleDates
} from "react-infinite-calendar";
import "react-infinite-calendar/styles.css";
import { mPrimary } from "../../../constants/colorPallete";
import { theme } from "../../../styles/style";
import { useTranslation } from "react-i18next";
import {
  ColorOverlay,
  EntireTableContainer,
  FlexRow,
  RepetitiveFirstColumnContainer,
  RepetitiveOtherInputsContainer,
  RepetitiveSecondColumnContainer,
  RoundedContainer,
  StyledTableCell,
  StyledTablePagination,
  StyledTableRow
} from "./ActionFormElements";
import {
  Fab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import InputField from "../../shared/formFields/InputField";
import { useFormikContext } from "formik";
import ActionFormData from "./models/ActionFormData";
import {
  dateInNDays,
  getIntlDateString,
  normalizeDateToUTC
} from "../../../utils/dateTimeUtils";
import TimePicker from "../../shared/TimePicker";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {
  DATE_TABLE_ROW_HEIGHT_WITH_MARGIN,
  DEFAULT_NUMBER_OF_DATES_PER_PAGE,
  INFINITE_CALENDAR_BREAKPOINT,
  INFINITE_CALENDAR_HEIGHT,
  INFINITE_CALENDAR_WIDTH_LARGE,
  INFINITE_CALENDAR_WIDTH_SMALL
} from "../../../constants/actionFormConstants";
import i18next from "i18next";
import { CROATIAN } from "../../../constants/languageConstants";

const StepThreeRepetitive = (): ReactElement => {
  const { setFieldValue } = useFormikContext<ActionFormData>();
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [displayedDates, setDisplayedDates] = useState<Date[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    DEFAULT_NUMBER_OF_DATES_PER_PAGE
  );
  const [infiniteCalendarWidth, setInfiniteCalendarWidth] = useState(
    INFINITE_CALENDAR_WIDTH_LARGE
  );

  const { t } = useTranslation();
  const { windowWidth } = useWindowDimensions();

  useEffect(() => {
    setDisplayedDates(
      selectedDates
        .sort((a, b) => b.getTime() - a.getTime())
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [page, rowsPerPage, selectedDates]);

  useEffect(() => {
    setFieldValue(
      "startDates",
      selectedDates.map((value) => normalizeDateToUTC(value).toISOString())
    );
    setFieldValue(
      "endDates",
      selectedDates.map((value) => normalizeDateToUTC(value).toISOString())
    );
  }, [selectedDates]);

  const getIndexOfDate = (date: Date): number => {
    return selectedDates.map((date) => date.getTime()).indexOf(date.getTime());
  };

  const removeDateAtIndex = (index: number): void => {
    let copyOfSelected: Date[] = [...selectedDates];
    copyOfSelected.splice(index, 1);
    setFieldValue("startDates", copyOfSelected);
    setSelectedDates(copyOfSelected);
  };

  const addDate = (date: Date): void => {
    let copyOfSelected: Date[] = [...selectedDates];
    copyOfSelected.unshift(date);
    setFieldValue("startDates", copyOfSelected);
    setSelectedDates(copyOfSelected);
  };

  const handleChangeDate = (clickedDate: Date): Date[] => {
    let indexOfClicked = getIndexOfDate(clickedDate);
    if (indexOfClicked == -1) {
      addDate(clickedDate);
    } else {
      removeDateAtIndex(indexOfClicked);
    }
    return selectedDates;
  };

  useEffect(() => {
    setInfiniteCalendarWidth(
      windowWidth < INFINITE_CALENDAR_BREAKPOINT
        ? INFINITE_CALENDAR_WIDTH_SMALL
        : INFINITE_CALENDAR_WIDTH_LARGE
    );
  }, [windowWidth]);

  const getInfiniteCalendar = () => {
    return useMemo(
      () => (
        <RoundedContainer>
          <InfiniteCalendar
            width={infiniteCalendarWidth}
            height={INFINITE_CALENDAR_HEIGHT}
            locale={{
              locale:
                i18next.language == CROATIAN
                  ? require("react-infinite-calendar/node_modules/date-fns/locale/hr")
                  : require("react-infinite-calendar/node_modules/date-fns/locale/en"),
              weekdays: [
                t("sun"),
                t("mon"),
                t("tue"),
                t("wed"),
                t("thu"),
                t("fri"),
                t("sat")
              ],
              blank: t("selectAtLeastOneDate")
            }}
            Component={withMultipleDates(Calendar)}
            selected={selectedDates}
            interpolateSelection={handleChangeDate}
            theme={{
              selectionColor: mPrimary,
              weekdayColor: theme.palette.primary.light,
              headerColor: mPrimary,
              floatingNav: {
                background: theme.palette.primary.light,
                color: "#FFF"
              }
            }}
            displayOptions={{
              showTodayHelper: false
            }}
            minDate={dateInNDays(0)}
          />
        </RoundedContainer>
      ),
      [selectedDates, infiniteCalendarWidth, t]
    );
  };

  return (
    <FlexRow>
      <RepetitiveFirstColumnContainer>
        {getInfiniteCalendar()}
      </RepetitiveFirstColumnContainer>
      <RepetitiveSecondColumnContainer>
        <RepetitiveOtherInputsContainer>
          <InputField
            variant="filled"
            label={t("createActionStep3Location")}
            name="location"
            fullWidth
          />
          <TimePicker label={t("startTime")} name="startTime" />
          <TimePicker label={t("endTime")} name="endTime" />
        </RepetitiveOtherInputsContainer>
        <ColorOverlay>
          <EntireTableContainer>
            <TableContainer>
              <Table size={"small"}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t("date")}</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedDates.map((date) => (
                    <StyledTableRow key={date.getTime()}>
                      <StyledTableCell component="th" scope="row">
                        {getIntlDateString(date.toISOString())}
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <div>
                          <Fab
                            onClick={() => {
                              removeDateAtIndex(getIndexOfDate(date));
                            }}
                            color="secondary"
                            aria-label={"label"}
                            size="small"
                            variant={"circular"}
                          >
                            <DeleteIcon />
                          </Fab>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  {displayedDates.length < DEFAULT_NUMBER_OF_DATES_PER_PAGE && (
                    <StyledTableRow
                      style={{
                        height:
                          (DEFAULT_NUMBER_OF_DATES_PER_PAGE -
                            displayedDates.length) *
                          DATE_TABLE_ROW_HEIGHT_WITH_MARGIN
                      }}
                    >
                      <StyledTableCell />
                      <StyledTableCell />
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <StyledTablePagination
              rowsPerPageOptions={[DEFAULT_NUMBER_OF_DATES_PER_PAGE]}
              count={selectedDates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, value) => setPage(value)}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${t("of")} ${count}`
              }
            />
          </EntireTableContainer>
        </ColorOverlay>
      </RepetitiveSecondColumnContainer>
    </FlexRow>
  );
};

export default StepThreeRepetitive;
