import { ReactElement, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import SignUpForActionDTO from "../../../api/models/SignUpForActionDTO";
import { signUpForAction } from "../../../api/signUpForAction";
import ErrorInfo from "../../../constants/ErrorInfo";
import ActionSignUpDialog from "../ActionSignUpDialog";
import ConfirmDialogContext from "../ConfirmDialog/ConfirmDialogContext";
import ActionSignUpProps from "./models/ActionSignUpProps";
import { patchActionUser } from "../../../api/patchActionUser";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import { BAD_REQUEST } from "../../../constants/errorConstants";

const ActionSignUp = ({
  perks,
  setNeedToUpdateActionData,
  actionId,
  isUpdating,
  selectedPerks
}: ActionSignUpProps): ReactElement => {
  const { isConfirmDialogOpen, setConfirmDialogOpen } =
    useContext(ConfirmDialogContext);
  const [selectedPerkIds, setSelectedPerkIds] = useState<number[]>([]);
  const { user } = useAppSelector((state) => state.UserState);
  const history = useHistory();

  const handleClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleSubmit = (perkIds?: number[]) => {
    setSelectedPerkIds(perkIds as number[]);
    if (isUpdating) {
      patchActionUser(
        {
          confirm: false,
          perkIds: perkIds as number[],
          userId: user?.id as string
        },
        actionId.toString()
      )
        .then(() => {
          setNeedToUpdateActionData(true);
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        });
    } else {
      signUpForAction(actionId, { perkIds: perkIds } as SignUpForActionDTO)
        .then(() => {
          setNeedToUpdateActionData(true);
        })
        .catch((error) => {
          if (error?.response?.status != BAD_REQUEST)
            history.push("/greska", {
              status: error?.response?.data?.status,
              message: error?.response?.data?.message,
              description: error?.response?.data?.description
            } as ErrorInfo);
        });
    }
    handleClose();
  };

  return (
    <>
      <ActionSignUpDialog
        perks={perks}
        alreadySelectedPerks={selectedPerks}
        id="action-signup"
        keepMounted
        open={isConfirmDialogOpen}
        onClose={handleClose}
        onSubmit={handleSubmit}
        value={selectedPerkIds}
        confirmButtonTitle="signUpForActionButtonTitle"
        title="actionSignUpTitle"
      />
    </>
  );
};

export default ActionSignUp;
