import ActionFormData from "../components/app/ActionForm/models/ActionFormData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import ActionDetailsDTO from "../pages/ActionDetails/models/ActionDetailsDTO";
import axiosInstance from "./axiosInstance";

export const createRepetitiveActionParams = (
  body: ActionFormData
): AxiosRequestConfig =>
  ({
    url: "/actions/repetitive",
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const postCreateRepetitiveAction = async (
  body: ActionFormData
): Promise<AxiosResponse<ActionDetailsDTO>> => {
  return await axiosInstance.request(createRepetitiveActionParams(body));
};
