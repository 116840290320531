import { DateTime } from "luxon";
import i18n from "../locale/i18n";

export const MILLISECONDS_IN_MINUTE = 60000;

export const getIntlDateString = (dateString: string): string => {
  return DateTime.fromISO(dateString).setLocale(i18n.language).toLocaleString();
};

export const convertDateToServerDateStringFormat = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const getCurrentDateWithZeroSeconds = (): Date => {
  return new Date(new Date().setSeconds(0));
};

export const getTimeOnlyString = (date: Date): string => {
  return date.toTimeString().split(" ")[0]; // hh:mm:ss
};

export const getStartOfToday = (): Date => {
  return new Date(new Date().setHours(0, 0, 0, 0));
};

export const cutOutSecondsFromStringTimeFormat = (time: string): string => {
  return time.substring(0, time.lastIndexOf(":"));
};

export const getCurrentDateWithSetTimeFromTimeOnlyFormat = (
  time: string
): Date => {
  const split = time.split(":");
  if (split.length != 3) {
    // Return date with current time to avoid error
    return new Date();
  }

  const hours = parseInt(split[0]);
  const minutes = parseInt(split[1]);
  const seconds = parseInt(split[2]);

  let date = new Date();
  if (
    !Number.isNaN(hours) &&
    !Number.isNaN(minutes) &&
    !Number.isNaN(seconds)
  ) {
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
  }
  return date;
};

export const compareDates = (first: Date, second: Date): number => {
  if (first.getTime() === second.getTime()) {
    return 0;
  }
  if (first < second) {
    return -1;
  }
  return 1;
};

export const dateInNDays = (numOfDaysForward: number): Date => {
  let date = new Date();
  date.setDate(date.getDate() + numOfDaysForward);
  return date;
};

export const normalizeDateToUTC = (date: Date): Date => {
  let timezoneOffset = date.getTimezoneOffset() * MILLISECONDS_IN_MINUTE;
  return new Date(date.getTime() - timezoneOffset);
};
