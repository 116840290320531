import { ReactElement, useContext, useEffect, useState } from "react";
import {
  CategoryContainer,
  CategoryListContainer,
  FirstRowContainer
} from "./CategoryEditFormElements";
import {
  MainContentContainer,
  MainTitle
} from "../../shared/layout/LayoutElements";
import SubcategoriesEditProps from "./models/SubcategoriesEditProps";
import DeleteIcon from "@material-ui/icons/Delete";
import FabReactive from "../../shared/FabReactive";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import AddPerkPerkTypeDialog from "./AddPerkPerkTypeDialog";
import { deletePerk } from "../../../api/deletePerk";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import ConfirmDialog from "../../shared/ConfirmDialog";

const SubcategoriesEdit = ({
  perks,
  perkType,
  updateData
}: SubcategoriesEditProps): ReactElement => {
  const [isAddPerkDialogOpen, setIsAddPerkDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [perkToBeDeletedId, setPerkToBeDeletedId] = useState<number | null>(
    null
  );

  const { t } = useTranslation();

  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);

  const handlePerkDelete = (perkId: number) => {
    deletePerk(perkId)
      .then(() => {
        updateData(perkType.id);
      })
      .catch(() => setSnackbarErrorMessageKey("deleteFailed"));
  };

  useEffect(() => {
    if (perkToBeDeletedId) {
      setConfirmDialogOpen(true);
    }
  }, [perkToBeDeletedId]);

  useEffect(() => {
    if (!isConfirmDialogOpen) {
      setPerkToBeDeletedId(null);
    }
  }, [isConfirmDialogOpen]);

  return (
    <>
      <ConfirmDialog
        title={t("confirmSubcategoryDelete")}
        open={isConfirmDialogOpen}
        setOpen={setConfirmDialogOpen}
        onConfirm={() =>
          perkToBeDeletedId && handlePerkDelete(perkToBeDeletedId)
        }
      />
      <AddPerkPerkTypeDialog
        isDialogOpen={isAddPerkDialogOpen}
        setIsDialogOpen={setIsAddPerkDialogOpen}
        perkTypeId={perkType.id}
        updateData={updateData}
      />
      <MainContentContainer>
        <FirstRowContainer>
          <MainTitle>{perkType.name}</MainTitle>
          <FabReactive
            icon={<AddIcon />}
            label={t("add")}
            onClick={() => setIsAddPerkDialogOpen(true)}
          />
        </FirstRowContainer>
        <CategoryListContainer>
          {perks.map((perk) => (
            <CategoryContainer
              key={perk.id}
              onClick={() => setPerkToBeDeletedId(perk.id)}
            >
              {perk.name}
              <DeleteIcon />
            </CategoryContainer>
          ))}
        </CategoryListContainer>
      </MainContentContainer>
    </>
  );
};

export default SubcategoriesEdit;
