import { Step, StepLabel, Stepper } from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { postCreateAction } from "../../../api/createAction";
import { getPerks } from "../../../api/getPerks";
import PerkDTO from "../../../api/models/PerkDTO";
import { postCreateRepetitiveAction } from "../../../api/postCreateRepetitiveActions";
import { ACTION_FORM_STEPS } from "../../../constants/actionFormConstants";
import ErrorInfo from "../../../constants/ErrorInfo";
import { ACTION_FORM_INITIAL_VALUES } from "../../../constants/formInitialValues";
import useSteps from "../../../hooks/useFormStepCounter";
import i18n from "../../../locale/i18n";
import { MButton, MButtonContrast } from "../../shared/buttons/ButtonElements";
import { MForm } from "../../shared/layout/LayoutElements";
import {
  ActionFormOuterContainer,
  ButtonContainer,
  CreateActionTitle,
  StyledStepLabel
} from "./ActionFormElements";
import ActionFormStep from "./ActionFormStep";
import ActionFormData from "./models/ActionFormData";
import PerkContext from "./PerkContext";
import getValidationSchema from "./validationSchema";

const ActionForm = (): ReactElement => {
  const { t } = useTranslation();
  const [isRepetitive, setIsRepetitive] = useState(false);
  const { step, nextStep, previousStep, isLastStep } = useSteps(
    ACTION_FORM_STEPS.length
  );
  const [selectedPerks, setSelectedPerks] = useState<PerkDTO[]>([]);
  const [perks, setPerks] = useState<PerkDTO[]>([]);

  const currentValidationSchema = getValidationSchema(step, isRepetitive);
  const history = useHistory();

  const submitForm = async (
    values: ActionFormData,
    { setSubmitting }: FormikHelpers<ActionFormData>
  ) => {
    if (!isRepetitive) {
      postCreateAction(values)
        .then((res) => {
          history.push(`/akcije/${res.data.id}`);
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        });
    } else {
      postCreateRepetitiveAction(values)
        .then(() => {
          history.push(`/korisnik/akcije`);
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        });
    }
    setSubmitting(false);
  };

  const handleNextStep = (
    values: ActionFormData,
    helpers: FormikHelpers<ActionFormData>
  ) => {
    if (isLastStep) {
      submitForm(values, helpers);
    } else {
      helpers.setTouched({});
      helpers.setSubmitting(false);
      nextStep();
    }
  };
  const steps = [
    t(ACTION_FORM_STEPS[0]),
    t(ACTION_FORM_STEPS[1]),
    t(ACTION_FORM_STEPS[2]),
    t(ACTION_FORM_STEPS[3])
  ];

  useEffect(() => {
    getPerks().then((response) => {
      setPerks(response.data);
    });
  }, []);

  i18n.on("languageChanged", () => {
    getPerks().then((response) => {
      setPerks(response.data);
      setSelectedPerks([]);
    });
  });

  return (
    <ActionFormOuterContainer>
      <Formik
        initialValues={ACTION_FORM_INITIAL_VALUES}
        validationSchema={currentValidationSchema}
        onSubmit={handleNextStep}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          isSubmitting,
          handleChange,
          handleSubmit
        }: FormikProps<ActionFormData>) => (
          <MForm onSubmit={handleSubmit} onChange={handleChange}>
            <CreateActionTitle>{t("newActionTitle")}</CreateActionTitle>
            <Stepper
              activeStep={step}
              alternativeLabel
              style={{ backgroundColor: "transparent" }}
            >
              {steps.map((label) => (
                <Step key={uuid()}>
                  <StepLabel>
                    <StyledStepLabel>{label}</StyledStepLabel>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <PerkContext.Provider
              value={{
                selectedPerks,
                setSelectedPerks,
                perks,
                setPerks,
                isRepetitive,
                setIsRepetitive
              }}
            >
              <ActionFormStep step={step} />
            </PerkContext.Provider>
            <ButtonContainer>
              <MButton
                color="secondary"
                variant="contained"
                onClick={previousStep}
                startIcon={<NavigateBefore />}
              >
                {t("createActionFormPreviousStep")}
              </MButton>
              <MButtonContrast
                type="submit"
                name="submit"
                variant="contained"
                disabled={isSubmitting}
                endIcon={<NavigateNext />}
                color="secondary"
              >
                {t("createActionFormNextStep")}
              </MButtonContrast>
            </ButtonContainer>
          </MForm>
        )}
      </Formik>
    </ActionFormOuterContainer>
  );
};

export default ActionForm;
