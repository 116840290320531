import { ReactElement, useEffect, useState } from "react";
import VolunteerMinimumDetailsDTO from "./models/VolunteerMinimumDetailsDTO";
import {
  HideDownSm,
  MainContentContainer,
  MainTitle
} from "../../shared/layout/LayoutElements";
import {
  ProfileTileFlex,
  RightSideContainer,
  UserDataContainer,
  UserDetailsContainer,
  VolunteerTilePicture
} from "./VolunteerListElements";
import DetailSpan from "./DetailSpan";
import { MButtonContrast } from "../../shared/buttons/ButtonElements";
import ProfilePlaceholderImage from "../../../assets/img/profile_placeholder.png";
import { getImageById } from "../../../api/getImageById";
import { blobToBase64 } from "../../../utils/base64Util";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import { useTranslation } from "react-i18next";
import MToolTip from "../../shared/ToolTip";
import WarningAlert from "../../shared/WarningAlert";
import { NOT_FOUND } from "../../../constants/errorConstants";
import ErrorInfo from "../../../constants/ErrorInfo";

const VolunteerTile = ({
  id,
  firstName,
  lastName,
  email,
  phone,
  isApproved
}: VolunteerMinimumDetailsDTO): ReactElement => {
  const [picture, setPicture] = useState<string>(ProfilePlaceholderImage);
  const history = useHistory();
  const { user } = useAppSelector((state) => state.UserState);

  useEffect(() => {
    getImageById(id)
      .then((res) => {
        blobToBase64(res.data).then((base64Result) =>
          setPicture(base64Result as string)
        );
      })
      .catch((error) => {
        if (error.response?.status !== NOT_FOUND) {
          // not found means that user has no profile picture, display placeholder

          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        }
      });
  }, []);

  const goToProfile = () => {
    if (user?.id === id) {
      history.push("/profil");
    } else {
      history.push(`/profil/${id}`);
    }
  };

  const { t } = useTranslation();
  //TODO STYLE Remove button from view on mobile. Alerts change on small screens into outlined icons with tooltip on click/hover
  return (
    <MainContentContainer>
      <ProfileTileFlex>
        <VolunteerTilePicture
          className="clickable"
          onClick={goToProfile}
          src={picture}
          alt={`${id}-image`}
          id={`${id}-image`}
        />
        <UserDataContainer>
          <MainTitle className="clickable" onClick={goToProfile}>
            {firstName} {lastName}
          </MainTitle>
          <UserDetailsContainer>
            <DetailSpan title={"Email:"} content={email} />
            {phone && <DetailSpan title={"Phone:"} content={phone} />}
          </UserDetailsContainer>
        </UserDataContainer>
        <RightSideContainer>
          {!isApproved && (
            <MToolTip message={`${t("needsApproval")}`}>
              <WarningAlert />
            </MToolTip>
          )}
          <HideDownSm>
            <MButtonContrast
              color={"secondary"}
              type={"button"}
              variant={"contained"}
              onClick={goToProfile}
            >
              {t("viewProfile")}
            </MButtonContrast>
          </HideDownSm>
        </RightSideContainer>
      </ProfileTileFlex>
    </MainContentContainer>
  );
};

export default VolunteerTile;
