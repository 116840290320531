import { GET_ARCHIVED_ACTIONS_FOR_USER_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import BasicActionDetailsDTO from "./models/BasicActionDetailsDTO";

export const getArchivedActionsForUserParams = (
  userId: string
): AxiosRequestConfig =>
  ({
    url: GET_ARCHIVED_ACTIONS_FOR_USER_URL(userId),
    method: "GET"
  } as AxiosRequestConfig);

export const getArchivedActionsForUser = async (
  userId: string
): Promise<AxiosResponse<BasicActionDetailsDTO[]>> => {
  return await axiosInstance.request(getArchivedActionsForUserParams(userId));
};
