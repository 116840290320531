import { TFunction } from "react-i18next";
import {
  ActionApprovalNotificationDTO,
  ActionEndedNotificationDTO,
  ActionSignupNotificationDTO,
  NotificationDTO,
  notificationType
} from "../api/models/NotificationDTO";

interface NotificationContentFactory {
  getTitle: (
    notification: NotificationDTO,
    t: TFunction<"translation">
  ) => string;
  getDescription: (
    notification: NotificationDTO,
    t: TFunction<"translation">
  ) => string;
  getRedirectLink: (notification: NotificationDTO) => string;
}

export const NOTIFICATION_CONTENT_FACTORIES: Record<
  notificationType,
  NotificationContentFactory
> = {
  action_approval: {
    getDescription: (
      notification: NotificationDTO,
      t: TFunction<"translation">
    ) => {
      const { actionId, approvedPerkIds, deniedPerkIds } =
        notification as ActionApprovalNotificationDTO;
      return (
        t("actionTitle") +
        ": " +
        actionId +
        "\n" +
        t("actionApprovalApproved") +
        ": " +
        approvedPerkIds +
        "\n" +
        t("actionApprovalDenied") +
        ": " +
        deniedPerkIds +
        "\n"
      );
    },
    getTitle: (notification: NotificationDTO, t: TFunction<"translation">) =>
      t("actionApprovalProcessed"),
    getRedirectLink: (notification: NotificationDTO) => {
      const { actionId } = notification as ActionApprovalNotificationDTO;
      return `/akcije/${actionId}`;
    }
  },
  action_signup: {
    getDescription: (
      notification: NotificationDTO,
      t: TFunction<"translation">
    ) => {
      const {
        creatorUserFirstName: firstName,
        creatorUserLastName: lastName,
        actionId
      } = notification as ActionSignupNotificationDTO;
      return t("newActionSignupDescription", {
        firstName,
        lastName,
        actionId
      });
    },
    getTitle: (notification: NotificationDTO, t: TFunction<"translation">) =>
      t("newActionSignupTitle"),
    getRedirectLink: (notification: NotificationDTO) => {
      const { actionId } = notification as ActionSignupNotificationDTO;
      return `/akcije/${actionId}/korisnici`;
    }
  },
  action_ended: {
    getDescription: (
      notification: NotificationDTO,
      t: TFunction<"translation">
    ) => t("actionEndedDescription"),
    getTitle: (notification: NotificationDTO, t: TFunction<"translation">) =>
      t("actionEndedTitle"),
    getRedirectLink: (notification: NotificationDTO) => {
      const { actionId } = notification as ActionEndedNotificationDTO;
      return `/akcije/${actionId}`;
    }
  }
};

export const NOTIFICATIONS_WS_URL = "/api/notification";
export const SUBSCRIPTION_ENDPOINT = "/user/topic/notification";
export const SUBSCRIPTION_START = "/app/start";
export const SUBSCRIPTION_STOP = "/app/stop";
