import styled from "styled-components";
import { ListItemIcon } from "@material-ui/core";

/**
 * Container for the SVG icon.
 *
 * Without specifying at least the height, svg renders at size 0x0.
 * // TODO [later] - if used anywhere else outside the LanguagePicker, don't style ListItemIcon
 */
export const SVGIconContainer = styled(ListItemIcon)`
  height: 30px;
  width: 30px;
`;
