import { ReactScrollLinkProps } from "react-scroll/modules/components/Link";

export const getScrollAttributes = (goTo: string): ReactScrollLinkProps => {
  return {
    to: goTo,
    duration: 500,
    delay: 0,
    smooth: "easeInOut",
    spy: true,
    offset: -100
  };
};
