import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MButtonContrast } from "../../shared/buttons/ButtonElements";
import {
  HeroButtoContainer,
  HeroContentContainer,
  HeroSubTitle,
  HeroTitle
} from "./HeroPageElements";
import { ReactElement } from "react";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";

const HeroPage = (): ReactElement => {
  const { logged } = useAppSelector((state) => state.UserState);
  const { t } = useTranslation();
  const history = useHistory();

  const toRegistracija = () => {
    history.push("/registracija");
  };
  return (
    <>
      <HeroContentContainer>
        <HeroTitle className="dragonBarrier">{t("heroTitle")}</HeroTitle>
        <HeroSubTitle>{t("heroSubtitle")}</HeroSubTitle>
        {!logged && (
          <HeroButtoContainer>
            <MButtonContrast
              className="dragonBarrier"
              onClick={toRegistracija}
              variant="contained"
              color="secondary"
            >
              {t("registerActionCall")}
            </MButtonContrast>
          </HeroButtoContainer>
        )}
      </HeroContentContainer>
    </>
  );
};

export default HeroPage;
