import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { ReactElement } from "react";
import { theme } from "../../../styles/style";

const WarningAlert = (): ReactElement => {
  return (
    <>
      <ReportProblemOutlinedIcon
        style={{ color: theme.palette.warning.main }}
      />
    </>
  );
};

export default WarningAlert;
