import { Select } from "@material-ui/core";
import styled from "styled-components";
import { mSecondary } from "../../../constants/colorPallete";

/**
 * Container for the {@see LanguagePicker} .
 */
export const LanguagePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  border: 2px solid #394441;
  border-radius: 8px;
`;
export const MSelect = styled(Select)`
  //Select has a child element that we have to get like this.
  color: ${mSecondary};
  & > div {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
  }
`;
