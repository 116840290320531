import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import HeroInfoContainer from "./HeroInfoContainer";
import { HeroSection } from "./HeroPageElements";

const HeroInfoSection = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <HeroSection className="dragonBarrier">
      <HeroInfoContainer
        id="whoAreWe"
        title={t("whoAreWeTitle")}
        body={t("whoAreWeBody")}
      />
      <HeroInfoContainer
        id="howDoWe"
        title={t("HowDoWeTitle")}
        body={t("HowDoWeBody")}
        alignRight
      />
      <HeroInfoContainer
        id="whatDoWe"
        title={t("WhatAreWeTitle")}
        body={t("WhatAreWeBody")}
      />
    </HeroSection>
  );
};

export default HeroInfoSection;
