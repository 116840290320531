import styled from "styled-components";
import { mSecondary, mTranBlack } from "../../../constants/colorPallete";
import { mH2, mH4 } from "../../../constants/cssConstants";
import { theme } from "../../../styles/style";

export const BasicInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
  @media (max-width: 980px) {
    gap: 12px;
  }
`;
export const PersonalInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  @media (max-width: 980px) {
    gap: 3px;
  }
`;
export const RowInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 400px;
  gap: 6px;
  @media (max-width: 980px) {
    gap: 3px;
  }
`;
export const ProfilePictureWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 132px;
  border-radius: 100%;
  @media (max-width: 768px) {
    width: 72px;
    height: 72px;
  }
`;

export const MInfoContainer = styled.div`
  border-top: 1px solid ${mSecondary};
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  gap: 12px;
`;

export const ImageUploader = styled.div`
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  &:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${mTranBlack};
  }
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
`;
export const TailPlaceholder = styled.img`
  width: 65px;
  height: auto;
`;

export const ProfileName = styled.h2`
  font-size: ${mH2};
  font-weight: 600;
  ${theme.breakpoints.down("sm")} {
    font-size: ${mH4};
  }
`;

export const TabContainer = styled.div`
  width: 100%;
`;
