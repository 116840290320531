import * as yup from "yup";
import i18n from "../../../locale/i18n";
import {
  DESCRIPTION_MAX_LENGTH,
  LOCATION_MAX_LENGTH,
  SHORT_DESCRIPTION_MAX_LENGTH,
  TITLE_MAX_LENGTH
} from "../../../constants/actionConstants";
import { getStartOfToday } from "../../../utils/dateTimeUtils";
import { REGEX_TIME_STRING } from "../../../constants/regexConstants";

type validationSchemaType = typeof validationSchema[0];

const getValidationSchema = (
  step: number,
  isRepetitive: boolean
): validationSchemaType => {
  if (step === 2 && isRepetitive) {
    return validationSchema[3];
  } else if (step === 3) {
    return validationSchema[4];
  } else {
    return validationSchema[step];
  }
};

const validationSchema = [
  // Validation schema for step 1
  yup.object().shape({
    title: yup
      .string()
      .required(i18n.t("required"))
      .max(TITLE_MAX_LENGTH, i18n.t("maxLength", { count: TITLE_MAX_LENGTH })),
    description: yup
      .string()
      .required(i18n.t("required"))
      .max(
        DESCRIPTION_MAX_LENGTH,
        i18n.t("maxLength", { count: DESCRIPTION_MAX_LENGTH })
      ),
    shortDescription: yup
      .string()
      .max(
        SHORT_DESCRIPTION_MAX_LENGTH,
        i18n.t("maxLength", { count: SHORT_DESCRIPTION_MAX_LENGTH })
      )
  }),
  // Validation schema for step 2
  yup.object().shape({
    titleEnglish: yup
      .string()
      .max(TITLE_MAX_LENGTH, i18n.t("maxLength", { count: TITLE_MAX_LENGTH })),
    descriptionEnglish: yup
      .string()
      .max(
        DESCRIPTION_MAX_LENGTH,
        i18n.t("maxLength", { count: DESCRIPTION_MAX_LENGTH })
      ),
    shortDescriptionEnglish: yup
      .string()
      .max(
        SHORT_DESCRIPTION_MAX_LENGTH,
        i18n.t("maxLength", { count: SHORT_DESCRIPTION_MAX_LENGTH })
      )
  }),
  // Validation schema for step 3 for single action create
  yup.object().shape({
    location: yup
      .string()
      .required(i18n.t("required"))
      .max(
        LOCATION_MAX_LENGTH,
        i18n.t("maxLength", { count: LOCATION_MAX_LENGTH })
      ),
    startDate: yup
      .date()
      .required(i18n.t("required"))
      .min(getStartOfToday(), i18n.t("createActionStartDateValidation")),
    endDate: yup
      .date()
      .required(i18n.t("required"))
      .min(yup.ref("startDate"), i18n.t("createActionDateRangeValidation")),
    startTime: yup.string().matches(REGEX_TIME_STRING, i18n.t("required"))
  }),
  // Validation schema for step 3 for repetitive action create
  yup.object().shape({
    location: yup
      .string()
      .required(i18n.t("required"))
      .max(
        LOCATION_MAX_LENGTH,
        i18n.t("maxLength", { count: LOCATION_MAX_LENGTH })
      ),
    endTime: yup.date().required("Required").min(new Date(), "req"),
    startTime: yup.string().matches(REGEX_TIME_STRING, i18n.t("required")),
    startDates: yup
      .array()
      .min(1, i18n.t("selectAtLeastOneDate"))
      .required(i18n.t("required")),
    endDates: yup.array().required(i18n.t("required"))
  }),
  // Validation schema for step 4
  yup.object().shape({
    requiredPerks: yup
      .array()
      .min(1, i18n.t("createActionRequiredPerksValidation"))
      .required(i18n.t("required"))
  })
];

export default getValidationSchema;
