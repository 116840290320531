import { GET_FINISHED_ACTIONS_FOR_USER_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import BasicActionDetailsDTO from "./models/BasicActionDetailsDTO";

export const getFinishedActionsForUserParams = (
  userId: string
): AxiosRequestConfig =>
  ({
    url: GET_FINISHED_ACTIONS_FOR_USER_URL(userId),
    method: "GET"
  } as AxiosRequestConfig);

export const getFinishedActionsForUser = async (
  userId: string
): Promise<AxiosResponse<BasicActionDetailsDTO[]>> => {
  return await axiosInstance.request(getFinishedActionsForUserParams(userId));
};
