import { AxiosRequestConfig, AxiosResponse } from "axios";
import ReviewDTO from "../components/app/ReviewList/models/ReviewDTO";
import axiosInstance from "./axiosInstance";
import { GET_REVIEWS_URL } from "./endpoints";

export const getReviews = async (
  targetUserId: string
): Promise<AxiosResponse<ReviewDTO[]>> => {
  return await axiosInstance.get(GET_REVIEWS_URL(targetUserId));
};

export const getReviewsParams = (targetUserId: string): AxiosRequestConfig => ({
  url: GET_REVIEWS_URL(targetUserId)
});
