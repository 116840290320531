import { ReactElement } from "react";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import ActionUsers from "../../components/app/ActionUsers";

const ActionUsersPage = (): ReactElement => {
  return (
    <PageContainer>
      <ActionUsers />
    </PageContainer>
  );
};

export default ActionUsersPage;
