export const pageTransitionVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};
export const fadeVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};
export const mainContainerVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
    y: "100px"
  },
  in: {
    opacity: 1,
    scale: 1,
    y: "0"
  },
  out: {
    opacity: 0,
    x: "-100vh"
  },
  transition: {
    ease: "easeInOut",
    duration: 6
  }
};

export const hideVariant = {
  hidden: {
    opacity: 0.2,
    scale: 0.7
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5
    }
  }
};
