import { useTranslation } from "react-i18next";
import { ColmAlignLeftContainer } from "../../shared/layout/LayoutElements";
import { MInfoText, MLabel } from "../../shared/text/TextElements";
import { PersonalInfoContainer, RowInfoContainer } from "./ProfileInfoElements";
import { ReactElement, useEffect, useState } from "react";
import ProfileTabProps from "./models/ProfileTabProps";
import { valueNotSet } from "../../../constants/errorConstants";
import { MButton } from "components/shared/buttons/ButtonElements";
import axiosInstance from "api/axiosInstance";
import { RESEND_EMAIL_URL } from "api/endpoints";

const AboutMe = ({ user }: ProfileTabProps): ReactElement => {
  const { t } = useTranslation();
  const [buttonTimeout, setButtonTimeout] = useState(false);
  const [isButtonDisabled, setIsButtonDisable] = useState(false);

  useEffect(() => {
    if (buttonTimeout) {
      setIsButtonDisable(true);
      setTimeout(() => {
        setIsButtonDisable(false);
      }, 20000);
      setButtonTimeout(false);
    }
  }, [buttonTimeout]);

  const sendEmail = () => {
    axiosInstance
      .get<void>(RESEND_EMAIL_URL)
      .then(() => setButtonTimeout(true));
  };

  return (
    <ColmAlignLeftContainer>
      <PersonalInfoContainer>
        <MLabel>{t("email")}</MLabel>
        <div style={{ display: "flex", alignItems: "center", gap: "32px" }}>
          <MInfoText>{user?.email ? user.email : valueNotSet}</MInfoText>
          {user !== undefined && !user.isEmailConfirmed && (
            <>
              <MInfoText style={{ color: "red" }}>
                {t("notConfirmed")}
              </MInfoText>
              <MButton
                variant="contained"
                color="primary"
                size="large"
                onClick={sendEmail}
                disabled={isButtonDisabled}
              >
                {t("confirm")}
              </MButton>
            </>
          )}
        </div>
      </PersonalInfoContainer>
      <PersonalInfoContainer>
        <MLabel>{t("telephone")}</MLabel>
        <MInfoText>{user?.phone ? user.phone : valueNotSet}</MInfoText>
      </PersonalInfoContainer>
      <RowInfoContainer>
        <PersonalInfoContainer>
          <MLabel>{t("birthDate")}</MLabel>
          <MInfoText>
            {user?.dateOfBirth ? user.dateOfBirth : valueNotSet}
          </MInfoText>
        </PersonalInfoContainer>
        <PersonalInfoContainer>
          <MLabel>{t("gender")}</MLabel>
          <MInfoText>{user?.gender ? t(user.gender) : valueNotSet}</MInfoText>
        </PersonalInfoContainer>
      </RowInfoContainer>
      <PersonalInfoContainer>
        <MLabel>{t("residentialAddress")}</MLabel>
        <MInfoText>{user?.address ? user.address : valueNotSet}</MInfoText>
      </PersonalInfoContainer>
      <PersonalInfoContainer>
        <MLabel>{t("country")}</MLabel>
        <MInfoText>{user?.country ? user.country : valueNotSet}</MInfoText>
      </PersonalInfoContainer>
    </ColmAlignLeftContainer>
  );
};

export default AboutMe;
