import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const BasicActionTileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  flex: 1;
  gap: 12px;
  @media (max-width: 660px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  flex: 2;
  @media (min-width: 600px) {
    min-width: 500px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  flex: 1;
`;

export const DescriptionContainer = styled(Typography)`
  word-wrap: break-word;
  word-break: break-word;
`;

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  @media (max-width: 660px) {
    flex-direction: column;
  }
`;
