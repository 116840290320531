import { createContext, Dispatch, SetStateAction } from "react";
import UserDetailsDTO from "../../../api/models/UserDetailsDTO";

interface UserContextProps {
  user?: UserDetailsDTO;
  setUser: Dispatch<SetStateAction<UserDetailsDTO | undefined>>;
  isAdmin: boolean;
}

const UserContext = createContext<UserContextProps>({} as UserContextProps);

export default UserContext;
