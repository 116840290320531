import { PATCH_USER_ROLES_BY_ID_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import UserRolesAndStatusDTO from "./models/UserRolesAndStatusDTO";
import UserDetailsDTO from "./models/UserDetailsDTO";

export const patchRolesParams = (
  userId: string,
  userRolesDTO: UserRolesAndStatusDTO
): AxiosRequestConfig =>
  ({
    url: PATCH_USER_ROLES_BY_ID_URL(userId),
    method: "PATCH",
    data: userRolesDTO
  } as AxiosRequestConfig);

export const patchRoles = async (
  userId: string,
  roleNames: string[],
  isApproved: boolean
): Promise<AxiosResponse<UserDetailsDTO>> => {
  return await axiosInstance.request(
    patchRolesParams(userId, { roleNames, isApproved } as UserRolesAndStatusDTO)
  );
};
