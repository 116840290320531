import { AxiosRequestConfig, AxiosResponse } from "axios";
import { PERSONAL_INFO_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { ProfileWizardStep1Data } from "../components/app/ProfileWizard/models/ProfileWizardData";
import UserDetailsDTO from "./models/UserDetailsDTO";

export const personalInfoParams = (
  body: ProfileWizardStep1Data
): AxiosRequestConfig =>
  ({
    url: PERSONAL_INFO_URL,
    method: "PATCH",
    data: body
  } as AxiosRequestConfig);

export const postPersonalInfo = async (
  body: ProfileWizardStep1Data
): Promise<AxiosResponse<UserDetailsDTO>> =>
  await axiosInstance.request(personalInfoParams(body));
