import styled from "styled-components";
import { TableCell, TablePagination } from "@material-ui/core";
import { mSecondary } from "../../../constants/colorPallete";
import { theme } from "../../../styles/style";

export const InsertWorkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  padding-right: 16px;
  ${theme.breakpoints.down("xs")} {
    flex-direction: column;
    gap: 12px;
    padding-right: 0px;
  }
`;

export const NumberAndAddContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  ${theme.breakpoints.down("xs")} {
    justify-content: space-between;
  }
`;

export const DateInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

export const EditButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  flex: 1;
`;

export const StyledTableCell = styled(TableCell)`
  color: ${mSecondary};
`;

export const StyledTablePagination = styled(TablePagination)`
  overflow-x: hidden;
`;
