import { DELETE_PERK_TYPE_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export const deletePerkTypeParams = (id: number): AxiosRequestConfig =>
  ({
    url: DELETE_PERK_TYPE_URL(id),
    method: "DELETE"
  } as AxiosRequestConfig);

export const deletePerkType = async (
  id: number
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(deletePerkTypeParams(id));
};
