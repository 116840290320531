import UseStepsReturnType from "./models/UseStepsReturnType";
import { useState } from "react";

const useSteps = (stepCount: number): UseStepsReturnType => {
  const [step, setStep] = useState<number>(0);
  const isLastStep = step === stepCount - 1;

  const nextStep = () => {
    setStep((step) => Math.min(step + 1, stepCount - 1));
  };

  const previousStep = () => {
    setStep((step) => Math.max(step - 1, 0));
  };

  return { step, nextStep, previousStep, isLastStep };
};

export default useSteps;
