import { AxiosRequestConfig, AxiosResponse } from "axios";
import { SIGN_UP_FOR_ACTION_URL } from "./endpoints";
import SignUpForActionDTO from "./models/SignUpForActionDTO";
import ActionDetailsDTO from "../pages/ActionDetails/models/ActionDetailsDTO";
import axiosInstance from "./axiosInstance";

const signUpForActionParams = (
  actionId: number,
  body: SignUpForActionDTO
): AxiosRequestConfig =>
  ({
    url: SIGN_UP_FOR_ACTION_URL(actionId),
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const signUpForAction = async (
  actionId: number,
  body: SignUpForActionDTO
): Promise<AxiosResponse<ActionDetailsDTO>> =>
  await axiosInstance.request(signUpForActionParams(actionId, body));
