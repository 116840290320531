import { ReactElement, useEffect } from "react";
import {
  ActionFormStepThreeColumnContainer,
  ActionFormStepThreeRowColumnContainer,
  ActionFormStepThreeRowContainer
} from "./ActionFormElements";
import DatePickerField from "components/shared/formFields/DatePickerField";
import InputField from "components/shared/formFields/InputField";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import ActionFormData from "components/app/ActionForm/models/ActionFormData";
import { dateInNDays, normalizeDateToUTC } from "utils/dateTimeUtils";
import TimePicker from "components/shared/TimePicker";

const StepThreeNormal = (): ReactElement => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<ActionFormData>();
  const { errors, isValid } = useFormikContext<ActionFormData>();

  useEffect(() => {
    console.log(JSON.stringify(errors));
    console.log(JSON.stringify(isValid));
  }, [errors, isValid]);

  return (
    <ActionFormStepThreeColumnContainer>
      <ActionFormStepThreeRowContainer>
        <ActionFormStepThreeRowColumnContainer>
          <DatePickerField
            setFieldValue={setFieldValue}
            name="startDate"
            label={t("startDate")}
            minDate={dateInNDays(0)}
            onAccept={(muiDate) => {
              // Purpose is to update end date because it cannot be before start date
              let newStartDate = muiDate as Date;
              let currentEndDate = new Date(values.endDate);
              if (newStartDate > currentEndDate) {
                setFieldValue(
                  "endDate",
                  new Date(normalizeDateToUTC(newStartDate).toISOString())
                );
              }
            }}
          />
          <DatePickerField
            setFieldValue={setFieldValue}
            name="endDate"
            label={t("endDate")}
            minDate={new Date(values.startDate)}
          />
        </ActionFormStepThreeRowColumnContainer>
        <ActionFormStepThreeRowColumnContainer>
          <TimePicker label={t("startTime")} name="startTime" />
          <TimePicker label={t("endTime")} name="endTime" />
        </ActionFormStepThreeRowColumnContainer>
      </ActionFormStepThreeRowContainer>
      <InputField
        variant="filled"
        label={t("createActionStep3Location")}
        fullWidth
        name="location"
      />
    </ActionFormStepThreeColumnContainer>
  );
};

export default StepThreeNormal;
