export const TRANSLATION_EN: Record<string, string> = {
  // i know that messages are probably not ideal, but @adrobnjak will hopefully take care of that
  loginTitle: "Login",
  logout: "Log out",
  registerTitle: "Register",
  couldNotDeleteAction: "Could not delete action.",
  confirmActionDelete: "Confirm deletion of action",
  confirmActionDeleteDetails:
    "Deleting will result is action {{title}} being permanently deleted.",
  confirmActionArchive: "Confirm archiving action",
  confirmActionArchiveDetails:
    "It will no longer be possible to input work records for action {{title}}",
  confirmActionUnarchive: "Confirm returning action from archive",
  confirmActionUnarchiveDetails:
    "It will be possible to input work records for action {{title}}",
  unarchive: "Return from archive",
  archive: "Archive",
  registerSuccess: "Registration successful, please log in to continue.",
  unknown: "Unknown",
  email: "Email",
  selectAtLeastOneDate: "Please select at least one date",
  clear: "Clear",
  password: "Password",
  repeatPassword: "Repeat password",
  telephone: "Telephone",
  birthDate: "Date of birth",
  gender: "Sex",
  residentialAddress: "Residential adress",
  country: "Country",
  hours: "Hours",
  failedToFetchWorkRecords: "Failed to fetch work records",
  failedToUpdateWorkRecord: "Failed to update work record",
  failedToDeleteWorkRecord: "Failed to delete work record",
  failedToCreateWorkRecord: "Failed to create work record",
  rememberMe: "Remember me",
  loginAttempt: "Log in",
  forgotPassword: "Forgot password?",
  registerHint: "Don't have an account? Register",
  aboutUs: "About us",
  ourWork: "Our work",
  ourModel: "Our model",
  volunteerListTitle: "List of volunteers",
  volunteerList: "Volunteers",
  actionList: "Volunteer events",
  myActionList: "My events",
  contactUs: "Contact us",
  registerAttempt: "Register",
  registerActionCall: "Join us!",
  firstName: "First name",
  lastName: "Last name",
  notificationPrompt: "I want to receive notifications",
  loginHint: "Already have an account? Log in",
  confirmPassword: "Confirm password",
  forgotPasswordEmailHint: "Enter your email.",
  send: "Send",
  normal: "Normal",
  repetitive: "Repetitive",
  backToLogin: "Back to login",
  enterNewPassword: "Enter new password",
  confirm: "Confirm",
  copyright: "Copyright",
  validEmail: "Please enter a valid email.",
  validPasswordLength: "Password must contain at least 8 characters.",
  validPasswordMatch: "Passwords don't match.",
  emailInUse: "This e-mail is already in use.",
  maxLength64: "Maximum length of this field cannot exceed 64.",
  validPasswordFormat:
    "Password must contain at least 1 number, 1 lowercase letter and 1 uppercase letter.",
  validFirstName: "Please enter a valid first name.",
  validLastName: "Please enter a valid last name.",
  required: "This field is required.",
  invalidCredentials: "Invalid credentials.",
  requiredPerks: "Who do we need?",
  startDate: "Start date:",
  endDate: "End date:",
  dow0: "Sunday",
  dow1: "Monday",
  dow2: "Tuesday",
  dow3: "Wednesday",
  dow4: "Thursday",
  dow5: "Friday",
  dow6: "Saturday",
  step: "Step",
  of: "of",
  protectYourself: "Only to protect yourself",
  youFly: "After that you can fly!",
  dragonName: "How do we call this Dragon?",
  yuchForm: "No one likes forms. Just a little more though.",
  lastStep: "Continue",
  goBack: "Return",
  actionPageTitle: "Current events",
  somethingsWrong: "Ops! Something did not go as planned :(",
  motivationMessageRegister: "You are soon going to be a dragon!",
  motivationMessageLogin: "Thank you for being here.",
  returnHome: "Back to Home",
  signUpForAction: "Sign up!",
  signUpForActionButtonTitle: "Sign up",
  moreInfoForAction: "More information",
  written: "written",
  reviews: "Reviews",
  documents: "Documents",
  downloadFailure: "Download failure.",
  uploadFailure: "Upload failure.",
  uploadDocumentFailure: "Upload failure. Select a pdf file under 10 MB.",
  documentListFetchFailure: "Failed to retrieve documents.",
  add: "Add",
  close: "Close",
  upload: "Upload",
  fileName: "File name",
  documentDeleteFailure: "Unable to delete",
  edit: "Edit",
  end: "End",
  createAction: "Create action",
  needsApproval: "Needs approval",
  actionNeedsApproval:
    "Coordinator needs to approve your enrollment. You will be notified.",
  other: "Other",
  documentType: "Document type",
  documentName: "Document name",
  confirmDelete: "Are you sure you want to delete file ",
  confirmLogout: "Are you sure you want to log out?",
  profile: "Profile",
  aboutMe: "Information",
  skills: "Skills",
  interests: "Interests",
  yes: "Yes",
  no: "No",
  category: "Document category",
  invalidFileType: "Invalid file type.",
  genericError: "Unexpected error occured. Please try again later.",
  reviewCount: "{{count}} review",
  reviewCount_plural: "{{count}} reviews",
  noReviews: "No reviews",
  actionDescription: "DETAILS",
  actionContacts: "CONTACTS",
  actionGoBack: "Back",
  coordinator: "Coordinator",
  viewProfile: "View profile",
  volunteer: "Volunteer",
  administrator: "Administrator",
  heroTitle: "Become a Dragon!",
  heroSubtitle: "Here the big help the small",
  maxLength: "Length of this field's content cannot exceed {{count}} character",
  maxLength_plural:
    "Length of this field's content cannot exceed {{count}} characters",
  createActionFormStep1: "Basic information in croatian",
  createActionFormStep2: "Basic information in english",
  createActionFormStep3: "Time and place",
  createActionFormStep4: "Volunteer skills",
  newActionTitle: "New action:",
  createActionFormNextStep: "Next step",
  createActionFormPreviousStep: "Previous step",
  createActionStep1Title: "*Action title in croatian",
  createActionStep1ShortDescription: "Short description in croatian",
  createActionStep1Description: "*Detailed description in croatian",
  createActionStep2Title: "Action title in english",
  createActionStep2ShortDescription: "Short description in english",
  createActionStep2Description: "Detailed description in english",
  createActionStep3DateFrom: "From:",
  createActionStep3DateTo: "To:",
  createActionStep3Location: "*Location",
  createActionStep4PerkType: "Category",
  createActionStep4Perk: "Skill",
  createActionDateRangeValidation: "Action can't end before it started.",
  createActionStartDateValidation: "Start date can't be before today.",
  createActionRequiredPerksValidation: "A minimum of 1 category is required.",
  any: "Any",
  viewUserActions: "View volunteer actions",
  isApproved: "Approved",
  categories: "Categories",
  mailSendSuccess: "E-mail successfully sent. Check your mail.",
  mailConfirmationSuccess: "E-mail successfully confirmed.",
  passwordResetSuccess: "Password successfully reset. Log in to continue.",
  addReview: "Leave a review",
  submitCreateReview: "Submit",
  cancelCreateReview: "Cancel",
  reviewTextLabel: "Review text",
  actionSignUpTitle: "Sign up for action",
  cancel: "Cancel",
  active: "Active",
  finished: "Finished",
  archived: "Archived",
  noCategory: "No category",
  loginSessionExpired: "Session expired.",
  date: "Date",
  startTime: "Start time:",
  endTime: "End time:",
  private: "Private",
  actionListEmptyMessage:
    "Currently there aren't any available volunteer actions. Please try again later.",
  filters: "Filters",
  filteredActionListEmptyMessage: "No available actions match your filter.",
  couldNotUpdatePrivileges: "Could not update privileges.",
  confirmPrivilegeChange: "Confirm privilege change.",
  privileges: "Privileges",
  couldNotFetchUserPrivileges: "Could not fetch user privileges.",
  noUsersFound:
    "Unfortunately, there are no users that match the given criteria.",
  dateOfBirth: "Date of birth",
  male: "Male",
  female: "Female",
  address: "Address",
  phone: "Phone",
  employmentStatus: "Employment Status",
  student: "Student",
  employed: "Employed",
  unemployed: "Unemployed",
  deleteFailed: "Delete failed.",
  couldNotFetchCategories: "Could not fetch categories.",
  confirmCategoryDelete: "Are you sure you want to delete the category",
  confirmSubcategoryDelete: "Are you sure you want to delete the subcategory?",
  nameCroatian: "Croatian name",
  nameEnglish: "English name",
  couldNotCreateCategory: "Category creation failed.",
  couldNotCreateSubcategory: "Subcategory creation failed.",
  retired: "Retired",
  disciple: "Disciple",
  profession: "Profession",
  authenticationRequired: "Please login to access that page.",
  unauthorized: "You are not authorized to access that page.",
  chooseFile: "Choose file",
  documentStatusUpdateFailed: "Document status update failed.",
  approve: "Approve",
  disapprove: "Disapprove",
  download: "Download",
  delete: "Delete",
  M: "Male",
  F: "Female",
  totalWorkHours: "Total work hours",
  workHours: "Work hours",
  reportSummary: "Report summary",
  details: "Details",
  ageGroup: "Age group",
  generateReport: "Generate report",
  dateToMustBeAfterDateFrom: "Date to must be after date from.",
  dateFrom: "Date from",
  dateTo: "Date to",
  pleaseSetDateValues: "Please select dates.",
  totalActions: "Total actions",
  numberOfVolunteers: "Number of volunteers",
  report: "Report",
  approvedVolunteer: "Approved volunteer",
  unknownUserPrivilege: "Unknown user privilege.",
  actionUserRequests: "Volunteer requests",
  acceptActionSignup: "Confirm user for action",
  accept: "Confirm",
  update: "Update",
  decline: "Decline",
  workTimeDesc: "Warehouse opening hours and customer service:",
  warehousePhone: "Warehouse phone:",
  associationWorkTime: "Association working hours:",
  workDay: "Work days: {{time}}",
  homeOffice: "home office due to COVID-19",
  whoAreWeTitle: "Who are we?",
  whoAreWeBody:
    "It started with a couple of friends and 30 chocolates. Now it has grown into something much bigger. The Little Dragon (orig. Mali zmaj) Association has been improving the quality of life of poor and neglected children for more than 10 years.",
  HowDoWeTitle: "How do we work?",
  HowDoWeBody:
    "We are a completely non-profit and voluntary association that is not funded from the city or state budget but depends on donations from individuals and companies as well as the results of competitions to which we apply for our programs and projects.",
  WhatAreWeTitle: "What are we doing?",
  WhatAreWeBody:
    "We take care of over 150 families and work with two safe houses, a small home for mothers and children and a shelter for a total of 500 children. Through various projects and workshops, we entertain and help children.",
  inspirationQuote: "It only takes one person to change someone's world.",
  getToKnowUs: "Get to know us!",
  monThu: "Mon-Thu: {{time}}",
  fridayHours: "Friday: {{time}}",
  mon: "Mon",
  tue: "Tue",
  wed: "Wed",
  thu: "Thu",
  fri: "Fri",
  sat: "Sat",
  sun: "Sun",
  wizardStepOneTitle:
    "Why do we need your info? Makes paperwork easier for you and us.",
  wizardStepTwoTitle: "Tell us what you do and what are your skills",
  wizardStepThreeTitle: "You can choose what you want to do.",
  saveAndExit: "Save and exit",
  quantity: "Quantity",
  itemsShort: "Items",
  itemsLong: "Items per page",
  categoryMenagement: "Category management",
  confirmDeleteReview: "Are you sure you want to delete this review?",
  noNotifications: "No notifications",
  actionApprovalProcessed: "Action sign-up processed",
  actionTitle: "Action title",
  actionApprovalApproved: "Approved categories",
  actionApprovalDenied: "Denied categories",
  newActionSignupTitle: "New action sign up",
  newActionSignupDescription:
    "New user, {{firstName}} {{lastName}}, has applied for action {{actionId}}.",
  actionEndedTitle: "Završena akcija",
  actionEndedDescription:
    "Akcija za koju ste se prijavili je nedavno završila. Provjerite jeste li unijeli sve radne sate.",
  notConfirmed: "Not confimed",
  confirmEmail: "Confirm",
  emailMustBeConfirmed: "*email must be confimed"
};
