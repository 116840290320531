import { ADMIN_PICTURE_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import ProfilePictureWithIdDTO from "./models/ProfilePictureWithIdDTO";

export const uploadPictureByIdParams = (
  body: ProfilePictureWithIdDTO
): AxiosRequestConfig =>
  ({
    url: ADMIN_PICTURE_URL,
    method: "PUT",
    data: body
  } as AxiosRequestConfig);

export const uploadPictureById = async (
  body: ProfilePictureWithIdDTO
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(uploadPictureByIdParams(body));
};
