import { ReactElement } from "react";
import { NavLinksContainer } from "../NavBarElements";
import { roleBasedLinkMapping } from "../../../../constants/navbarLinkMapping";
import { v4 as uuid } from "uuid";
import useAuth from "../../../../hooks/useAuth";

const BigLinkGroup = (): ReactElement => {
  const { role } = useAuth();
  return (
    <>
      <NavLinksContainer>
        {roleBasedLinkMapping[role].map(({ component: LinkComponent }) => (
          <LinkComponent key={uuid()} />
        ))}
      </NavLinksContainer>
    </>
  );
};

export default BigLinkGroup;
