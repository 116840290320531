export const blobToBase64 = (blob: Blob): Promise<unknown> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const fileToBase64 = (fileParam: File): Promise<string> => {
  return new Promise((resolve) => {
    let fileBase64: string;

    let fileReader: FileReader = new FileReader();

    // Convert the file to base64 text
    fileReader.readAsDataURL(fileParam);

    fileReader.onload = () => {
      fileBase64 = fileReader.result as string;
      resolve(fileBase64);
    };
  });
};
