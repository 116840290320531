import { useTranslation } from "react-i18next";
import { getScrollAttributes } from "../../../utils/getScrollAttributes";
import { ArrowContainer, ArrowMessage } from "./HeroPageElements";
import ArrowDown from "../../../assets/svg/arrowDown.svg";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { ReactElement } from "react";

const ArrowLink = (): ReactElement => {
  const { t } = useTranslation();
  const { scrollY } = useViewportScroll();

  const opacity = useTransform(scrollY, [0, 500], [1, 0]);

  return (
    <ArrowContainer
      activeClass="selectedLink"
      className="dragonBarrier"
      {...getScrollAttributes("whoAreWe")}
    >
      <ArrowMessage style={{ opacity }}>{t("getToKnowUs")}</ArrowMessage>
      <motion.div style={{ opacity }}>
        <img className="arrowDown" src={ArrowDown} alt="arrowDown" />
      </motion.div>
    </ArrowContainer>
  );
};

export default ArrowLink;
