import { PROFILE_PICTURE_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import ProfilePictureDTO from "./models/ProfilePictureDTO";

export const uploadPictureParams = (
  body: ProfilePictureDTO
): AxiosRequestConfig =>
  ({
    url: PROFILE_PICTURE_URL,
    method: "PUT",
    data: body
  } as AxiosRequestConfig);

export const uploadPicture = async (
  body: ProfilePictureDTO
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(uploadPictureParams(body));
};
