import { ReactElement, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import ConfirmDialogContext from "../../shared/ConfirmDialog/ConfirmDialogContext";
import {
  ColumnFlexContainer,
  FlexRowSpaceBetweenContainer
} from "../../shared/DocumentListCore/DocumentListElements";
import {
  AfterProfileComponentTitleContainer,
  MainContentContainer,
  MainTitle
} from "../../shared/layout/LayoutElements";
import WarningAlert from "../../shared/WarningAlert";
import CreateReviewForm from "../CreateReviewForm";
import MToolTip from "../../shared/ToolTip";
import Review from "./Review";
import ReviewContext from "./ReviewContext";
import Role from "../../../constants/roles";

const ReviewListView = (): ReactElement => {
  const { reviews } = useContext(ReviewContext);
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.UserState);

  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  return (
    <MainContentContainer>
      <ColumnFlexContainer>
        <FlexRowSpaceBetweenContainer>
          <MainTitle>{t("reviews")}</MainTitle>
          <AfterProfileComponentTitleContainer>
            {reviews.length > 0 && (
              <MToolTip message={t("reviewCount", { count: reviews.length })}>
                <WarningAlert />
              </MToolTip>
            )}

            <CreateReviewForm />
          </AfterProfileComponentTitleContainer>
        </FlexRowSpaceBetweenContainer>
        <ConfirmDialogContext.Provider
          value={{ isConfirmDialogOpen, setConfirmDialogOpen }}
        >
          {reviews.map(
            ({
              id,
              creatorUserId,
              creatorUserFirstName,
              creatorUserLastName,
              text,
              creationDate
            }) => (
              <Review
                key={id}
                id={id}
                reviewer={`${creatorUserFirstName} ${creatorUserLastName}`}
                text={text}
                creationDate={creationDate}
                deletable={
                  user?.roles.includes(Role.ADMINISTRATOR) ||
                  creatorUserId === user?.id
                }
              />
            )
          )}
        </ConfirmDialogContext.Provider>
      </ColumnFlexContainer>
    </MainContentContainer>
  );
};

export default ReviewListView;
