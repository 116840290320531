import { ReactElement, useEffect, useState } from "react";
import { getImageById } from "../../../api/getImageById";
import { blobToBase64 } from "../../../utils/base64Util";
import ProfilePlaceholderImage from "../../../assets/img/profile_placeholder.png";
import {
  ButtonContainer,
  MainContentContainer,
  MainTitle
} from "../../shared/layout/LayoutElements";
import {
  ActionUserRowContainer,
  PerkContainer,
  PerkPill,
  UserActionLeftContainer,
  VolunteerTilePicture
} from "./ActionUsersElements";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import UserDetailsDTO from "../../../api/models/UserDetailsDTO";
import {
  UserDataContainer,
  UserDetailsContainer
} from "../VolunteersList/VolunteerListElements";
import DetailSpan from "../VolunteersList/DetailSpan";
import { MButton, MButtonContrast } from "../../shared/buttons/ButtonElements";
import ActionUserTileProps from "./models/ActionUserTileProps";
import { useTranslation } from "react-i18next";

const ActionUserTile = ({
  user,
  handleConfirm,
  handleReject
}: ActionUserTileProps): ReactElement => {
  const { t } = useTranslation();
  const { id: reduxId } = useAppSelector(
    (state) => state.UserState.user as UserDetailsDTO
  );
  const [picture, setPicture] = useState<string>(ProfilePlaceholderImage);
  const history = useHistory();

  const goToProfile = () => {
    if (user.id === reduxId) {
      history.push("/profil");
    } else {
      history.push(`/profil/${user.id}`);
    }
  };

  useEffect(() => {
    getImageById(user.id).then((res) => {
      blobToBase64(res.data).then((base64Result) =>
        setPicture(base64Result as string)
      );
    });
  }, []);

  return (
    <MainContentContainer>
      <ActionUserRowContainer>
        <UserActionLeftContainer>
          <VolunteerTilePicture
            className="clickable"
            onClick={goToProfile}
            src={picture}
            alt={`${user.id}-image`}
            id={`${user.id}-image`}
          />
          <UserDataContainer>
            <MainTitle className="clickable" onClick={goToProfile}>
              {user.firstName} {user.lastName}
            </MainTitle>
            <UserDetailsContainer>
              {user.phone && (
                <DetailSpan title={"Phone:"} content={user.phone} />
              )}
            </UserDetailsContainer>
          </UserDataContainer>
          <PerkContainer>
            {user.perks.map((perk) => (
              <PerkPill>
                {perk.type}-{perk.name}
              </PerkPill>
            ))}
          </PerkContainer>
        </UserActionLeftContainer>
        <ButtonContainer>
          <MButton
            color="secondary"
            variant="contained"
            onClick={() => handleReject(user)}
          >
            {t("decline")}
          </MButton>
          <MButtonContrast
            variant="contained"
            onClick={() => handleConfirm(user)}
            color="secondary"
          >
            {t("accept")}
          </MButtonContrast>
        </ButtonContainer>
      </ActionUserRowContainer>
    </MainContentContainer>
  );
};

export default ActionUserTile;
