import { AxiosRequestConfig, AxiosResponse } from "axios";
import { REVIEWS_URL } from "./endpoints";
import ReviewDTO from "../components/app/ReviewList/models/ReviewDTO";
import axiosInstance from "./axiosInstance";
import CreateReviewRequestBody from "./models/CreateReviewRequestBody";

export const createReviewParams = (
  body: CreateReviewRequestBody
): AxiosRequestConfig =>
  ({
    url: REVIEWS_URL,
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const postCreateReview = async (
  body: CreateReviewRequestBody
): Promise<AxiosResponse<ReviewDTO>> =>
  await axiosInstance.request(createReviewParams(body));
