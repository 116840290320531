import { Dispatch, ReactElement, SetStateAction } from "react";
import { Checkbox, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PerkNoQuantityDTO from "../../../api/models/PerkNoQuantityDTO";

interface PerkFilterProps {
  perkFilterValues: PerkNoQuantityDTO[];
  selectedPerks: PerkNoQuantityDTO[];
  setSelectedPerks: Dispatch<SetStateAction<PerkNoQuantityDTO[]>>;
}
const PerkFilterComponent = ({
  perkFilterValues,
  selectedPerks,
  setSelectedPerks
}: PerkFilterProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Autocomplete
      multiple
      value={selectedPerks}
      onChange={(event, value) => setSelectedPerks(value)}
      limitTags={2}
      id="perk-filter"
      options={perkFilterValues}
      disableCloseOnSelect
      getOptionLabel={(option) => `${option.type} - ${option.name}`}
      noOptionsText={t("noCategory")}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.type} - ${option.name}`}
        </>
      )}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t("categories")}
        />
      )}
    />
  );
};

export default PerkFilterComponent;
