import { ReactElement } from "react";
import {
  MessageOfSupport,
  NavBarContainer,
  NavBarWrapper,
  SidebarBGImageContainer,
  SideDrawerContainer
} from "./NavBarElements";
import NavBarLogo from "./NavBarLogo";
import {
  RightNavContainer,
  SeparationLine
} from "../../shared/layout/LayoutElements";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ResponsiveHOC from "../../../hoc/ResponsiveHOC";
import { BREAKPOINT_LG } from "../../../constants/cssConstants";
import BigButtonGroup from "./BigButtonGroup";
import SmallButtonGroup from "./SmallButtonGroup";
import SideDrawer from "./SideDrawer";
import LanguagePicker from "../LanguagePicker";
import SmallLinkGroup from "./SmallLinkGroup";
import BigLinkGroup from "./BigLinkGroup";
import ConditionalHOC from "../../../hoc/ConditionalHOC";
import SmallProfileInfo from "./SmallProfileInfo";
import useAuth from "../../../hooks/useAuth";

const NavBar = (): ReactElement => {
  const { isLoggedIn } = useAuth();
  const { t } = useTranslation();
  return (
    <>
      <NavBarWrapper>
        <NavBarContainer>
          <NavBarLogo />
          <Switch>
            <Route path="/prijava" exact>
              <MessageOfSupport>{t("motivationMessageLogin")}</MessageOfSupport>
            </Route>
            <Route path="/registracija" exact>
              <MessageOfSupport>
                {t("motivationMessageRegister")}
              </MessageOfSupport>
            </Route>
            <Route path="*">
              <ResponsiveHOC
                breakpoint={BREAKPOINT_LG}
                bigScreenComponent={() => {
                  return (
                    <>
                      <BigLinkGroup />
                      <RightNavContainer>
                        <BigButtonGroup />
                      </RightNavContainer>
                    </>
                  );
                }}
                smallScreenComponent={() => {
                  return (
                    <>
                      <RightNavContainer>
                        {/* <NotificationPopper /> */}
                        <SideDrawer>
                          <SidebarBGImageContainer>
                            <ConditionalHOC
                              condition={isLoggedIn}
                              onTrue={SmallProfileInfo}
                            />
                          </SidebarBGImageContainer>
                          <SideDrawerContainer>
                            <SmallButtonGroup />
                            <SeparationLine />
                            <SmallLinkGroup />
                            <SeparationLine />
                            <LanguagePicker />
                          </SideDrawerContainer>
                        </SideDrawer>
                      </RightNavContainer>
                    </>
                  );
                }}
              />
            </Route>
          </Switch>
        </NavBarContainer>
      </NavBarWrapper>
    </>
  );
};

export default NavBar;
