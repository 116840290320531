import { useTranslation } from "react-i18next";
import { NavLink } from "../../app/NavBar/NavBarElements";
import { ReactElement } from "react";
import LinkProps from "./models/LinkProps";
import { LinkContainer } from "../layout/LayoutElements";
import { ListItem } from "@material-ui/core";

const ProfileLink = ({ children }: LinkProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <NavLink to="/profil">
      {/* ListItem is used for font styling */}
      <ListItem>
        <LinkContainer>
          {children}
          {t("profile")}
        </LinkContainer>
      </ListItem>
    </NavLink>
  );
};

export default ProfileLink;
