import { ReactElement, useContext, useEffect, useState } from "react";
import { useField } from "formik";
import PerkWithQuantity from "./models/PerkWithQuantity";
import PerkDTO from "../../../api/models/PerkDTO";
import {
  ActionFormStepFourContainer,
  AutocompleteContainer,
  NewPerkContainer,
  QuantityContainer
} from "./ActionFormElements";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import PerkList from "./PerkList";
import PerkContext from "./PerkContext";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import FabReactive from "../../shared/FabReactive";
import AddIcon from "@material-ui/icons/Add";

const StepFour = (): ReactElement => {
  const [perkTypes, setPerkTypes] = useState<string[]>([]);
  const [selectedPerkType, setSelectedPerkType] = useState<string | null>("");
  const [selectedPerk, setSelectedPerk] = useState<PerkDTO | null>();
  const [quantity, setQuantity] = useState<number>(1);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField<PerkWithQuantity[]>("requiredPerks");
  const { setValue, setError } = helper;
  const { value } = field;
  const { error } = meta;
  const { selectedPerks, setSelectedPerks, perks, setPerks } =
    useContext(PerkContext);
  const { t } = useTranslation();

  useEffect(() => {
    setPerkTypes(Array.from(new Set(perks.map((perk) => perk.type)).values()));
  }, [perks]);
  useEffect(() => {
    setSelectedPerk(null);
    setQuantity(1);
  }, [selectedPerkType]);

  const addPerk = () => {
    if (selectedPerk) {
      selectedPerk.quantity = quantity;
      setSelectedPerks([...selectedPerks, selectedPerk as PerkDTO]);
      setValue([
        ...value,
        {
          perkId: selectedPerk.id,
          quantity: selectedPerk.quantity
        } as PerkWithQuantity
      ]);
      setPerks(perks.filter((perk) => perk.id != selectedPerk.id));
    }
    setSelectedPerk(null);
    setQuantity(0);
    setSelectedPerkType("");
    setError("");
  };

  const removePerk = (removedPerk: PerkDTO) => {
    setSelectedPerks(selectedPerks.filter((perk) => perk.id != removedPerk.id));
    setValue(value.filter((perk) => perk.perkId != removedPerk.id));
    setPerks([...perks, removedPerk]);
  };

  return (
    <ActionFormStepFourContainer>
      <PerkList removePerk={removePerk} />
      <NewPerkContainer>
        <AutocompleteContainer>
          <Autocomplete
            fullWidth
            key={uuid()}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("createActionStep4PerkType")}
                variant="filled"
                error={!!error}
                helperText={error}
              />
            )}
            value={selectedPerkType}
            options={perkTypes}
            onChange={(event, value: string | null) =>
              setSelectedPerkType(value)
            }
          />
        </AutocompleteContainer>
        <AutocompleteContainer>
          <Autocomplete
            fullWidth
            key={selectedPerk?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("createActionStep4Perk")}
                variant="filled"
              />
            )}
            options={perks as PerkDTO[]}
            filterOptions={(options: PerkDTO[]) =>
              options.filter((perk) => perk.type == selectedPerkType)
            }
            getOptionLabel={(option: PerkDTO) => option.name}
            getOptionSelected={(option: PerkDTO, value: PerkDTO) =>
              option.id === value.id
            }
            value={selectedPerk}
            onChange={(event, value: PerkDTO | null) => setSelectedPerk(value)}
            disabled={selectedPerkType == null || selectedPerkType === ""}
          />
        </AutocompleteContainer>
        <QuantityContainer>
          <TextField
            label={t("quantity")}
            type="number"
            value={quantity}
            onChange={(event) => setQuantity(parseInt(event.target.value))}
            disabled={selectedPerk == undefined}
            variant="filled"
            name="quantity"
            inputProps={{ min: 1 }}
          />
        </QuantityContainer>
        <FabReactive
          icon={<AddIcon />}
          label={t("add")}
          onClick={addPerk}
          disabled={selectedPerk == null || quantity <= 0 || isNaN(quantity)}
        />
      </NewPerkContainer>
    </ActionFormStepFourContainer>
  );
};

export default StepFour;
