import DocumentDTO from "../components/app/DocumentList/models/DocumentDTO";
import { DOCUMENT_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export const uploadDocumentParams = (body: DocumentDTO): AxiosRequestConfig =>
  ({
    url: DOCUMENT_URL,
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const postDocument = async (
  body: DocumentDTO
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(uploadDocumentParams(body));
};
