import { Button, Fab } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mPrimary, white } from "../../../constants/colorPallete";
import { mParagrafFontSize } from "../../../constants/cssConstants";
import { theme } from "../../../styles/style";
import { Pagination } from "@material-ui/lab";

export const MButtonContrast = styled(Button)`
  white-space: nowrap;
  background-color: ${theme.palette.warning.main};
  &:hover {
    background-color: ${theme.palette.warning.dark};
  }
`;

export const MButtonMaxWidth = styled(Button)`
  white-space: nowrap;
  width: 100%;
  background-color: ${theme.palette.warning.main};
  &:hover {
    background-color: ${theme.palette.warning.dark};
  }
`;
export const MButtonWide = styled(Button)`
  white-space: nowrap;
  width: 100%;
`;

export const FlexFab = styled(Fab)`
  white-space: nowrap;
  display: flex;
  align-items: center;
  ${theme.breakpoints.down("sm")} {
    height: 36px;
  }
  //entering span text in fab buttons
  & > span > span {
    ${theme.breakpoints.down("sm")} {
      font-size: ${mParagrafFontSize};
    }
  }
`;
export const MButton = styled(Button)`
  white-space: nowrap;
`;
export const MButtonTrash = styled(MButton)`
  color: ${white};
`;
export const MFilterButton = styled(Button)`
  white-space: nowrap;
  background-color: ${white};
  border-radius: 8px;
`;
export const LinkButtonContainer = styled(Button)`
  width: 100%;
  padding: 0px;
  text-transform: none;
`;

export const CircleButton = styled(Button)`
  white-space: nowrap;
  padding: 6px;
  border-radius: 100%;
`;

export const StyledPagination = styled(Pagination)`
  & > ul > li > button {
    color: white;
  }
`;

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
`;
export const HrefTo = styled.a`
  text-decoration: none;
  color: ${mPrimary};
`;
