import { ReactElement, useContext, useEffect, useState } from "react";
import {
  MainContentContainer,
  MainTitle
} from "../../shared/layout/LayoutElements";
import { useTranslation } from "react-i18next";
import {
  CategoryContainer,
  CategoryListContainer,
  FirstRowContainer
} from "./CategoryEditFormElements";
import PerkNoQuantityDTO from "../../../api/models/PerkNoQuantityDTO";
import { getPerks } from "../../../api/getPerks";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import FabReactive from "../../shared/FabReactive";
import { getPerkTypes } from "../../../api/getPerkTypes";
import PerkTypeDTO from "../../../api/models/PerkTypeDTO";
import { deletePerkType } from "../../../api/deletePerkType";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import SubcategoriesEdit from "./SubcategoriesEdit";
import ConfirmDialog from "../../shared/ConfirmDialog";
import AddPerkPerkTypeDialog from "./AddPerkPerkTypeDialog";

const CategoryEditForm = (): ReactElement => {
  const { t } = useTranslation();
  const [perks, setPerks] = useState<PerkNoQuantityDTO[]>([]);
  const [perkCategories, setPerkCategories] = useState<PerkTypeDTO[]>([]);
  const [isAddCategoryDialogOpen, setIsAddCategoryDialogOpen] = useState(false);
  const [selectedPerkTypeId, setSelectedPerkTypeId] = useState(-1);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);

  const handlePerkTypeDelete = () => {
    deletePerkType(selectedPerkTypeId)
      .then(() => {
        fetchPerkAndPerkTypeData();
      })
      .catch(() => setSnackbarErrorMessageKey("deleteFailed"));
  };

  const fetchPerkAndPerkTypeData = (wantedSelectedPerkTypeId?: number) => {
    getPerkTypes()
      .then((response) => {
        setPerkCategories(
          response.data.filter(
            (value) =>
              value.name !== "Coordinator" && value.name !== "Koordinator"
          )
        );
        if (wantedSelectedPerkTypeId) {
          setSelectedPerkTypeId(wantedSelectedPerkTypeId);
        }
      })
      .catch(() => setSnackbarErrorMessageKey("couldNotFetchCategories"));

    getPerks()
      .then((response) => {
        setPerks(response.data);
      })
      .catch(() => setSnackbarErrorMessageKey("couldNotFetchCategories"));
  };

  useEffect(() => {
    fetchPerkAndPerkTypeData();
  }, [t]);

  useEffect(() => {
    if (
      perkCategories.length > 0 &&
      !perkCategories.find((value) => value.id === selectedPerkTypeId)
    ) {
      setSelectedPerkTypeId(perkCategories[0].id);
    }
  }, [perkCategories]);

  const findPerkTypeWithId = (id: number): PerkTypeDTO | undefined => {
    return perkCategories.find((value) => value.id === id);
  };

  const createConfirmDialogMessage = (): string => {
    let selectedPerkType = findPerkTypeWithId(selectedPerkTypeId);

    return selectedPerkType
      ? `${t("confirmCategoryDelete")} ${selectedPerkType.name}?`
      : "";
  };

  const renderSubcategory = (): ReactElement => {
    let selectedPerkType = findPerkTypeWithId(selectedPerkTypeId);

    return selectedPerkType ? (
      <SubcategoriesEdit
        updateData={fetchPerkAndPerkTypeData}
        perkType={selectedPerkType}
        perks={perks.filter(
          (perk) => perk.type === (selectedPerkType as PerkTypeDTO).name
        )}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
      <AddPerkPerkTypeDialog
        isDialogOpen={isAddCategoryDialogOpen}
        setIsDialogOpen={setIsAddCategoryDialogOpen}
        updateData={fetchPerkAndPerkTypeData}
      />
      <ConfirmDialog
        title={createConfirmDialogMessage()}
        open={isConfirmDialogOpen}
        setOpen={setConfirmDialogOpen}
        onConfirm={handlePerkTypeDelete}
      />
      <MainContentContainer>
        <FirstRowContainer>
          <MainTitle>{t("categories")}</MainTitle>
          <FabReactive
            icon={<AddIcon />}
            label={t("add")}
            onClick={() => setIsAddCategoryDialogOpen(true)}
          />
        </FirstRowContainer>
        <CategoryListContainer>
          {perkCategories.map((perkTypeDTO) => (
            <CategoryContainer
              key={`perkType-${perkTypeDTO.id}`}
              onClick={() =>
                perkTypeDTO.id !== selectedPerkTypeId
                  ? setSelectedPerkTypeId(perkTypeDTO.id)
                  : setConfirmDialogOpen(true)
              }
              isSelected={perkTypeDTO.id === selectedPerkTypeId}
            >
              {perkTypeDTO.name}
              {perkTypeDTO.id === selectedPerkTypeId && <DeleteIcon />}
            </CategoryContainer>
          ))}
        </CategoryListContainer>
      </MainContentContainer>
      {renderSubcategory()}
    </>
  );
};

export default CategoryEditForm;
