import { LibraryBooks, Phone } from "@material-ui/icons";
import { ReactElement, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { getActionDetails } from "../../../api/actions";
import ErrorInfo from "../../../constants/ErrorInfo";
import ActionDetailsDTO from "../../../pages/ActionDetails/models/ActionDetailsDTO";
import {
  ActionLocation,
  ActionTitle
} from "../../shared/action/ActionSharedElements";
import DatesComponent from "../../shared/action/DatesComponent";
import { filterFilledPerks } from "../../shared/action/perkUtilFunctions";
import ActionSignUp from "../../shared/ActionSignUp";
import { MButton } from "../../shared/buttons/ButtonElements";
import ConfirmDialogContext from "../../shared/ConfirmDialog/ConfirmDialogContext";
import { ActionCardDescription } from "../ActionFeed/ActionFeedElements";
import MTabs from "../Tabs";
import TabInfoProps from "../Tabs/models/TabInfoProps";
import {
  ActionBottomCardContainer,
  ActionDetailsContainer,
  ActionDetailsLeftContainer,
  ActionDetailsLocationTitle,
  ActionDetailsOuterContainer,
  ActionDetailsRightContainer,
  ButtonGroup,
  CategoryDesktopContainer,
  CategoryMobileContainer,
  LocationIcon,
  TabContainer
} from "./ActionDetailsElements";
import Categories from "./Categories";
import UserList from "./UserList";
import WorkRecords from "./WorkRecords";
import {
  FlexColumnContainer,
  WarningContainer
} from "../../shared/layout/LayoutElements";
import MToolTip from "../../shared/ToolTip";
import WarningAlert from "../../shared/WarningAlert";
import DragonSpinner from "../../shared/LoadingAnimation";
import TimesComponent from "../../shared/action/TimesComponent";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import { CardTitle } from "../../shared/text/TextElements";

interface actionParams {
  id: string;
}

const ActionDetails = (): ReactElement => {
  const { id } = useParams<actionParams>();
  const [action, setAction] = useState<ActionDetailsDTO | null>(null);
  const { t } = useTranslation();
  const [needToUpdateActionData, setNeedToUpdateActionData] = useState(false);
  const [snackbarErrorMessageKey, setSnackbarErrorMessageKey] = useState("");

  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const tabs: TabInfoProps[] = [
    { label: t("actionDescription"), icon: <LibraryBooks /> },
    { label: t("actionContacts"), icon: <Phone /> }
  ];

  const history = useHistory();

  const goToPreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    if (needToUpdateActionData) {
      getActionDetails(parseInt(id))
        .then((response) => {
          setAction(response.data);
          setNeedToUpdateActionData(false);
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.status
          } as ErrorInfo);
        });
    }
  }, [needToUpdateActionData]);

  useEffect(() => {
    setNeedToUpdateActionData(true);
  }, [t]);

  return action ? (
    <>
      <ActionDetailsOuterContainer>
        <ActionDetailsContainer>
          {action.isEnrolled && !action.isConfirmed && (
            <WarningContainer>
              <MToolTip message={t("actionNeedsApproval")}>
                <WarningAlert />
              </MToolTip>
            </WarningContainer>
          )}
          <ActionDetailsLeftContainer>
            <ActionTitle>{action.title}</ActionTitle>
            <ActionLocation>
              <LocationIcon />
              <ActionDetailsLocationTitle>
                {action.location}
              </ActionDetailsLocationTitle>
            </ActionLocation>
            <TabContainer>
              <MTabs tabInfo={tabs}>
                <Scrollbars autoHeight>
                  <ActionCardDescription>
                    {action.description}
                  </ActionCardDescription>
                </Scrollbars>
                <UserList confirmedUsers={action.confirmedUsers} />
              </MTabs>
            </TabContainer>
            <DatesComponent
              startDate={action.startDate}
              endDate={action.endDate}
            />
            <TimesComponent
              startTime={action.startTime}
              endTime={action.endTime}
            />
          </ActionDetailsLeftContainer>
          <ActionDetailsRightContainer>
            <CategoryDesktopContainer>
              <Categories
                confirmedUsers={action.confirmedUsers}
                requirements={action.requirements}
                listOfPerkIdsUserIsOn={action.listOfPerkIdsUserIsOn}
              />
            </CategoryDesktopContainer>
            <ButtonGroup>
              <ConfirmDialogContext.Provider
                value={{ isConfirmDialogOpen, setConfirmDialogOpen }}
              >
                {!action.isConfirmed && (
                  <MButton
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => setConfirmDialogOpen(true)}
                  >
                    {action.isEnrolled ? t("update") : t("signUpForAction")}
                  </MButton>
                )}
                {isConfirmDialogOpen && (
                  <ActionSignUp
                    perks={filterFilledPerks(action)}
                    selectedPerks={action.listOfPerkIdsUserIsOn}
                    actionId={action.id}
                    setNeedToUpdateActionData={setNeedToUpdateActionData}
                    isUpdating={action.isEnrolled}
                  />
                )}
              </ConfirmDialogContext.Provider>
              <MButton
                onClick={goToPreviousPage}
                variant="contained"
                color="secondary"
                size="large"
              >
                {t("actionGoBack")}
              </MButton>
            </ButtonGroup>
          </ActionDetailsRightContainer>
        </ActionDetailsContainer>
        <CategoryMobileContainer>
          <ActionDetailsOuterContainer>
            <Categories
              confirmedUsers={action.confirmedUsers}
              requirements={action.requirements}
              listOfPerkIdsUserIsOn={action.listOfPerkIdsUserIsOn}
            />
          </ActionDetailsOuterContainer>
        </CategoryMobileContainer>
      </ActionDetailsOuterContainer>
      <ActionDetailsOuterContainer>
        <ActionBottomCardContainer>
          <FlexColumnContainer>
            <CardTitle>{t("workHours")}</CardTitle>
            <SnackbarErrorContext.Provider
              value={{
                snackbarErrorMessageKey,
                setSnackbarErrorMessageKey
              }}
            >
              {action.isConfirmed && <WorkRecords action={action} />}
            </SnackbarErrorContext.Provider>
          </FlexColumnContainer>
        </ActionBottomCardContainer>
      </ActionDetailsOuterContainer>
    </>
  ) : (
    <DragonSpinner withBackdrop />
  );
};

export default ActionDetails;
