import { ReactElement } from "react";
import { Dialog } from "@material-ui/core";
import {
  DocumentAddContainer,
  DocumentAddFormContainer,
  DocumentIconAndTitleContainer,
  DocumentTitle,
  HugeDescriptionIcon
} from "./DocumentListElements";
import { getCutOriginalFileName } from "../../../utils/getCutFileName";
import { MButtonMaxWidth, MButtonWide } from "../buttons/ButtonElements";
import FileEditPopupProps from "./models/FileEditPopupProps";
import { useTranslation } from "react-i18next";

const FileEditPopup = ({
  isOpen,
  setDialogOpen,
  fileName,
  documentIsApproved,
  downloadDocument,
  setDeleteDialogOpen,
  patchApprovalStatus,
  canApprove,
  canDelete
}: FileEditPopupProps): ReactElement => {
  const { t } = useTranslation();

  const handlePopupClose = () => {
    setDialogOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handlePopupClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DocumentAddContainer>
        <DocumentIconAndTitleContainer>
          <HugeDescriptionIcon />
          <DocumentTitle>{getCutOriginalFileName(fileName)}</DocumentTitle>
        </DocumentIconAndTitleContainer>
        <DocumentAddFormContainer>
          <MButtonMaxWidth
            type={"button"}
            variant={"contained"}
            color={"secondary"}
            onClick={() => {
              downloadDocument(fileName);
              handlePopupClose();
            }}
          >
            {t("download")}
          </MButtonMaxWidth>
          {canApprove &&
            (!documentIsApproved ? (
              <MButtonMaxWidth
                color={"secondary"}
                type={"button"}
                variant={"contained"}
                onClick={() => {
                  patchApprovalStatus(true, fileName);
                  handlePopupClose();
                }}
              >
                {t("approve")}
              </MButtonMaxWidth>
            ) : (
              <MButtonMaxWidth
                color={"secondary"}
                type={"button"}
                variant={"contained"}
                onClick={() => {
                  patchApprovalStatus(false, fileName);
                  handlePopupClose();
                }}
              >
                {t("disapprove")}
              </MButtonMaxWidth>
            ))}
          {canDelete && (
            <MButtonWide
              type={"button"}
              variant={"contained"}
              color={"secondary"}
              onClick={() => setDeleteDialogOpen(true)}
            >
              {t("delete")}
            </MButtonWide>
          )}
        </DocumentAddFormContainer>
      </DocumentAddContainer>
    </Dialog>
  );
};

export default FileEditPopup;
