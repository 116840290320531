import ChangePasswordDTO from "./models/ChangePasswordDTO";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { CHANGE_PASSWORD_PASSWORD_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";

const changePasswordEnterPasswordParams = (
  body: ChangePasswordDTO
): AxiosRequestConfig =>
  ({
    url: CHANGE_PASSWORD_PASSWORD_URL,
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const changePasswordEnterPassword = async (
  body: ChangePasswordDTO
): Promise<AxiosResponse<void>> =>
  await axiosInstance.request(changePasswordEnterPasswordParams(body));
