import { ReactElement, useContext } from "react";
import { Category, PerkListContainer } from "./ActionFormElements";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PerkListProps from "./models/PerkListProps";
import { v4 as uuid } from "uuid";
import PerkContext from "./PerkContext";

const PerkList = ({ removePerk }: PerkListProps): ReactElement => {
  const { selectedPerks } = useContext(PerkContext);
  return (
    <PerkListContainer>
      {selectedPerks.map((perk) => (
        <Category key={uuid()}>
          {`${perk.type}  -  ${perk.name}  :  ${perk.quantity}`}
          <Button startIcon={<DeleteIcon />} onClick={() => removePerk(perk)} />
        </Category>
      ))}
    </PerkListContainer>
  );
};
export default PerkList;
