import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GET_ALL_ACTIONS_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import ActionFormData from "../components/app/ActionForm/models/ActionFormData";
import ActionDetailsDTO from "../pages/ActionDetails/models/ActionDetailsDTO";

export const createActionParams = (body: ActionFormData): AxiosRequestConfig =>
  ({
    url: GET_ALL_ACTIONS_URL,
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const postCreateAction = async (
  body: ActionFormData
): Promise<AxiosResponse<ActionDetailsDTO>> => {
  return await axiosInstance.request(createActionParams(body));
};
