import styled from "styled-components";
import { motion } from "framer-motion";
import { fadeVariants } from "../../../constants/animationVariantConstants";

export const FadeAnimation = styled(motion.div).attrs(() => ({
  initial: "initial",
  animate: "in",
  exit: "out",
  variants: fadeVariants
}))``;
