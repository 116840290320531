import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { getJobTypes } from "../../../api/getJobTypes";
import JobType from "../../../api/models/JobType";
import { valueNotSet } from "../../../constants/errorConstants";
import {
  ColmAlignLeftContainer,
  FlexColumnContainer,
  FlexRowContainer
} from "../../shared/layout/LayoutElements";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import { MLabel } from "../../shared/text/TextElements";
import MToolTip from "../../shared/ToolTip";
import ProfileTabProps from "./models/ProfileTabProps";
import { PersonalInfoContainer } from "./ProfileInfoElements";

const InterestsTab = ({ user }: ProfileTabProps): ReactElement => {
  const [jobTypes, setJobTypes] = useState<JobType[]>([]);
  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  const { t } = useTranslation();

  useEffect(() => {
    getJobTypes()
      .then((response) => {
        setJobTypes(response.data);
      })
      .catch(() => {
        setSnackbarErrorMessageKey("genericError");
      });
  }, []);
  return (
    <ColmAlignLeftContainer>
      <PersonalInfoContainer>
        <MLabel>{t("interests")}</MLabel>
        {user?.perkIds ? (
          <FlexColumnContainer>
            {jobTypes
              .filter((jobType) =>
                user.jobTypeIds?.some((jobTypeId) => jobTypeId === jobType.id)
              )
              .map((filteredJobType) => (
                <FlexRowContainer key={uuid()}>
                  <MToolTip message={filteredJobType.description}>
                    <InfoRoundedIcon />
                  </MToolTip>
                  {filteredJobType.name}
                </FlexRowContainer>
              ))}
          </FlexColumnContainer>
        ) : (
          valueNotSet
        )}
      </PersonalInfoContainer>
    </ColmAlignLeftContainer>
  );
};

export default InterestsTab;
