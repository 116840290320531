import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import { ReactElement } from "react";

const RoleGetter = (): ReactElement => {
  const { t } = useTranslation();
  const renderRole = (roleNumber: number | undefined) => {
    switch (roleNumber) {
      case 2:
        return t("coordinator");
      case 3:
        return t("administrator");
      default:
        return t("volunteer");
    }
  };
  const { user } = useAppSelector((state) => state.UserState);
  return <>{renderRole(user?.roles?.length)}</>;
};

export default RoleGetter;
