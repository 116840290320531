export const REGEX_NAME_SURNAME =
  /^([A-ZČĆŠŽĐ][a-zA-ZČĆŠŽĐčćšžđ]+)([ -]{0,3}[A-ZČĆŠŽĐ][a-zA-ZČĆŠŽĐčćšžđ]+)*$/;

export const REGEX_PASSWORD = /(?=.*\d)(?=.*[a-zčćžšđ])(?=.*[A-ZČĆŽŠĐ]).*/;

export const REGEX_EMAIL = new RegExp(
  "[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,6}$",
  "i"
);

export const REGEX_TIME_STRING = /[0-2]?[0-9]:[0-5]?[0-9]:[0-5]?[0-9]/;
