import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GET_ACTION_USERS_FOR_ACTION_ID } from "./endpoints";
import axiosInstance from "./axiosInstance";
import ActionUserUpdateDTO from "./models/ActionUserUpdateDTO";
import ActionUserDTO from "./models/ActionUserDTO";

export const patchActionUserParams = (
  data: ActionUserUpdateDTO,
  actionId: string
): AxiosRequestConfig =>
  ({
    url: GET_ACTION_USERS_FOR_ACTION_ID(actionId),
    method: "PATCH",
    data: data
  } as AxiosRequestConfig);

export const patchActionUser = async (
  user: ActionUserUpdateDTO,
  actionId: string
): Promise<AxiosResponse<ActionUserDTO[]>> => {
  return await axiosInstance.request(patchActionUserParams(user, actionId));
};
