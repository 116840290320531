import { useTranslation } from "react-i18next";
import { NavLink } from "../../app/NavBar/NavBarElements";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { conditionallyHighlighted } from "../../../utils/cssUtils";

const CategoryEditLink = (): ReactElement => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  return (
    <NavLink
      to="/kategorije"
      highlighted={conditionallyHighlighted(pathname, "/kategorije")}
    >
      {t("categories")}
    </NavLink>
  );
};

export default CategoryEditLink;
