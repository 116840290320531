import NameAndQuantityDTO from "../api/models/NameAndQuantityDTO";
import {
  mError,
  mLightBlue,
  mPrimary,
  mSecondary,
  mWarning
} from "./colorPallete";

export const PI_CHART_WIDTH = 450;
export const PI_CHART_HEIGHT = 250;

export const NOT_EMPTY_PREDICATE = (group: NameAndQuantityDTO): boolean =>
  group.quantity > 0;

export const PI_CHART_COLORS = [
  mPrimary,
  mSecondary,
  mWarning,
  mError,
  mLightBlue
];

export const MAX_DATE = new Date(8640000000000000);
export const MIN_DATE = new Date(-8640000000000000);
