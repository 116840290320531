import Cookies from "js-cookie";
import { JWT_COOKIE_KEY, JWT_HEADER_PREFIX } from "../constants/apiConstants";

export const createJwtHeader = (jwt: string): string => {
  return JWT_HEADER_PREFIX + jwt;
};

export const saveJwtFromHeader = (header: string | undefined): void => {
  if (header !== undefined) {
    Cookies.set(JWT_COOKIE_KEY, header.substring(JWT_HEADER_PREFIX.length), {
      expires: 365
    });
  }
};

export const removeJwt = (): void => {
  Cookies.remove(JWT_COOKIE_KEY);
};

export const getJwtFromCookies = (): string | undefined => {
  return Cookies.get(JWT_COOKIE_KEY);
};
