import { ReactElement, useEffect, useRef, useState } from "react";
import AvatarEditorCore from "../../shared/AvatarEditorCore";
import ProfilePlaceholderImage from "../../../assets/img/profile_placeholder.png";
import { getImageById } from "../../../api/getImageById";
import { ForeignProfilePictureEditorProps } from "./models/ForeignProfilePictureEditorProps";
import { blobToBase64 } from "../../../utils/base64Util";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import { uploadPictureById } from "../../../api/uploadPictureById";
import Role from "../../../constants/roles";

const ForeignProfilePictureEditor = ({
  userId
}: ForeignProfilePictureEditorProps): ReactElement => {
  const { user } = useAppSelector((state) => state.UserState);
  const [profilePicture, setProfilePicture] = useState<string>(
    ProfilePlaceholderImage
  );

  const [base64Image, setBase64Image] = useState<string>("");
  const editorRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const [showProfilePictureEditorDialog, setShowProfilePictureEditorDialog] =
    useState<boolean>(false);
  const [isPictureReadyToSend, setIsPictureReadyToSend] =
    useState<boolean>(false);
  const [errorMessageId, setErrorMessageId] = useState<string>("");
  const [scale, setScale] = useState<number>(1.2);

  useEffect(() => {
    getImageById(userId).then((res) => {
      blobToBase64(res.data).then((base64Result) => {
        setProfilePicture(base64Result as string);
      });
    });
  }, []);

  const handleProfilePictureEditorDialogOpen = () => {
    // Because only admin can change other users profile picture
    if (user?.roles?.find((role) => role === Role.ADMINISTRATOR)) {
      setShowProfilePictureEditorDialog(true);
      setScale(1.2);
    }
  };

  const handleProfilePictureEditorDialogClose = () => {
    setIsPictureReadyToSend(false);
    setShowProfilePictureEditorDialog(false);
    setBase64Image("");
  };

  const onUpload = (): void => {
    const canvasScaled = editorRef?.current?.getImageScaledToCanvas();
    // Variable is used because state does not guarantee immediate change
    // and it must be insured that new image gets sent to server
    let imageInBase64Format = canvasScaled.toDataURL();

    // Split is necessary for the file to be stored correctly on server
    // It is not split before because html img tag requires the original format
    uploadPictureById({
      picture: imageInBase64Format.split(",")[1],
      userId: userId
    })
      .then(() => {
        setProfilePicture(imageInBase64Format);
      })
      .catch(() => {
        setErrorMessageId("uploadFailure");
      });
    handleProfilePictureEditorDialogClose();
  };

  return (
    <AvatarEditorCore
      base64Image={base64Image}
      setBase64Image={setBase64Image}
      scale={scale}
      setScale={setScale}
      errorMessageId={errorMessageId}
      setErrorMessageId={setErrorMessageId}
      handleProfilePictureEditorDialogClose={
        handleProfilePictureEditorDialogClose
      }
      handleProfilePictureEditorDialogOpen={
        handleProfilePictureEditorDialogOpen
      }
      isPictureReadyToSend={isPictureReadyToSend}
      setIsPictureReadyToSend={setIsPictureReadyToSend}
      showProfilePictureEditorDialog={showProfilePictureEditorDialog}
      profilePicture={profilePicture}
      editorRef={editorRef}
      onUpload={onUpload}
    />
  );
};

export default ForeignProfilePictureEditor;
