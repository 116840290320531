import { useTranslation } from "react-i18next";
import { NavLink } from "../../app/NavBar/NavBarElements";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { conditionallyHighlighted } from "../../../utils/cssUtils";

const ActionListLink = (): ReactElement => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  return (
    <NavLink
      to="/akcije"
      highlighted={conditionallyHighlighted(pathname, "/akcije")}
    >
      {t("actionList")}
    </NavLink>
  );
};

export default ActionListLink;
