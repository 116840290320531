import { ReactElement } from "react";
import { useHistory } from "react-router";
import { MAX_TITLE_LENGTH } from "../../../constants/userActionsConstants";
import { getCutTextThreeDots } from "../../../utils/getCutTextThreeDots";
import { ActionTitle } from "../action/ActionSharedElements";
import { ActionTitleClickableProps } from "./models/ActionTitleClickableProps";

const ActionTitleClickable = ({
  title,
  id
}: ActionTitleClickableProps): ReactElement => {
  const history = useHistory();

  const toActionPage = (id: number) => {
    history.push(`/akcije/${id}`);
  };
  return (
    <ActionTitle onClick={() => toActionPage(id)}>
      {getCutTextThreeDots(title, MAX_TITLE_LENGTH)}
    </ActionTitle>
  );
};

export default ActionTitleClickable;
