import FileType from "file-type";
import { fileToBase64 } from "./base64Util";

export interface processImageFileToBase64Params {
  imageFile: File;
  setErrorMessageId: (errorMessageId: string) => void;
  handleProfilePictureEditorDialogClose: () => void;
  setBase64Image: (base64Image: string) => void;
}

export const processImageFileToBase64 = ({
  setBase64Image,
  imageFile,
  setErrorMessageId,
  handleProfilePictureEditorDialogClose
}: processImageFileToBase64Params): void => {
  if (imageFile) {
    let fileReader: FileReader = new FileReader();
    fileReader.readAsArrayBuffer(imageFile as File);

    fileReader.onload = () => {
      FileType.fromBuffer(fileReader.result as ArrayBuffer).then((res) => {
        if (res === undefined) {
          setErrorMessageId("invalidFileType");
          handleProfilePictureEditorDialogClose();
          return;
        }
        let mimeType = res?.mime;
        if (
          mimeType !== "image/jpeg" &&
          mimeType !== "image/png" &&
          mimeType !== "image/webp"
        ) {
          setErrorMessageId("invalidFileType");
          handleProfilePictureEditorDialogClose();
          return;
        }
        fileToBase64(imageFile as File).then((result: string) => {
          setBase64Image(result);
        });
      });
    };
  }
};
