import { ReactElement } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAppSelector } from "../../store/reducers/userDetailsReducer";
import { AuthenticatedRouteProps } from "./models/AuthenticatedRouteProps";

const AuthenticatedRoute = ({
  component: Component,
  ...rest
}: AuthenticatedRouteProps): ReactElement => {
  const { logged } = useAppSelector((state) => state.UserState);

  return (
    <Route
      {...rest}
      render={(props) => {
        return logged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/prijava",
              state: {
                snackbarData: {
                  severity: "info",
                  messageKey: "authenticationRequired"
                }
              }
            }}
          />
        );
      }}
    />
  );
};

export default AuthenticatedRoute;
