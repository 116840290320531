//String is reduced to first 26 char + ..last 4
export const getCutOriginalFileName = (value: string): string => {
  if (value.length > 30) {
    return `${value.substring(0, 26)}..${value.substring(
      value.length - 4,
      value.length
    )}`;
  } else {
    return value;
  }
};
