import { ReactElement } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { MButtonMaxWidth, MButtonWide } from "../buttons/ButtonElements";
import ConfirmDialogProps from "./models/ConfirmDialogProps";
import { StyledDialogContent } from "./ConfirmDialogElements";

const ConfirmDialog = ({
  title,
  open,
  onConfirm,
  content,
  setOpen
}: ConfirmDialogProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <StyledDialogContent id="confirm-dialog-content">
        {content}
      </StyledDialogContent>
      <DialogActions>
        <MButtonMaxWidth
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          variant={"contained"}
          color={"primary"}
        >
          {t("yes")}
        </MButtonMaxWidth>
        <MButtonWide
          variant="contained"
          onClick={() => setOpen(false)}
          color="secondary"
        >
          {t("no")}
        </MButtonWide>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
