import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import EnterPassword from "../../components/app/RestartPassword/EnterPassword";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import EnterMail from "../../components/app/RestartPassword/EnterMail";
import RestartPasswordParams from "./models/RestartPasswordParams";

const RestartPasswordPage = (): ReactElement => {
  const { id } = useParams<RestartPasswordParams>();
  return (
    <PageContainer>{(id && <EnterPassword />) || <EnterMail />}</PageContainer>
  );
};

export default RestartPasswordPage;
