import SVGIconProps from "./models/SVGIconProps";
import { ReactElement } from "react";
import { iconMapping } from "../../../constants/iconMapping";

/**
 * SVG icon. Wraps a svg asset with the given name into a react component, which
 * can then be easily reused.
 *
 * @param name name of the icon to be loaded into a component. {@see iconMapping} {@see SVGIconProps}
 */
const SVGIcon = ({ name }: SVGIconProps): ReactElement => {
  const Icon = iconMapping[name]; // load icon from map
  if (Icon) {
    // successfully loaded
    return <Icon />;
  } else {
    return <div>Missing</div>; // placeholder
  }
};

export default SVGIcon;
