import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDate,
  ActionDateContainer,
  ActionDatesContainer,
  ItalicTypography,
  StyledAccessTime
} from "./ActionSharedElements";
import { Typography } from "@material-ui/core";
import TimesComponentProps from "./models/TimesComponentProps";
import { cutOutSecondsFromStringTimeFormat } from "../../../utils/dateTimeUtils";

const TimesComponent = ({
  startTime,
  endTime
}: TimesComponentProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <ActionDatesContainer>
      <ActionDateContainer>
        <ItalicTypography color={"secondary"}>
          {t("startTime")}
        </ItalicTypography>
        <ActionDate>
          <StyledAccessTime />
          <Typography>
            {cutOutSecondsFromStringTimeFormat(startTime)}
          </Typography>
        </ActionDate>
      </ActionDateContainer>
      <ActionDateContainer>
        <ItalicTypography color={"secondary"}>{t("endTime")}</ItalicTypography>
        <ActionDate>
          <StyledAccessTime />
          <Typography>{cutOutSecondsFromStringTimeFormat(endTime)}</Typography>
        </ActionDate>
      </ActionDateContainer>
    </ActionDatesContainer>
  );
};

export default TimesComponent;
