import { GET_USER_DETAILS_BY_ID_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import UserDetailsProfileDTO from "./models/UserDetailsDTO";

export const getUserDetailsByIdParams = (userId: string): AxiosRequestConfig =>
  ({
    url: GET_USER_DETAILS_BY_ID_URL(userId),
    method: "GET"
  } as AxiosRequestConfig);

export const getUserDetailsById = async (
  userId: string
): Promise<AxiosResponse<UserDetailsProfileDTO>> => {
  return await axiosInstance.request(getUserDetailsByIdParams(userId));
};
