import ActionDTO from "../pages/ActionFeed/models/ActionDTO";
import { ENGLISH } from "../constants/languageConstants";

/**
 * Filters out the given array of actions, according to the given language.
 *
 * If the language is english, removes all the actions that have empty
 * content (title, shortDescription and description). Those actions are
 * meant only for the croatian users and will not be shown to the english
 * users.
 * @param actions actions to filter out
 * @param language application language at the time of filtering
 * @returns filteredActions - filtered actions
 */
export const removeEmptyEnglishActions = (
  actions: ActionDTO[],
  language: string
): ActionDTO[] => {
  if (language === ENGLISH) {
    return actions.filter(
      (value) =>
        value.title !== "" &&
        value.description !== "" &&
        value.shortDescription !== ""
    );
  }
  return actions;
};
