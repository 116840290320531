import { ReactElement, useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import i18next from "i18next";
import { CROATIAN } from "../../../constants/languageConstants";
import { enUS, hr } from "date-fns/locale";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Paper } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TimePickerProps from "./models/TimePickerProps";
import { useField } from "formik";
import {
  getCurrentDateWithSetTimeFromTimeOnlyFormat,
  getCurrentDateWithZeroSeconds,
  getTimeOnlyString
} from "../../../utils/dateTimeUtils";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const TimePicker = ({ label, name }: TimePickerProps): ReactElement => {
  const [time, setTime] = useState(getCurrentDateWithZeroSeconds());

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(name);
  const { setValue } = helper;
  const { value } = field;

  useEffect(() => {
    if (value == null || value == "") {
      let test = getTimeOnlyString(time);
      console.log(test);
      setValue(getTimeOnlyString(time));
    } else {
      let date = getCurrentDateWithSetTimeFromTimeOnlyFormat(value);
      setTime(date);
    }
  }, [value]);

  function onChange(date: MaterialUiPickersDate) {
    let dateAsDate = date as Date;
    if (dateAsDate) {
      dateAsDate.setSeconds(0);
      setTime(dateAsDate);
      const timeOnlyString = getTimeOnlyString(dateAsDate);
      setValue(timeOnlyString);
    }
  }

  return (
    <>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={i18next.language == CROATIAN ? hr : enUS}
      >
        <KeyboardTimePicker
          ampm={false}
          className="responsivePicker"
          label={label}
          value={time}
          margin="normal"
          inputVariant="filled"
          DialogProps={{ PaperComponent: Paper }}
          keyboardIcon={<AccessTimeIcon className="whiteIcon" />}
          onChange={onChange}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default TimePicker;
