import { NotificationDTO } from "../api/models/NotificationDTO";
import { NOTIFICATIONS_WS_URL } from "../constants/notificationConstants";

/**
 * In the given array of notifications, finds the one with the given id
 * and returns its index.
 *
 * @param notifications array of notifications
 * @param id id of the notification to be found
 * @returns index of the notification with the given id.
 */
export const getIndexById = (
  notifications: NotificationDTO[],
  id: number
): number => notifications.map((notification) => notification.id).indexOf(id);

/**
 * Resolves the current url for websocket connection.
 */
export const getWebsocketUrl = (): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const REACT_APP_BACKEND_URL = window._env_?.REACT_APP_BACKEND_URL;
  if (!REACT_APP_BACKEND_URL) {
    return `wss://localhost:8080${NOTIFICATIONS_WS_URL}`;
  } else {
    return `wss://${REACT_APP_BACKEND_URL}${NOTIFICATIONS_WS_URL}`;
  }
};
