import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GET_ACTION_USERS_FOR_ACTION_ID } from "./endpoints";
import axiosInstance from "./axiosInstance";
import ActionUserDTO from "./models/ActionUserDTO";

export const getActionUsersParams = (actionId: string): AxiosRequestConfig =>
  ({
    url: GET_ACTION_USERS_FOR_ACTION_ID(actionId),
    method: "GET"
  } as AxiosRequestConfig);

export const getActionUsers = async (
  actionId: string
): Promise<AxiosResponse<ActionUserDTO[]>> => {
  return await axiosInstance.request(getActionUsersParams(actionId));
};
