import { ReactElement, useState } from "react";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import VolunteersList from "../../components/app/VolunteersList";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import SnackbarErrorContext from "../../components/shared/SnackbarErrorContext";
import { PageTitle } from "../../components/shared/text/TextElements";

const Volunteers = (): ReactElement => {
  const [snackbarErrorMessageKey, setSnackbarErrorMessageKey] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <PageContainer>
        <PageTitle>{t("volunteerList")}</PageTitle>
        <Snackbar
          open={snackbarErrorMessageKey.length !== 0}
          autoHideDuration={10000}
          onClose={() => setSnackbarErrorMessageKey("")}
        >
          <MuiAlert elevation={6} variant="filled" severity="error">
            {t(snackbarErrorMessageKey)}
          </MuiAlert>
        </Snackbar>
        <SnackbarErrorContext.Provider
          value={{
            snackbarErrorMessageKey,
            setSnackbarErrorMessageKey
          }}
        >
          <VolunteersList />
        </SnackbarErrorContext.Provider>
      </PageContainer>
    </>
  );
};

export default Volunteers;
