import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosInstance from "./axiosInstance";
import { CONFIRM_EMAIL_URL } from "./endpoints";
import ConfirmEmailDTO from "./models/ConfirmEmailDTO";

const confirmEmailParams = (body: ConfirmEmailDTO): AxiosRequestConfig =>
  ({
    url: CONFIRM_EMAIL_URL,
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const confirmEmail = async (
  body: ConfirmEmailDTO
): Promise<AxiosResponse<void>> =>
  await axiosInstance.request(confirmEmailParams(body));
