import { ReactElement } from "react";
import { ProfileInfoDrawer } from "../SideDrawer/SideDrawerElements";
import SmallProfileImage from "../../../shared/BasicProfileInfo/SmallProfileImage";
import NameRole from "../../../shared/BasicProfileInfo/NameRole";

const SmallProfileInfo = (): ReactElement => (
  <ProfileInfoDrawer>
    <SmallProfileImage />
    <NameRole />
  </ProfileInfoDrawer>
);

export default SmallProfileInfo;
