import { CSSProperties } from "react";

export const navHeight = "90px";

//Font sizes
export const mHeroDesktop = "95.37px";
export const mH1 = "61.04px";
export const mH2 = "48.83px";
export const mH3 = "39.06px";
export const mH4 = "31.25px";
export const mH5 = "25.00px";
export const mH6 = "20.00px";
export const mNormalText = "16.00px";
export const mParagrafFontSize = "12.80px";
export const mParagrafSmallFontSIze = "10.24px";

export const BREAKPOINT_MD = 960;
export const BREAKPOINT_LG = 1280;
export const mPhoneInputStyle: CSSProperties = {
  backgroundColor: `rgba(25, 0, 50, 0.31)`,
  color: "white",
  width: "100%",
  border: "none",
  borderTopLeftRadius: "6px",
  borderTopRightRadius: "6px",
  borderBottomLeftRadius: "0px",
  borderBottomRightRadius: "0px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
};
export const mPhoneButtonStyle: CSSProperties = {
  backgroundColor: "transparent",
  color: "white",
  border: "none"
};
export const mBoxShadow =
  "0px 60px 98px rgba(255, 255, 255, 0.07),0px 38.8889px 57.3935px rgba(255, 255, 255, 0.0531481),0px 23.1111px 31.2148px rgba(255, 255, 255, 0.0425185),0px 12px 15.925px rgba(255, 255, 255, 0.035),0px 4.88889px 7.98519px rgba(255, 255, 255, 0.0274815),0px 1.11111px 3.85648px rgba(255, 255, 255, 0.0168519)";

export const mBoxShadowOrange =
  "0px 60px 98px rgba(255, 107, 0, 0.12),0px 38.8889px 57.3935px rgba(255, 107, 0, 0.0831481),0px 23.1111px 31.2148px rgba(255, 107, 0, 0.0525185),0px 12px 15.925px rgba(255, 107, 0, 0.045),0px 4.88889px 7.98519px rgba(255, 107, 0, 0.0324815),0px 1.11111px 3.85648px rgba(255, 107, 0, 0.0168519)";
export const mBoxShadowOrangeSmall = "0px 6px 8px 0px rgba(255,107,0,0.82)";

export const mTitleTextShadow =
  "0px 4px 3px rgba(0, 0, 0, 0.4),0px 8px 13px rgba(0, 0, 0, 0.1),0px 18px 23px rgba(0, 0, 0, 0.1)";
export const mHeroTopBoxShadow =
  "0px -4px 3px rgba(0, 0, 0, 0.4),0px -8px 13px rgba(0, 0, 0, 0.1),0px -18px 23px rgba(0, 0, 0, 0.1)";
