import styled from "styled-components";
import { ButtonBase, Slider } from "@material-ui/core";

export const ImageDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-grow: 1;
  overflow: hidden;
  flex-wrap: wrap;
  padding-bottom: 5px;
`;

export const CustomSlider = styled(Slider)`
  width: 90%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 95%;
  flex-grow: 1;
  flex-wrap: wrap;
`;

export const CircularImage = styled.img`
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;

export const CircularButtonBase = styled(ButtonBase)`
  border-radius: 50%;
`;
