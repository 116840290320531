import { ProfileWizardStep2Data } from "../components/app/ProfileWizard/models/ProfileWizardData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GET_ADMIN_SKILLS_BY_ID_URL } from "./endpoints";
import UserDetailsDTO from "./models/UserDetailsDTO";
import axiosInstance from "./axiosInstance";

const skillsParams = (
  body: ProfileWizardStep2Data,
  id: string
): AxiosRequestConfig =>
  ({
    url: GET_ADMIN_SKILLS_BY_ID_URL(id),
    method: "PATCH",
    data: body
  } as AxiosRequestConfig);

export const adminPostSkills = async (
  body: ProfileWizardStep2Data,
  id: string
): Promise<AxiosResponse<UserDetailsDTO>> =>
  await axiosInstance.request(skillsParams(body, id));
