import ReviewProps from "./models/ReviewProps";
import { ReactElement, useContext, useState } from "react";
import {
  CreationDate,
  ReviewContainer,
  ReviewerName,
  ReviewText
} from "./ReviewElements";
import { useTranslation } from "react-i18next";
import { getIntlDateString } from "../../../utils/dateTimeUtils";
import { Button, ButtonBase } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { FlexRowContainer } from "../../shared/layout/LayoutElements";
import ReviewContext from "./ReviewContext";
import ConfirmDialog from "../../shared/ConfirmDialog";

const Review = ({
  id,
  reviewer,
  text,
  creationDate,
  deletable = true
}: ReviewProps): ReactElement => {
  const { t } = useTranslation();

  const { onDelete } = useContext(ReviewContext);

  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  return (
    <>
      <ButtonBase>
        <ConfirmDialog
          title={t("confirmDeleteReview")}
          open={isConfirmDialogOpen}
          setOpen={setConfirmDialogOpen}
          onConfirm={() => onDelete(id)}
        />
      </ButtonBase>
      <ReviewContainer>
        <FlexRowContainer>
          <ReviewerName>- {reviewer}</ReviewerName>
          {deletable && (
            <Button onClick={() => setConfirmDialogOpen(true)}>
              <DeleteIcon />
            </Button>
          )}
        </FlexRowContainer>
        <ReviewText>{text}</ReviewText>
        <br />
        <CreationDate>
          {t("written")} {getIntlDateString(creationDate)}
        </CreationDate>
      </ReviewContainer>
    </>
  );
};

export default Review;
