import { Grid } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import { ReactElement } from "react";
import { ExternalLink, HrefTo } from "../../shared/buttons/ButtonElements";
import { FooterContainer, FooterGrid } from "./FooterElements";
import { useTranslation } from "react-i18next";

const Footer = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <FooterGrid container item>
        <ExternalLink href="https://www.facebook.com/groups/177048109062661/">
          <FacebookIcon fontSize="large" />
        </ExternalLink>
        <ExternalLink href="https://www.facebook.com/groups/1674716669436495/">
          <MonetizationOnRoundedIcon fontSize="large" />
        </ExternalLink>
      </FooterGrid>
      <FooterGrid container>
        <Grid item>
          <p>
            {t("address")}: Našička 3, 10 000 Zagreb
            <br />
            {t("phone")}:
            <HrefTo href="callto:+385915670618"> +385 (0) 91 567 0618</HrefTo>
            <br />
            {t("phone")}:
            <HrefTo href="callto:+38516701307"> +385 (1) 6701 307</HrefTo>
          </p>
          <p>
            OIB: 60174266484
            <br />
            IBAN: HR5824840081105567475 (RBA)
            <br />
            SWIFT: RZBHHR2X
            <br />
            E-mail:{" "}
            <HrefTo href="mailto:malizmaj.hr@gmail.com">
              malizmaj.hr@gmail.com
            </HrefTo>
          </p>
        </Grid>
        <Grid item>
          <p>
            {t("workTimeDesc")}
            <br />
            {t("monThu", { time: "15:00 - 20:00" })}
            <br />
            {t("warehousePhone")}
            <HrefTo href="callto:+385915974474"> +385 091 597 4474</HrefTo>
          </p>
          <p>
            {t("associationWorkTime")}
            <br />
            {t("workDay", { time: "12:00 - 20:00" })}
            <br />
            {t("homeOffice")}
            <br />
            {t("monThu", { time: "12:00 - 15:00" })}
            <br />
            {t("fridayHours", { time: "12:00 - 20:00" })}
          </p>
        </Grid>
      </FooterGrid>
    </FooterContainer>
  );
};

export default Footer;
