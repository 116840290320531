// TODO - add VISUAL information for 2nd part of form

import { ReactElement } from "react";
import { ActionFormStepOneContainer } from "./ActionFormElements";
import InputField from "../../shared/formFields/InputField";
import { useTranslation } from "react-i18next";

const StepTwo = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <ActionFormStepOneContainer>
      <InputField
        variant="filled"
        label={t("createActionStep2Title")}
        fullWidth
        name="titleEnglish"
      />
      <InputField
        variant="filled"
        label={t("createActionStep2ShortDescription")}
        fullWidth
        name="shortDescriptionEnglish"
        minRows={3}
        multiline={true}
      />
      <InputField
        variant="filled"
        label={t("createActionStep2Description")}
        fullWidth
        name="descriptionEnglish"
        minRows={9}
        multiline={true}
      />
    </ActionFormStepOneContainer>
  );
};

export default StepTwo;
