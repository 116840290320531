import { Alert } from "@material-ui/lab";
import { motion } from "framer-motion";
import styled from "styled-components";
import background from "../../../assets/svg/Background_DRagonScales.svg";
import {
  mainContainerVariants,
  pageTransitionVariants
} from "../../../constants/animationVariantConstants";
import {
  mPrimary,
  mSecondary,
  mTranSecondary,
  mWarning,
  white
} from "../../../constants/colorPallete";
import {
  mBoxShadow,
  mBoxShadowOrange,
  mH3,
  mH6,
  navHeight
} from "../../../constants/cssConstants";
import { theme } from "../../../styles/style";

export const SignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  gap: 24px;
  color: #fff;
  @media (max-width: 980px) {
    gap: 12px;
  }
  @media (max-width: 400px) {
    max-width: 400px;
  }
`;

export const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
  @media (max-width: 980px) {
    gap: 12px;
  }
`;
export const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  @media (max-width: 980px) {
    gap: 12px;
  }
`;
export const ColmAlignLeftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 980px) {
    gap: 12px;
  }
`;

export const FlexSBContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 980px) {
    gap: 12px;
  }
`;
export const FlexColumnGapContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  @media (max-width: 980px) {
    gap: 12px;
  }
`;
export const WarningContainer = styled.div`
  position: absolute;
  right: 32px;
  top: 18px;
`;

export const PageContainer = styled(motion.div).attrs(() => ({
  initial: "initial",
  animate: "in",
  exit: "out",
  variants: pageTransitionVariants
}))`
  margin-top: ${navHeight};
  margin-bottom: 180px;
  padding-top: 24px;
  width: 90%;
  min-height: calc(100vh - ${navHeight} - 24px);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  gap: 24px;
`;

export const PageContainerNoMargin = styled(PageContainer)`
  margin-bottom: 0px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;
export const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
  font-size: 15px;
  font-weight: 500;
  color: inherit;
`;
export const LeftNavContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
export const RightNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  gap: 16px;
`;
export const BurgerNavigationContainer = styled.div`
  ${theme.breakpoints.up("lg")} {
    display: none;
  }
`;

export const EnrolledBanner = styled.div<{
  isConfimedStyle: boolean;
  isEnrolledStyle: boolean;
}>`
  width: 80%;
  height: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  top: 0;
  align-self: center;
  background-color: ${({ isConfimedStyle, isEnrolledStyle }) =>
    isEnrolledStyle && !isConfimedStyle ? `${mWarning}` : `${mPrimary}`};
  position: absolute;
`;

export const MainContentContainer = styled(motion.div).attrs(() => ({
  //FramerMotion animation attributes
  initial: "initial",
  animate: "in",
  exit: "out",
  variants: mainContainerVariants
}))`
  width: 1030px;
  position: relative;
  padding: 36px 54px;
  background: ${white};
  border: 1px solid ${mSecondary};
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.isEnrolledStyle ? `${mBoxShadowOrange}` : `${mBoxShadow}`};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${theme.breakpoints.down("md")} {
    width: 790px;
    padding: 12px 30px;
    gap: 16px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 480px;
    padding: 12px 24px;
  }
  ${theme.breakpoints.down("xs")} {
    width: 360px;
    padding: 12px 12px;
  }
  @media (max-width: 400px) {
    width: 300px;
  }
`;

export const MainTitle = styled.div`
  font-size: ${mH3};
  font-weight: bold;
  width: auto;
  ${theme.breakpoints.down("md")} {
    font-size: ${mH6};
  }
`;

export const MForm = styled.form`
  width: 100%;
  height: auto;
`;
export const SeparationLine = styled.div`
  background-color: ${mTranSecondary};
  height: 2px;
  width: 100%;
`;

export const ContainerFullWidth = styled.div`
  width: 100%;
  height: auto;
`;

export const AfterProfileComponentTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
`;

export const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

export const MAlert = styled(Alert)`
  ${theme.breakpoints.down("md")} {
    padding: 0px 0px 0px 12px;
  }
`;

export const HideDownSm = styled.div`
  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`;

export const BackgroundImage = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  top: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: -10;
`;

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
`;
