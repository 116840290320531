import { ReactElement, useContext, useState } from "react";

import { getUserDetails } from "../../../api/getUserDetails";
import DocumentListCore from "../../shared/DocumentListCore";
import { getDocument } from "../../../api/getDocument";
import fileDownload from "js-file-download";
import { deleteDocument } from "../../../api/deleteDocument";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import DocumentNoFileDTO from "../../shared/DocumentListCore/models/DocumentNoFileDTO";
import DocumentDTO from "../../shared/DocumentListCore/models/DocumentDTO";
import { postDocument } from "../../../api/uploadDocument";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";

const Index = (): ReactElement => {
  const { user } = useAppSelector((state) => state.UserState);
  const [documentTitles, setDocumentTitles] = useState<DocumentNoFileDTO[]>([]);
  const [needUpdate, setNeedUpdate] = useState<boolean>(true);

  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);

  const fetchDocuments = () => {
    getUserDetails()
      .then((res) => {
        setDocumentTitles(res.data.documents);
        setNeedUpdate(false);
      })
      .catch(() => {
        setSnackbarErrorMessageKey("downloadFailure");
      });
  };

  const downloadDocumentFile = (fileName: string) => {
    getDocument(fileName)
      .then((res) => {
        let finalFileName = fileName.endsWith(".pdf")
          ? fileName
          : `${fileName}.pdf`;
        fileDownload(res.data, finalFileName);
      })
      .catch(() => setSnackbarErrorMessageKey("downloadFailure"));
  };

  const deleteDocumentFile = (fileName: string) => {
    deleteDocument(fileName)
      .then(() => {
        setNeedUpdate(true);
      })
      .catch(() => setSnackbarErrorMessageKey("documentDeleteFailure"));
  };

  const uploadDocument = (documentDTO: DocumentDTO) => {
    postDocument(documentDTO)
      .then(() => {
        setNeedUpdate(true);
      })
      .catch(() => {
        setSnackbarErrorMessageKey("uploadDocumentFailure");
      });
  };

  return (
    <DocumentListCore
      documentTitles={documentTitles}
      setNeedUpdate={setNeedUpdate}
      deleteDocument={deleteDocumentFile}
      canDelete={true}
      // Even if user is admin he does not need to approve on his profile,
      // because all admin docs are approved
      canApprove={false}
      needUpdate={needUpdate}
      fetchDocuments={fetchDocuments}
      uploadDocument={uploadDocument}
      downloadDocument={downloadDocumentFile}
      userId={user?.id as string}
    />
  );
};

export default Index;
