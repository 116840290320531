import { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserDetailsById } from "../../../api/getUserDetailsById";
import UserDetailsDTO from "../../../api/models/UserDetailsDTO";
import { PROFILE_WIZARD_STEPS } from "../../../constants/profileWizardSteps";
import ProfileParams from "../../../pages/Profile/models/ProfileParams";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import useSteps from "../../../hooks/useFormStepCounter";
import ProfileWizardStep from "./ProfileWizardStep";
import UserContext from "./UserContext";
import ProfileWizardProps from "./models/ProfileWizardProps";
import Role from "../../../constants/roles";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";

const ProfileWizard = ({ close }: ProfileWizardProps): ReactElement => {
  const { id } = useParams<ProfileParams>();
  const [userData, setUserData] = useState<UserDetailsDTO>();
  const { user } = useAppSelector((state) => state.UserState);
  const admin = user ? user.roles.includes(Role.ADMINISTRATOR) : false;
  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  //backlog put UserContext.Provider  over whole profile so it updates.
  useEffect(() => {
    if (id) {
      getUserDetailsById(id)
        .then((res) => {
          setUserData(res.data);
        })
        .catch(() => {
          setSnackbarErrorMessageKey("genericError");
        });
    } else {
      setUserData(user);
    }
  }, [id]);
  const { step, nextStep, previousStep } = useSteps(
    PROFILE_WIZARD_STEPS.length
  );
  return (
    <>
      <UserContext.Provider
        value={{
          user: userData,
          setUser: setUserData,
          isAdmin: admin && id != null
        }}
      >
        <ProfileWizardStep
          step={step}
          afterSubmit={nextStep}
          cancel={previousStep}
          close={close}
        />
      </UserContext.Provider>
    </>
  );
};

export default ProfileWizard;
