import { Typography } from "@material-ui/core";
import { AccessTime } from "@material-ui/icons";
import styled, { css } from "styled-components";
import {
  mBlackGreen,
  mLightGreen,
  mSecondary,
  mWarning,
  mWhiteOrange,
  white
} from "../../../constants/colorPallete";
import { theme } from "../../../styles/style";

export const ActionTitle = styled.h3`
  font-weight: bold;
  word-break: break-word;
  font-size: xx-large;
  cursor: pointer;
  margin-block: 0px;
  //margin-right needs to because of alert on action cards
  margin-right: 42px;
  ${theme.breakpoints.down("xs")} {
    font-size: large;
  }
`;
export const ActionLocation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 8px;
  background: #394441;
  border-radius: 8px;
  column-gap: 5px;
`;

export const ActionDatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 10px;
`;
export const ActionDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
export const ActionDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface CategoryProps {
  isEnrolled?: boolean;
  isConfirmed?: boolean;
}

export const Category = styled.p<CategoryProps>`
  background: ${mSecondary};
  color: ${white};
  border-radius: 18px;
  font-size: 16px;
  padding: 6px 12px;
  margin: 5px;
  font-weight: bold;
  min-width: 150px;
  text-align: center;

  @media (max-width: 400px) {
    font-size: 12px;
    min-width: 100px;
  }

  ${(props) =>
    props.isEnrolled &&
    css`
      background-color: ${mWarning};
      color: ${mWhiteOrange};
    `}
  ${(props) =>
    props.isConfirmed &&
    css`
      background-color: ${mLightGreen};
      color: ${mBlackGreen};
    `}
`;
export const ItalicTypography = styled(Typography)`
  font-style: italic;
`;
export const StyledAccessTime = styled(AccessTime)`
  margin-right: 4px;
`;
