import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { Formik, FormikHelpers, FormikProps } from "formik";
import {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { adminPostInterests } from "../../../api/adminPostInterests";
import { getJobTypes } from "../../../api/getJobTypes";
import JobType from "../../../api/models/JobType";
import { postInterests } from "../../../api/postInterests";
import ErrorInfo from "../../../constants/ErrorInfo";
import { saveUserDetails } from "../../../store/actions/userDetailsActions";
import { MButton, MButtonContrast } from "../../shared/buttons/ButtonElements";
import { MForm, SignWrapper } from "../../shared/layout/LayoutElements";
import { WizardTitle } from "../../shared/text/TextElements";
import MToolTip from "../../shared/ToolTip";
import { ButtonContainer } from "../ActionForm/ActionFormElements";
import { ProfileWizardStep3Data } from "./models/ProfileWizardData";
import ProfileWizardStepProps from "./models/ProfileWizardStepProps";
import {
  CheckboxContainer,
  CheckBoxLabel,
  FlexEndContainer,
  SelectionContainer,
  TitleContainer
} from "./ProfileWizardElements";
import UserContext from "./UserContext";
import CloseDialogButton from "../../shared/buttons/CloseDialogButton";
import { Checkbox } from "@material-ui/core";

const StepThree = ({ cancel, close }: ProfileWizardStepProps): ReactElement => {
  const [jobTypes, setJobTypes] = useState<JobType[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, setUser, isAdmin } = useContext(UserContext);

  useEffect(() => {
    getJobTypes().then((response) => {
      setJobTypes(response.data);
    });
  }, []);

  const INITIAL_VALUES = {
    jobTypeIds: user ? user.jobTypeIds : []
  } as ProfileWizardStep3Data;

  const handleSubmit = (
    values: ProfileWizardStep3Data,
    { setSubmitting }: FormikHelpers<ProfileWizardStep3Data>
  ) => {
    if (isAdmin && user) {
      adminPostInterests(values as ProfileWizardStep3Data, user.id)
        .then((res) => {
          setUser(res.data);
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        });
    } else {
      postInterests(values as ProfileWizardStep3Data)
        .then((res) => {
          dispatch(saveUserDetails(res.data));
          setUser(res.data);
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        });
    }
    setSubmitting(false);
  };
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        submitForm
      }: FormikProps<ProfileWizardStep3Data>) => (
        <MForm onSubmit={handleSubmit} onChange={handleChange}>
          <SignWrapper style={{ maxWidth: "600px" }}>
            <TitleContainer>
              <WizardTitle>{t("wizardStepThreeTitle")}</WizardTitle>
              <CloseDialogButton close={close} />
            </TitleContainer>
            <CheckboxContainer role="group" aria-labelledby="jobTypeIds-group">
              {jobTypes.map((jobType) => {
                return (
                  <SelectionContainer key={uuid()}>
                    <CheckBoxLabel key={uuid()}>
                      <Checkbox
                        name="jobTypeIds"
                        value={jobType.id}
                        checked={values.jobTypeIds.some(
                          (job) => job == jobType.id
                        )}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const { checked } = event.target;
                          if (!checked) {
                            values.jobTypeIds = values.jobTypeIds.filter(
                              (jobTypeId) => jobTypeId != jobType.id
                            );
                          }
                        }}
                      />
                      {jobType.name}
                    </CheckBoxLabel>
                    <MToolTip message={jobType.description} key={uuid()}>
                      <InfoRoundedIcon />
                    </MToolTip>
                  </SelectionContainer>
                );
              })}
            </CheckboxContainer>
            <ButtonContainer>
              <MButton
                color="secondary"
                variant="contained"
                onClick={cancel}
                startIcon={<NavigateBefore />}
              >
                {t("createActionFormPreviousStep")}
              </MButton>
              <MButtonContrast
                type="submit"
                name="submit"
                color="secondary"
                variant="contained"
                disabled
                endIcon={<NavigateNext />}
              >
                {t("createActionFormNextStep")}
              </MButtonContrast>
            </ButtonContainer>
          </SignWrapper>
          <FlexEndContainer>
            <MButton
              autoFocus
              onClick={() => {
                submitForm().then(close);
              }}
              variant="contained"
              color="primary"
            >
              {t("saveAndExit")}
            </MButton>
          </FlexEndContainer>
        </MForm>
      )}
    </Formik>
  );
};

export default StepThree;
