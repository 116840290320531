import { AxiosRequestConfig, AxiosResponse } from "axios";
import { PERK_TYPES_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import PerkTypeDTO from "./models/PerkTypeDTO";

export const getPerkTypesParams = (): AxiosRequestConfig =>
  ({
    url: PERK_TYPES_URL,
    method: "GET"
  } as AxiosRequestConfig);

export const getPerkTypes = async (): Promise<AxiosResponse<PerkTypeDTO[]>> => {
  return await axiosInstance.request(getPerkTypesParams());
};
