import { Snackbar, TextField, Typography } from "@material-ui/core";
import LockIcon from "@material-ui/icons/LockOutlined";
import { MButtonMaxWidth } from "../../shared/buttons/ButtonElements";
import {
  FlexSBContainer,
  SignWrapper
} from "../../shared/layout/LayoutElements";
import { AnchorLink, IconContainer, IconHeaderContainer } from "./FormElements";
import { useFormikContext } from "formik";
import LoginFormData from "../../../pages/Login/models/LoginFormData";
import { ReactElement, useState } from "react";
import { useLocation } from "react-router-dom";
import RedirectedState from "../../../pages/Login/models/RedirectedState";
import MuiAlert from "@material-ui/lab/Alert";
import Copyright from "../../shared/Copyright";
import { useTranslation } from "react-i18next";

const Login = (): ReactElement => {
  const { errors, isSubmitting } = useFormikContext<LoginFormData>();
  const { state } = useLocation<RedirectedState>();
  const [open, setOpen] = useState(state !== undefined);
  const handleClose = () => {
    setOpen(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <SignWrapper>
        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={state?.snackbarData.severity}
          >
            {t(state?.snackbarData.messageKey)}
          </MuiAlert>
        </Snackbar>
        <IconHeaderContainer>
          <IconContainer>
            <LockIcon />
          </IconContainer>
          <Typography variant="h5">{t("loginTitle")}</Typography>
        </IconHeaderContainer>
        <TextField
          variant="filled"
          label={t("email")}
          fullWidth
          name="email"
          id="email"
          error={!!errors.email}
          helperText={errors.email}
          type="email"
        />
        <TextField
          variant="filled"
          label={t("password")}
          fullWidth
          name="password"
          type="password"
          id="password"
          error={!!errors.password}
          helperText={errors.password}
        />

        <MButtonMaxWidth
          variant="contained"
          color="secondary"
          name="submit"
          type="submit"
          disabled={isSubmitting}
        >
          {t("loginAttempt")}
        </MButtonMaxWidth>
        <FlexSBContainer>
          <AnchorLink to="/promjena-lozinke">
            <Typography variant="body2">{t("forgotPassword")}</Typography>
          </AnchorLink>
          <AnchorLink to="/registracija">
            <Typography variant="body2">{t("registerHint")}</Typography>
          </AnchorLink>
        </FlexSBContainer>
        <Copyright />
      </SignWrapper>
    </>
  );
};

export default Login;
