import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { mSecondary } from "../../../constants/colorPallete";

export const FooterContainer = styled.footer`
  position: relative;
  padding-top: 18px;
  width: 100%;
  background-color: ${mSecondary};
  display: flex;
  flex-direction: column;
  align-content: center;
  color: white;
  z-index: 25;
`;
export const FooterGrid = styled(Grid)`
  padding: 12px;
  gap: 48px;
`;
