export interface ProfileWizardStep1Data {
  address?: string;
  country?: {
    name: string;
    code: string;
  };
  dateOfBirth?: string;
  email: string;
  firstName: string;
  gender?: string;
  lastName: string;
  phoneIsPrivate: boolean;
  phone?: string;
}
export enum EMPLOYMENT_STATUS {
  DISCIPLE = "DISCIPLE",
  EMPLOYED = "EMPLOYED",
  RETIRED = "RETIRED",
  STUDENT = "STUDENT",
  UNEMPLOYED = "UNEMPLOYED"
}
export const EmploymentStatusArray = [
  "DISCIPLE",
  "EMPLOYED",
  "RETIRED",
  "STUDENT",
  "UNEMPLOYED"
];
export interface ProfileWizardStep2Data {
  employmentStatusName?: EMPLOYMENT_STATUS;
  perkIds?: number[];
  profession?: string;
}

export interface ProfileWizardStep3Data {
  jobTypeIds: number[];
}
