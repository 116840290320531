import { Tab, useTheme } from "@material-ui/core";
import { ReactElement, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { v4 as uuid } from "uuid";
import MTabsProps from "./models/MTabsProps";
import { CustomTabs } from "./TabElements";
import TabPanel from "./TabPanel";

const MTabs = (props: MTabsProps): ReactElement => {
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  return (
    <>
      <CustomTabs
        value={value}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        {props.tabInfo.map((tab) => (
          <Tab label={tab.label} icon={tab.icon} key={uuid()} {...a11yProps} />
        ))}
      </CustomTabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {props.children.map((element, index) => {
          return (
            <TabPanel
              value={value}
              index={index}
              dir={theme.direction}
              key={uuid()}
            >
              {element}
            </TabPanel>
          );
        })}
      </SwipeableViews>
    </>
  );
};

export default MTabs;
