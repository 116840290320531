import {
  motion,
  MotionStyle,
  TargetAndTransition,
  useMotionValue,
  useSpring
} from "framer-motion";
import { MouseEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import DragonSvg from "../../assets/svg/dragon.svg";
import HeroPage from "../../components/app/HeroPage";
import ArrowLink from "../../components/app/HeroPage/ArrowLink";
import HeroInfoSection from "../../components/app/HeroPage/HeroInfoSection";
import {
  DragonSvgDiv,
  InspirationQuote,
  QuoteContainer,
  WaveSvgDiv
} from "../../components/app/HeroPage/HeroPageElements";
import { PageContainerNoMargin } from "../../components/shared/layout/LayoutElements";

const Home = (): ReactElement => {
  const { t } = useTranslation();
  const x = useMotionValue(200);
  const y = useMotionValue(200);
  const [flip, setFlip] = useState(false);

  const handleMouseTrack = (event: MouseEvent) => {
    //change depending on cursor size
    x.set(event?.clientX - 16);
    y.set(event?.clientY - 86);
    handleFlip();
  };

  const springConfig = { damping: 25, stiffness: 100, mass: 5 };
  const cursorXSpring = useSpring(x, springConfig);
  const cursorYSpring = useSpring(y, springConfig);
  const customCursorPosition: MotionStyle = {
    translateX: cursorXSpring,
    translateY: cursorYSpring
  };
  const handleFlip = () => {
    //calculates position of a dragon with his previous position so we can flip him
    setFlip(cursorXSpring.get() - cursorXSpring.getPrevious() < 0);
  };

  const handleFlipAnimation: TargetAndTransition = { rotateY: flip ? -180 : 0 };
  return (
    <>
      <div id="heroPage" onMouseMove={handleMouseTrack}>
        <motion.div
          className="cursor"
          style={customCursorPosition}
          initial={{ rotateY: 0 }}
          animate={handleFlipAnimation}
        />

        <PageContainerNoMargin>
          <HeroPage />
          <ArrowLink />
        </PageContainerNoMargin>
        <DragonSvgDiv className="custom-shape-divider-bottom-1630487986 dragonBarrier ">
          <img className="responsiveImg" src={DragonSvg} alt="dragonSvg" />
        </DragonSvgDiv>
        <HeroInfoSection />
        <WaveSvgDiv className="custom-shape-divider-top-1633940255 dragonBarrier">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              className="shape-fill"
            ></path>
          </svg>
        </WaveSvgDiv>
        <QuoteContainer className="dragonBarrier">
          <InspirationQuote>{t("inspirationQuote")}</InspirationQuote>
        </QuoteContainer>
      </div>
    </>
  );
};

export default Home;
