import React, { ReactElement, useContext, useState } from "react";
import { getActionReportForInterval } from "../../../api/getActionReportForInterval";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import { useTranslation } from "react-i18next";
import ReportDTO from "../../../api/models/ReportDTO";
import ReportCard from "./ReportCard";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ActionReportsContainer, DatePickerContainer } from "./ReportElements";
import { MButton, StyledPagination } from "../../shared/buttons/ButtonElements";
import { PAGE_SIZE } from "../../../constants/paginationConstants";
import { MAX_DATE, MIN_DATE } from "../../../constants/reportConstants";
import { Paper } from "@material-ui/core";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import i18next from "i18next";
import { enUS, hr } from "date-fns/locale";
import { CROATIAN } from "../../../constants/languageConstants";
const Report = (): ReactElement => {
  const [reportDTO, setReportDTO] = useState<ReportDTO | undefined>();
  const [actionsPage, setActionsPage] = useState(1);
  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const { t } = useTranslation();

  const getPageCount = (): number => {
    if (reportDTO == null || reportDTO.actionReportDetailsList.length == 0) {
      return 1;
    }
    return Math.ceil(reportDTO.actionReportDetailsList.length / PAGE_SIZE);
  };

  const handleSearch = () => {
    if (dateTo == null || dateFrom == null) {
      setSnackbarErrorMessageKey("pleaseSetDateValues");
    } else {
      getActionReportForInterval(dateFrom, dateTo)
        .then((res) => {
          setReportDTO(res.data);
        })
        .catch(() => setSnackbarErrorMessageKey(t("downloadFailure")));
    }
  };

  return (
    <>
      <DatePickerContainer>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={i18next.language == CROATIAN ? hr : enUS}
        >
          <KeyboardDatePicker
            name="dateFrom"
            label={t("dateFrom")}
            format="dd.MM.yyyy"
            value={dateFrom}
            maxDate={dateTo === null ? MAX_DATE : dateTo}
            inputVariant="filled"
            keyboardIcon={<EventRoundedIcon className="whiteIcon" />}
            DialogProps={{ PaperComponent: Paper }}
            onChange={(date: MaterialUiPickersDate) => setDateFrom(date)}
          />
          <KeyboardDatePicker
            name="dateTo"
            label={t("dateTo")}
            format="dd.MM.yyyy"
            value={dateTo}
            inputVariant="filled"
            keyboardIcon={<EventRoundedIcon className="whiteIcon" />}
            DialogProps={{ PaperComponent: Paper }}
            onChange={(date: MaterialUiPickersDate) => setDateTo(date)}
            minDate={dateFrom === null ? MIN_DATE : dateFrom}
          />
        </MuiPickersUtilsProvider>
        <MButton
          onClick={handleSearch}
          variant={"contained"}
          color={"primary"}
          size={"large"}
        >
          {t("generateReport")}
        </MButton>
      </DatePickerContainer>
      {reportDTO && (
        <>
          <ReportCard
            totalWorkHours={reportDTO.totalWorkHours}
            ageGroups={reportDTO.ageGroups}
            genderGroups={reportDTO.genderGroups}
            title={t("reportSummary")}
            userReportDetailsDTOSet={reportDTO.userReportDetailsDTOSet}
            totalActionsInPeriod={reportDTO.actionReportDetailsList.length}
          />
          <ActionReportsContainer>
            {reportDTO.actionReportDetailsList
              .slice((actionsPage - 1) * PAGE_SIZE, actionsPage * PAGE_SIZE)
              .map((details) => (
                <ReportCard {...details} />
              ))}
          </ActionReportsContainer>
          <StyledPagination
            count={getPageCount()}
            page={actionsPage}
            onChange={(event, value) => setActionsPage(value)}
          />
        </>
      )}
    </>
  );
};

export default Report;
