import { ReactElement } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const Copyright = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="secondary">
      {t("copyright")}&copy;Mali zmaj {new Date().getFullYear()}
    </Typography>
  );
};

export default Copyright;
