import { ReactElement, useState } from "react";

import { ContainerFullWidth } from "../../shared/layout/LayoutElements";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

const Register = (): ReactElement => {
  const [stepCount, setStepCount] = useState(1);

  const childNextHandler = () => {
    setStepCount(stepCount + 1);
  };
  const childPreviousHandler = () => {
    setStepCount(stepCount - 1);
  };

  return (
    <>
      <ContainerFullWidth className={stepCount === 1 ? "center" : "hidden"}>
        {/* StepCount is needed for reseting the validation when you step back to step one. */}
        <StepOne nextStep={childNextHandler} stepCount={stepCount} />
      </ContainerFullWidth>
      <ContainerFullWidth className={stepCount === 2 ? "center" : "hidden"}>
        <StepTwo backStep={childPreviousHandler} />
      </ContainerFullWidth>
    </>
  );
};

export default Register;
