import { ReactElement, useEffect, useState } from "react";
import { useAppSelector } from "../../store/reducers/userDetailsReducer";
import DocumentList from "../../components/app/DocumentList";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import ReviewList from "../../components/app/ReviewList";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SnackbarErrorContext from "../../components/shared/SnackbarErrorContext";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ProfileParams from "./models/ProfileParams";
import ForeignDocumentList from "../../components/app/ForeignDocumentList";
import ProfileInfo from "../../components/app/ProfileInfo";
import RoleSelector from "../../components/app/RoleSelector";
import Role from "../../constants/roles";
import axiosInstance from "api/axiosInstance";
import UserDetailsDTO from "api/models/UserDetailsDTO";
import { USER_DETAILS_URL } from "api/endpoints";
import { saveUserDetails } from "store/actions/userDetailsActions";
import { useDispatch } from "react-redux";

const ProfilePage = (): ReactElement => {
  const { logged, user } = useAppSelector((state) => state.UserState);
  const [snackbarErrorMessageKey, setSnackbarErrorMessageKey] = useState("");
  const { t } = useTranslation();
  const { id } = useParams<ProfileParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    axiosInstance.get<UserDetailsDTO>(USER_DETAILS_URL).then((res) => {
      dispatch(saveUserDetails(res.data));
    });
  }, []);

  return (
    <>
      {logged ? (
        <PageContainer>
          <Snackbar
            open={snackbarErrorMessageKey.length !== 0}
            autoHideDuration={10000}
            onClose={() => setSnackbarErrorMessageKey("")}
          >
            <MuiAlert elevation={6} variant="filled" severity="error">
              {t(snackbarErrorMessageKey)}
            </MuiAlert>
          </Snackbar>
          <SnackbarErrorContext.Provider
            value={{
              snackbarErrorMessageKey,
              setSnackbarErrorMessageKey
            }}
          >
            <ProfileInfo />

            {id ? (
              <ForeignDocumentList userId={id as string} />
            ) : (
              <DocumentList />
            )}
            {id && <ReviewList targetUserId={id as string} />}
            {id && user?.roles.includes(Role.ADMINISTRATOR) && (
              <RoleSelector id={id} />
            )}
          </SnackbarErrorContext.Provider>
        </PageContainer>
      ) : (
        <div>Not logged in</div>
      )}
    </>
  );
};
export default ProfilePage;
