import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { ReactElement } from "react";

interface CloseDialogButtonProps {
  close: () => void;
}

const CloseDialogButton = ({ close }: CloseDialogButtonProps): ReactElement => {
  return (
    <IconButton
      aria-label="close"
      onClick={close}
      style={{
        position: "absolute",
        right: 8,
        top: 8
      }}
    >
      <Close />
    </IconButton>
  );
};
export default CloseDialogButton;
