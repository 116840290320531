import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosInstance from "./axiosInstance";
import { GET_DOCUMENT_URL } from "./endpoints";

export const downloadDocumentParams = (fileName: string): AxiosRequestConfig =>
  ({
    url: GET_DOCUMENT_URL(fileName),
    method: "GET",
    responseType: "blob"
  } as AxiosRequestConfig);

export const getDocument = async (
  fileName: string
): Promise<AxiosResponse<BlobPart>> => {
  return await axiosInstance.request(downloadDocumentParams(fileName));
};
