import { NotificationDTO } from "../../api/models/NotificationDTO";

export const ADD = "ADD";
export const REMOVE = "REMOVE";
export const NONE = "NONE";

interface addNotificationType {
  type: typeof ADD;
  payload: NotificationDTO;
}

interface removeNotificationType {
  type: typeof REMOVE;
  payload: number;
}

interface emptyType {
  type: typeof NONE;
}

export type notificationActionType =
  | addNotificationType
  | removeNotificationType
  | emptyType;
