import { AxiosRequestConfig, AxiosResponse } from "axios";
import { PERKS_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import PerkNoQuantityDTO from "./models/PerkNoQuantityDTO";

export const perkParams = (): AxiosRequestConfig =>
  ({
    url: PERKS_URL,
    method: "GET"
  } as AxiosRequestConfig);

export const getPerks = async (): Promise<
  AxiosResponse<PerkNoQuantityDTO[]>
> => {
  return await axiosInstance.request(perkParams());
};
