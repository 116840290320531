import { TextField } from "@material-ui/core";
import styled from "styled-components";
import { mParagrafSmallFontSIze } from "../../../constants/cssConstants";
import { theme } from "../../../styles/style";

export const VolunteerTilePicture = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  ${theme.breakpoints.down("md")} {
    width: 50px;
    height: 50px;
  }
`;

export const ProfileTileFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

export const UserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  border-top: 1px solid black;
`;

export const DetailSpanContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
`;

export const DetailTextTitle = styled.span`
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 15px;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.03em;

  ${theme.breakpoints.down("sm")} {
    font-size: ${mParagrafSmallFontSIze};
  }
`;

export const DetailTextContent = styled.span`
  font-family: IBM Plex Sans;
  font-weight: bold;
  font-size: 15px;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.03em;
  ${theme.breakpoints.down("sm")} {
    font-size: ${mParagrafSmallFontSIze};
  }
`;

export const RightSideContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  flex-grow: 1;
`;

export const VolunteerListContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

export const SelectFilterContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  display: flex;
  padding: 6px;
  max-width: 200px;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
`;

export const NameFilterContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  display: flex;
  padding: 6px;
  max-width: 200px;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
`;

export const SearchTextField = styled(TextField)`
  & > div > input {
    color: black;
  }
  & > div > div > svg {
    color: black;
  }
  div {
    background-color: white;
  }
`;

export const CenterTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
