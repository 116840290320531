import { ListItem, ListItemIcon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { NavLink } from "../../app/NavBar/NavBarElements";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ReactElement } from "react";
import useAuth from "../../../hooks/useAuth";

const LogOut = (): ReactElement => {
  const { t } = useTranslation();
  const { handleLogout } = useAuth();

  return (
    <NavLink onClick={handleLogout} to="/">
      <ListItem id="logoutElement">
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        {t("logout")}
      </ListItem>
    </NavLink>
  );
};

export default LogOut;
