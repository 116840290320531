import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  HideButtonsContainer,
  RegisteredUserRightNavContainer
} from "../NavBarElements";
import LanguagePicker from "../../LanguagePicker";
import {
  MButton,
  MButtonContrast
} from "../../../shared/buttons/ButtonElements";
import ConditionalHOC from "../../../../hoc/ConditionalHOC";
import MPopper from "../../Popper";
import SmallProfileImage from "../../../shared/BasicProfileInfo/SmallProfileImage";
import ProfileLinkArray from "../../../shared/links/ProfileLinkArray";
import { SeparationLine } from "../../../shared/layout/LayoutElements";
import LogOut from "../../../shared/links/LogOut";
import useAuth from "../../../../hooks/useAuth";
import { NameContainer } from "../../Popper/PopperElements";
import NameRole from "../../../shared/BasicProfileInfo/NameRole";

const BigButtonGroup = (): ReactElement => {
  const { t } = useTranslation();
  const { isLoggedIn, redirectToLogin, redirectToRegister } = useAuth();

  return (
    <ConditionalHOC
      condition={isLoggedIn}
      onTrue={() => (
        <RegisteredUserRightNavContainer>
          <LanguagePicker />
          {/* <NotificationPopper /> */}
          <MPopper buttonElement={<SmallProfileImage />}>
            <NameContainer>
              <NameRole />
            </NameContainer>
            <ProfileLinkArray />
            <SeparationLine />
            <LogOut />
          </MPopper>
        </RegisteredUserRightNavContainer>
      )}
      onFalse={() => (
        <HideButtonsContainer>
          <LanguagePicker />
          <MButtonContrast
            onClick={redirectToRegister}
            variant="contained"
            color="secondary"
          >
            {t("registerAttempt")}
          </MButtonContrast>
          <MButton
            onClick={redirectToLogin}
            variant="contained"
            color="secondary"
          >
            {t("loginAttempt")}
          </MButton>
        </HideButtonsContainer>
      )}
    />
  );
};

export default BigButtonGroup;
