import { mH6, mTitleTextShadow } from "../constants/cssConstants";
import {
  mDarkGreen,
  mError,
  mPrimary,
  mSecondary,
  mTranPurple,
  mWarning,
  white
} from "../constants/colorPallete";
import { createTheme } from "@material-ui/core";
import { MPaper } from "../components/app/Popper/PopperElements";

//ALL COULORS ARE IN constants/colorPallete.

export const theme = createTheme({
  palette: {
    primary: {
      main: `${mPrimary}`
    },

    secondary: {
      main: `${mSecondary}`
    },

    warning: {
      main: `${mWarning}`
    },

    error: {
      main: `${mError}`
    }
  },
  typography: {
    fontFamily: ["IBM Plex Sans"].join(",")
  }
});
theme.props = {
  //Lable stays on top- no animation
  MuiInputLabel: {
    shrink: true
  },
  MuiSelect: {
    MenuProps: {
      disableScrollLock: true
    }
  },
  MuiDialog: {
    PaperComponent: MPaper
  }

  //When full form is merged ill make it dynamic. Only to set size small when in mobile view

  // MuiTextField: {
  //   size: "small"
  // }
};

theme.overrides = {
  //TYPOGRAPHY
  MuiTypography: {
    h1: {
      fontWeight: 700
    },
    h2: {
      fontWeight: 700
    },
    h3: {
      fontWeight: 700
    },
    h4: {
      fontWeight: 600
    },
    h5: {
      fontWeight: 600
    }
  },
  //BUTTON AND CLICKABLES
  MuiBottomNavigationAction: {
    root: {
      "&$selected": {
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        backgroundColor: `${mDarkGreen}`
      }
    }
  },
  MuiButton: {
    root: {
      fontWeight: 600
    }
  },
  MuiIconButton: {
    root: {
      color: "inherit"
    }
  },

  //TEXTFIELD AND INPUT OVERRIDES
  MuiTextField: {
    root: {
      borderTopRightRadius: "8px",
      borderTopLeftRadius: "8px",
      backgroundColor: `${mTranPurple}`,
      color: `${white}`
    }
  },
  MuiOutlinedInput: {
    root: {
      backgroundColor: `${white}`,
      color: `${mSecondary}`
    }
  },
  MuiFormControl: {
    root: {
      borderTopRightRadius: "8px",
      borderTopLeftRadius: "8px",
      backgroundColor: `${mTranPurple}`,
      color: `${white}`
    }
  },
  MuiInputLabel: {
    root: {
      color: `${white}`,
      fontSize: "1.2em",
      "&$focused": {
        color: `${white}`
      }
    },
    outlined: {
      color: `${mSecondary}`
    }
  },
  MuiInputBase: {
    root: {
      color: `${white}`,
      fontWeight: 500
    }
  },
  MuiCheckbox: {
    root: {
      color: `${mSecondary}`,
      "&$checked": {
        color: `${white}`
      }
    }
  },
  MuiRadio: {
    root: {
      color: `${mPrimary}`,
      "&$checked": {
        color: `${mSecondary}`
      }
    },
    checked: {},
    colorPrimary: {
      "&$checked": {
        color: `${mPrimary}`,
        "&:hover": {
          backgroundColor: `${mPrimary}`,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "transparent"
          }
        }
      }
    }
  },
  MuiFormControlLabel: {
    root: {
      color: `${white}`
    }
  },
  //SELECTOR OVERRIDES
  MuiSelect: {
    select: {
      display: "flex",
      alignContent: "center"
    },
    icon: {
      color: `${mSecondary}`
    }
  },
  MuiListItemIcon: {
    root: {
      minWidth: "30px"
    }
  },
  MuiListItem: {},
  MuiList: {
    padding: {
      paddingTop: "0px",
      paddingBottom: "0px"
    }
  },
  MuiPaper: {
    root: {},
    rounded: {
      marginTop: "12px",
      backgroundImage: "none",
      padding: "0px"
    }
  },
  MuiDialogActions: {
    root: {
      padding: "18px 0px 0px 0px"
    }
  },
  MuiDialogTitle: {
    root: {
      color: `${white}`
    }
  },
  MuiDialogContent: {
    dividers: {
      borderTop: "none",
      borderBottom: "none"
    }
  },
  //STEPPER
  MuiStepIcon: {},

  MuiStepLabel: {
    label: {
      color: `${white}`,
      textShadow: `${mTitleTextShadow}`
    }
  },
  //TOOLTIP
  MuiTooltip: {
    tooltip: {
      fontSize: mH6
    }
  },
  //GRID
  MuiGrid: {
    container: {
      justifyContent: "center"
    }
  },
  MuiTableCell: {
    root: {
      [theme.breakpoints.down("xs")]: {
        padding: "0px"
      }
    }
  }
};
