import DateFnsUtils from "@date-io/date-fns";
import { Dialog, DialogTitle, InputAdornment, Paper } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getActions } from "../../../api/actions";
import { getPerks } from "../../../api/getPerks";
import { Perk } from "../../../pages/ActionDetails/models/ActionDetailsDTO";
import ActionDTO from "../../../pages/ActionFeed/models/ActionDTO";
import ActionSignUp from "../../shared/ActionSignUp";
import {
  MButton,
  MButtonContrast,
  MFilterButton
} from "../../shared/buttons/ButtonElements";
import ConfirmDialogContext from "../../shared/ConfirmDialog/ConfirmDialogContext";
import { PageTitle } from "../../shared/text/TextElements";
import ActionCard from "./ActionCard";
import {
  ActionFeedContainer,
  FilterButtonsContainer,
  FilterContainer,
  FilterFlexColumnContainer,
  SearchTextField,
  TextFilterContainer
} from "./ActionFeedElements";
import { containsDate, containsPerk, containsString } from "./filterFunctions";
import PerkFilterComponent from "./PerkFilterComponent";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import i18next from "i18next";
import { enUS, hr } from "date-fns/locale";
import { CROATIAN } from "../../../constants/languageConstants";
import { removeEmptyEnglishActions } from "../../../utils/actionUtils";
import { getCurrentDateWithSetTimeFromTimeOnlyFormat } from "../../../utils/dateTimeUtils";

const ActionFeed = (): ReactElement => {
  const [actions, setActions] = useState<ActionDTO[]>([]);
  const [filteredActions, setFilteredActions] = useState<ActionDTO[]>([]);
  const [selectedPerks, setSelectedPerks] = useState<Perk[]>([]);
  const [perkFilterValues, setPerkFilterValues] = useState<Perk[]>([]);
  const [stringFilterValue, setStringFilterValue] = useState<string>("");
  const [dateFilterValue, setDateFilterValue] = useState<Date | null>(null);
  const { t, i18n } = useTranslation();
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [perks, setPerks] = useState<Perk[]>([]);
  const [selectedActionId, setSelectedActionId] = useState(0);
  const [isUpdatingSignUp, setIsUpdatingSignUp] = useState(false);
  const [needToUpdateActionData, setNeedToUpdateActionData] = useState(true);
  const [userListOfPerks, setUserListOfPerks] = useState<number[]>([]);

  const openSignUpDialog = (
    actionId: number,
    perks: Perk[],
    listOfPerkIdsUserIsOn: number[],
    isUpdating: boolean
  ): void => {
    setSelectedActionId(actionId);
    setPerks(perks);
    setIsUpdatingSignUp(isUpdating);
    setUserListOfPerks(listOfPerkIdsUserIsOn);
    setConfirmDialogOpen(true);
  };
  const [snackbarErrorMessageKey, setSnackbarErrorMessageKey] = useState("");
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  useEffect(() => {
    if (needToUpdateActionData) {
      getActions().then((response) => {
        const data = removeEmptyEnglishActions(response.data, i18n.language);
        setActions(data);
        setFilteredActions(data);
        setNeedToUpdateActionData(false);
      });
      getPerks().then((response) => {
        setPerkFilterValues(response.data);
      });
    }
  }, [needToUpdateActionData]);
  i18n.on("languageChanged", () => {
    getActions().then((response) => {
      setActions(removeEmptyEnglishActions(response.data, i18n.language));
    });
    getPerks().then((response) => {
      setPerkFilterValues(response.data);
      setSelectedPerks([]);
    });
  });

  const filter = () => {
    setFilteredActions(
      actions.filter(
        (action) =>
          containsPerk(action, selectedPerks) &&
          containsString(action, stringFilterValue) &&
          containsDate(action, dateFilterValue)
      )
    );
  };
  useEffect(() => {
    filter();
  }, [selectedPerks, stringFilterValue, dateFilterValue]);

  const handleFliterOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterClear = () => {
    setSelectedPerks([]);
    setDateFilterValue(null);
  };

  return (
    <>
      <PageTitle>{t("actionList")}</PageTitle>
      <FilterContainer>
        <TextFilterContainer>
          <SearchTextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              disableUnderline: true
            }}
            value={stringFilterValue}
            onChange={(event) => setStringFilterValue(event.target.value)}
          />
        </TextFilterContainer>
        <MFilterButton
          onClick={handleFliterOpen}
          variant="contained"
          startIcon={<FilterListIcon />}
        >
          {t("filters")}
        </MFilterButton>
        <Dialog onClose={handleFilterClose} open={openFilterDialog}>
          <FilterFlexColumnContainer>
            <DialogTitle>{t("filters")}</DialogTitle>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={i18next.language == CROATIAN ? hr : enUS}
            >
              <KeyboardDatePicker
                clearable
                clearLabel={t("clear")}
                name="filter-date"
                label={t("date")}
                format="dd.MM.yyyy"
                value={dateFilterValue}
                inputVariant="filled"
                keyboardIcon={<EventRoundedIcon className="whiteIcon" />}
                DialogProps={{ PaperComponent: Paper }}
                onChange={setDateFilterValue}
              />
            </MuiPickersUtilsProvider>
            <PerkFilterComponent
              perkFilterValues={perkFilterValues}
              selectedPerks={selectedPerks}
              setSelectedPerks={setSelectedPerks}
            />
            <FilterButtonsContainer>
              <MButtonContrast
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => handleFilterClear()}
              >
                {t("clear")}
              </MButtonContrast>
              <MButton
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => handleFilterClose()}
              >
                {t("close")}
              </MButton>
            </FilterButtonsContainer>
          </FilterFlexColumnContainer>
        </Dialog>
      </FilterContainer>
      <ConfirmDialogContext.Provider
        value={{ isConfirmDialogOpen, setConfirmDialogOpen }}
      >
        <ActionFeedContainer>
          {filteredActions &&
            filteredActions
              .sort((a, b) => {
                const dateA = new Date(Date.parse(a.startDate));
                const dateB = new Date(Date.parse(b.startDate));
                if (dateA.getTime() != dateB.getTime()) {
                  return dateA.getTime() - dateB.getTime();
                }
                const timeA = getCurrentDateWithSetTimeFromTimeOnlyFormat(
                  a.startTime
                );
                const timeB = getCurrentDateWithSetTimeFromTimeOnlyFormat(
                  b.startTime
                );
                return timeA.getTime() - timeB.getTime();
              })
              .map((action) => (
                <ActionCard
                  action={action}
                  key={action.id}
                  openSignUpDialog={openSignUpDialog}
                />
              ))}
        </ActionFeedContainer>
        {actions.length > 0 && filteredActions.length == 0 && (
          <h1>{t("filteredActionListEmptyMessage")}</h1>
        )}
        {actions.length == 0 && <h1>{t("actionListEmptyMessage")}</h1>}
        {isConfirmDialogOpen && (
          <SnackbarErrorContext.Provider
            value={{
              snackbarErrorMessageKey,
              setSnackbarErrorMessageKey
            }}
          >
            <ActionSignUp
              perks={perks}
              selectedPerks={userListOfPerks}
              actionId={selectedActionId}
              isUpdating={isUpdatingSignUp}
              setNeedToUpdateActionData={setNeedToUpdateActionData}
            />
          </SnackbarErrorContext.Provider>
        )}
      </ConfirmDialogContext.Provider>
    </>
  );
};

export default ActionFeed;
