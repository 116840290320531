import { ADMIN_DOCUMENT_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import DocumentApprovalDTO from "./models/DocumentApprovalDTO";

export const patchDocumentStatusParams = (
  documentApprovalDTO: DocumentApprovalDTO
): AxiosRequestConfig =>
  ({
    url: ADMIN_DOCUMENT_URL,
    method: "PATCH",
    data: documentApprovalDTO
  } as AxiosRequestConfig);

export const patchDocumentStatus = async (
  userId: string,
  documentTitle: string,
  isApproved: boolean
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(
    patchDocumentStatusParams({
      userId,
      documentTitle,
      isApproved
    } as DocumentApprovalDTO)
  );
};
