import {
  StepLabel,
  TableCell,
  TablePagination,
  TableRow
} from "@material-ui/core";
import { motion } from "framer-motion";
import styled from "styled-components";
import background from "../../../assets/svg/AbstractGradient.svg";
import { mTranPurple, white } from "../../../constants/colorPallete";
import { mTitleTextShadow } from "../../../constants/cssConstants";
import { theme } from "../../../styles/style";

export const ActionFormStepOneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 18px;
  width: 90%;
  gap: 10px;
  margin: auto auto;
`;
export const ActionFormStepThreeRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;
export const ActionFormStepThreeRowColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
  width: 100%;
`;
export const ActionFormOuterContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 24px;
  box-shadow: ${mTitleTextShadow};
  background-image: url(${background});
  background-size: cover;
  background-position: top;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 1030px;
  ${theme.breakpoints.down("md")} {
    width: 790px;
    padding: 12px 30px;
    gap: 16px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 480px;
    padding: 12px 24px;
  }
  ${theme.breakpoints.down("xs")} {
    width: 360px;
    padding: 12px 12px;
  }
  @media (max-width: 400px) {
    width: 300px;
  }
`;
export const ActionFormStepThreeColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 18px;
  width: 90%;
  gap: 16px;
  justify-content: center;
  margin: auto auto;
`;

export const CreateActionTitle = styled.h1`
  text-align: center;
  color: ${white};
  text-shadow: ${mTitleTextShadow};
`;
export const ActionFormStepFourContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  min-height: 40%;
  padding: 18px;
`;
export const NewPerkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
`;
export const PerkListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 6px;
  background-color: ${mTranPurple};
  border-radius: 8px;
  min-height: 100px;
  width: 100%;
  margin-bottom: 16px;
  gap: 12px;
`;
export const Category = styled.p`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 24px;
  font-size: 14px;
  padding: 6px 0px 6px 12px;
  margin: 6px;
  font-weight: bold;
  min-width: 150px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  gap: 30px;
`;
export const StyledStepLabel = styled(StepLabel)`
  @media (max-width: 480px) {
    display: none;
  }
`;

export const AutocompleteContainer = styled.div`
  min-width: 200px;
`;
export const QuantityContainer = styled.div`
  max-width: 100px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  padding: 18px;
`;

export const RepetitiveFirstColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const RepetitiveSecondColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const RepetitiveOtherInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const RoundedContainer = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const EntireTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  background-color: ${mTranPurple};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const ColorOverlay = styled.div`
  background-color: rgb(0, 0, 0, 0.09);
  width: 100%;
`;

export const StyledTableCell = styled(TableCell)`
  color: white;
`;

export const StyledTableRow = styled(TableRow)`
  height: 50px;
`;

export const StyledTablePagination = styled(TablePagination)`
  color: white;
  width: 100%;
`;

export const ButtonGroupRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
