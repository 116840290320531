import { ReactElement, useContext } from "react";
import PerkContext from "./PerkContext";
import StepThreeRepetitive from "./StepThreeRepetitive";
import StepThreeNormal from "./StepThreeNormal";
import { ButtonGroup } from "@material-ui/core";
import { MButton } from "../../shared/buttons/ButtonElements";
import { useTranslation } from "react-i18next";
import { ButtonGroupRow } from "./ActionFormElements";

const StepThree = (): ReactElement => {
  const { t } = useTranslation();
  const { isRepetitive, setIsRepetitive } = useContext(PerkContext);

  return (
    <>
      <ButtonGroupRow>
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          <MButton
            onClick={() => setIsRepetitive(false)}
            color={!isRepetitive ? "primary" : "secondary"}
          >
            {t("normal")}
          </MButton>
          <MButton
            onClick={() => setIsRepetitive(true)}
            color={isRepetitive ? "primary" : "secondary"}
          >
            {t("repetitive")}
          </MButton>
        </ButtonGroup>
      </ButtonGroupRow>
      {isRepetitive ? <StepThreeRepetitive /> : <StepThreeNormal />}
    </>
  );
};

export default StepThree;
