import { LOGIN_URL } from "./endpoints";

import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosInstance from "./axiosInstance";
import LoginDTO from "../pages/Login/models/LoginDTO";

export const loginParams = (body: LoginDTO): AxiosRequestConfig =>
  ({
    url: LOGIN_URL,
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const postLogin = async (
  body: LoginDTO
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(loginParams(body));
};
