import { ReactElement, useContext, useState } from "react";
import ConfirmDialogContext from "../../shared/ConfirmDialog/ConfirmDialogContext";
import ErrorInfo from "../../../constants/ErrorInfo";
import ActionSignUpDialog from "../../shared/ActionSignUpDialog";
import { patchActionUser } from "../../../api/patchActionUser";
import { useHistory } from "react-router-dom";
import ConfrimUserProps from "./models/ConfirmUserProps";

const ConfirmUser = ({
  user,
  actionId,
  setUsers
}: ConfrimUserProps): ReactElement => {
  const { isConfirmDialogOpen, setConfirmDialogOpen } =
    useContext(ConfirmDialogContext);
  const [selectedPerkIds, setSelectedPerkIds] = useState<number[]>([]);
  const history = useHistory();

  const handleClose = () => {
    setConfirmDialogOpen(false);
    setSelectedPerkIds([]);
  };

  const handleSubmit = (perkIds: number[]) => {
    const confirm = true;
    patchActionUser({ confirm, perkIds, userId: user?.id as string }, actionId)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((err) => {
        history.push("/greska", {
          status: err?.response?.data?.status,
          message: err?.response?.data?.message,
          description: err?.response?.data?.description
        } as ErrorInfo);
      });
    handleClose();
  };

  return (
    <>
      {user && (
        <ActionSignUpDialog
          perks={user.perks}
          alreadySelectedPerks={[]}
          id="ringtone-menu"
          keepMounted
          open={isConfirmDialogOpen}
          onSubmit={handleSubmit}
          onClose={handleClose}
          value={selectedPerkIds}
          confirmButtonTitle="accept"
          title="acceptActionSignup"
        />
      )}
    </>
  );
};

export default ConfirmUser;
