import { FormControlLabel } from "@material-ui/core";
import styled from "styled-components";
import { mSecondary, white } from "../../../constants/colorPallete";
import {
  mH2,
  mH5,
  mH6,
  mParagrafFontSize
} from "../../../constants/cssConstants";
import { theme } from "../../../styles/style";

export const MLabel = styled.p`
  margin: 0px;
  font-size: ${mH6};
  font-weight: 600;
  color: ${mSecondary};
  ${theme.breakpoints.down("sm")} {
    font-size: ${mParagrafFontSize};
  }
`;
export const MInfoText = styled.p`
  margin: 0px;
  font-size: ${mH6};
  font-weight: 700;
  ${theme.breakpoints.down("sm")} {
    font-size: ${mParagrafFontSize};
  }
`;

export const PageTitle = styled.h2`
  color: ${white};
  padding: 0px;
  font-size: ${mH2};
  ${theme.breakpoints.down("md")} {
    font-size: ${mH5};
  }
`;
export const CardTitle = styled.h2`
  align-self: center;
`;

export const WizardTitle = styled.h4`
  text-align: center;
  font-size: ${mH6};
  font-weight: 500;
  color: ${white};
  ${theme.breakpoints.down("sm")} {
    font-size: ${mParagrafFontSize};
  }
`;

export const MFormControlLabel = styled(FormControlLabel)`
  color: black;
`;
