import {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState
} from "react";
import RoleSelectorProps from "./models/RoleSelectorProps";
import {
  RoleSelectorContainer,
  StyledRadioGroup
} from "./RoleSelectorElements";
import { Radio } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  MainContentContainer,
  MainTitle
} from "../../shared/layout/LayoutElements";
import { getUserDetailsById } from "../../../api/getUserDetailsById";
import { patchRoles } from "../../../api/patchRoles";
import ConfirmDialog from "../../shared/ConfirmDialog";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import Role from "../../../constants/roles";
import UserDetailsDTO from "../../../api/models/UserDetailsDTO";
import { getMostSignificantRole } from "../../../utils/roleUtil";
import { MFormControlLabel } from "../../shared/text/TextElements";

const RoleSelector = ({ id }: RoleSelectorProps): ReactElement => {
  const [roleIdentifier, setRoleIdentifier] = useState(0);
  const [wantedRoleIdentifier, setWantedRoleIdentifier] = useState(0);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  const { t } = useTranslation();

  useEffect(() => {
    getUserDetailsById(id)
      .then((res) => {
        setRoleIdentifier(determineRoleIdentifier(res.data));
      })
      .catch(() => {
        setSnackbarErrorMessageKey(t("couldNotFetchUserPrivileges"));
      });
  }, []);

  const determineRoleIdentifier = (userDetails: UserDetailsDTO): number => {
    let mostSignificantRole = getMostSignificantRole(userDetails.roles);
    if (mostSignificantRole === Role.VOLUNTEER && !userDetails.isApproved) {
      return 1;
    } else if (mostSignificantRole === Role.VOLUNTEER) {
      return 2;
    } else if (mostSignificantRole === Role.COORDINATOR) {
      return 3;
    } else if (mostSignificantRole === Role.ADMINISTRATOR) {
      return 4;
    } else {
      setSnackbarErrorMessageKey("unknownUserPrivilege");
      return 0; // nothing is selected
    }
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    let numberValue = parseInt(value, 10);
    if (numberValue !== roleIdentifier) {
      setIsConfirmDialogOpen(true);
      setWantedRoleIdentifier(numberValue);
    }
  };

  useEffect(() => {
    if (!isConfirmDialogOpen) {
      setWantedRoleIdentifier(roleIdentifier);
    }
  }, [isConfirmDialogOpen]);

  const onDialogConfirm = (): void => {
    let rolesToGiveUser: string[] = [Role.VOLUNTEER];
    let isApproved = false;
    if (wantedRoleIdentifier >= 2) {
      isApproved = true;
    }
    if (wantedRoleIdentifier >= 3) {
      rolesToGiveUser.push(Role.COORDINATOR);
    }
    if (wantedRoleIdentifier === 4) {
      rolesToGiveUser.push(Role.ADMINISTRATOR);
    }
    patchRoles(id, rolesToGiveUser, isApproved)
      .then((res) => {
        if (rolesToGiveUser.includes(Role.ADMINISTRATOR)) {
          location.reload();
        } else {
          setRoleIdentifier(determineRoleIdentifier(res.data));
          setWantedRoleIdentifier(determineRoleIdentifier(res.data));
        }
      })
      .catch(() => setSnackbarErrorMessageKey(t("couldNotUpdatePrivileges")));
  };

  const renderRoleSelectorComponent = (): ReactElement => {
    return (
      <MainContentContainer>
        <ConfirmDialog
          title={t("confirmPrivilegeChange")}
          open={isConfirmDialogOpen}
          setOpen={setIsConfirmDialogOpen}
          onConfirm={() => onDialogConfirm()}
        />
        <RoleSelectorContainer>
          <MainTitle>{t("privileges")}</MainTitle>
          <StyledRadioGroup
            row
            aria-label="gender"
            name="gender1"
            value={roleIdentifier}
            onChange={handleChange}
          >
            <MFormControlLabel
              value={4}
              control={<Radio />}
              label={t("administrator")}
            />
            <MFormControlLabel
              value={3}
              control={<Radio />}
              label={t("coordinator")}
            />
            <MFormControlLabel
              value={2}
              control={<Radio />}
              label={t("approvedVolunteer")}
            />
            <MFormControlLabel
              value={1}
              control={<Radio />}
              label={t("volunteer")}
            />
          </StyledRadioGroup>
        </RoleSelectorContainer>
      </MainContentContainer>
    );
  };

  return (
    <>
      {roleIdentifier > 0 && roleIdentifier !== 4 ? (
        renderRoleSelectorComponent()
      ) : (
        <></>
      )}
    </>
  );
};

export default RoleSelector;
