import { GET_IMAGE_BY_ID_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export const getImageByIdParams = (id: string): AxiosRequestConfig =>
  ({
    url: GET_IMAGE_BY_ID_URL(id),
    method: "GET",
    responseType: "blob"
  } as AxiosRequestConfig);

export const getImageById = async (
  id: string
): Promise<AxiosResponse<Blob>> => {
  return await axiosInstance.request(getImageByIdParams(id));
};
