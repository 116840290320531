import { Paper } from "@material-ui/core";
import styled from "styled-components";
import background from "../../../assets/svg/AbstractGradient.svg";

export const MPaper = styled(Paper)`
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  padding: 18px;
  border-radius: 18px;
`;
export const NameContainer = styled.div`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: 6px;
  background-image: url(${background});
  background-size: cover;
`;
