import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef
} from "react";
import { Client, IMessage, StompSubscription } from "@stomp/stompjs";
import { useDispatch } from "react-redux";
import { getJwtFromCookies } from "../../../utils/jwt";
import { NotificationDTO } from "../../../api/models/NotificationDTO";
import { createNotification } from "../../../store/actions/notificationActionFactories";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import { getWebsocketUrl } from "../../../utils/notificationUtils";
import { NotificationConnectionHelpers } from "./models/NotificationConnectionHelpers";
import { NotificationConnectionProviderProps } from "./models/NotificationConnectionProviderProps";
import {
  SUBSCRIPTION_ENDPOINT,
  SUBSCRIPTION_START,
  SUBSCRIPTION_STOP
} from "../../../constants/notificationConstants";

/**
 * Context which provides consumers with helper methods.
 */
const NotificationConnectionContext = createContext<
  NotificationConnectionHelpers | undefined
>(undefined);

/**
 * Wrapper around NotificationContextContext.Provider, should be used in-place.
 */
const NotificationConnectionProvider = ({
  children
}: NotificationConnectionProviderProps): ReactElement => {
  const client = useRef({} as Client);
  const subscription = useRef({} as StompSubscription);
  const connected = useRef(false);

  const dispatch = useDispatch();

  const { logged } = useAppSelector((user) => user.UserState);

  const handleMessageReceive = useCallback((message: IMessage) => {
    const notification: NotificationDTO = JSON.parse(
      message.body
    ) as NotificationDTO;
    dispatch(createNotification(notification));
  }, []);

  const connect = useCallback(() => {
    if (connected.current) {
      return;
    }

    const stompClient = new Client();

    stompClient.brokerURL = getWebsocketUrl();
    stompClient.onConnect = () => {
      subscription.current = stompClient.subscribe(
        SUBSCRIPTION_ENDPOINT,
        handleMessageReceive
      );
      connected.current = true;

      stompClient.publish({
        destination: SUBSCRIPTION_START,
        headers: {},
        body: getJwtFromCookies()
      });
    };

    stompClient.activate();
    client.current = stompClient;
  }, []);

  const disconnect = useCallback(() => {
    if (
      connected.current &&
      client.current !== ({} as Client) &&
      client.current !== null
    ) {
      try {
        client.current.publish({
          destination: SUBSCRIPTION_STOP,
          headers: {},
          body: getJwtFromCookies()
        });
        subscription.current.unsubscribe();
        client.current
          .deactivate()
          .then(() => {
            connected.current = false;
          })
          .catch((err) => {
            console.error("Error in disconnecting: ", err);
          });
      } catch (error) {
        // prevent TypeError from refs being uninitialized before mount
      }
    }
  }, []);

  useEffect(() => {
    /*
    if (!connected.current && logged) {
      connect(); // connect on mount if already logged in
    }
    WHEN HTTPS IS ENABLED, UNCOMMENT THIS CODE
     */
  }, [logged]);

  return (
    <NotificationConnectionContext.Provider
      value={{
        connect,
        disconnect
      }}
    >
      {children}
    </NotificationConnectionContext.Provider>
  );
};

/**
 * Safe wrapper around useContext(NotificationConnectionContext)
 */
const useNotificationConnection = (): NotificationConnectionHelpers => {
  const context = useContext(NotificationConnectionContext);
  if (context === undefined) {
    throw new Error(
      "useNotificationConnection must be used within a NotificationConnectionProvider"
    );
  }
  return context;
};

export { NotificationConnectionProvider, useNotificationConnection };
