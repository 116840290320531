import { ReactElement } from "react";
import ErrorPage from "../../../pages/ErrorPage";
import ProfileWizardStepProps from "./models/ProfileWizardStepProps";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { FadeAnimation } from "../../shared/AnimatedComponents/AnimatedElements";

const ProfileWizardStep = ({
  step,
  afterSubmit,
  cancel,
  close
}: ProfileWizardStepProps): ReactElement => {
  const steps = [StepOne, StepTwo, StepThree];

  if (step != undefined && step < steps.length) {
    const CurrentStep = steps[step];
    return (
      <FadeAnimation>
        <CurrentStep afterSubmit={afterSubmit} cancel={cancel} close={close} />
      </FadeAnimation>
    );
  } else {
    return <ErrorPage />;
  }
};

export default ProfileWizardStep;
