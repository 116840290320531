import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import {
  ActionDate,
  ActionDateContainer,
  ActionDatesContainer,
  ItalicTypography
} from "./ActionSharedElements";
import { ReactElement } from "react";
import { getIntlDateString } from "../../../utils/dateTimeUtils";

interface dateProps {
  startDate: string;
  endDate: string;
}

const DatesComponent = ({ startDate, endDate }: dateProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <ActionDatesContainer>
      <ActionDateContainer>
        <ItalicTypography color={"secondary"}>
          {t("startDate")}
        </ItalicTypography>
        <ActionDate>
          <DateRange />
          <Typography>{getIntlDateString(startDate)}</Typography>
        </ActionDate>
      </ActionDateContainer>
      <ActionDateContainer>
        <ItalicTypography color={"secondary"}>{t("endDate")}</ItalicTypography>
        <ActionDate>
          <DateRange />
          <Typography>{getIntlDateString(endDate)}</Typography>
        </ActionDate>
      </ActionDateContainer>
    </ActionDatesContainer>
  );
};

export default DatesComponent;
