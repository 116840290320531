import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import { adminPostSkills } from "../../../api/adminPostSkills";
import { getPerks } from "../../../api/getPerks";
import { postSkills } from "../../../api/postSkills";
import ErrorInfo from "../../../constants/ErrorInfo";
import { Perk } from "../../../pages/ActionFeed/models/ActionDTO";
import { saveUserDetails } from "../../../store/actions/userDetailsActions";
import { MButton, MButtonContrast } from "../../shared/buttons/ButtonElements";
import InputField from "../../shared/formFields/InputField";
import { FlexSBContainer, MForm } from "../../shared/layout/LayoutElements";
import { WizardTitle } from "../../shared/text/TextElements";
import { ButtonContainer } from "../ActionForm/ActionFormElements";
import {
  EmploymentStatusArray,
  EMPLOYMENT_STATUS,
  ProfileWizardStep2Data
} from "./models/ProfileWizardData";
import ProfileWizardStepProps from "./models/ProfileWizardStepProps";
import {
  FlexEndContainer,
  StepColumnContainer,
  TitleContainer
} from "./ProfileWizardElements";
import UserContext from "./UserContext";
import CloseDialogButton from "../../shared/buttons/CloseDialogButton";

const StepTwo = ({
  afterSubmit,
  cancel,
  close
}: ProfileWizardStepProps): ReactElement => {
  const [perks, setPerks] = useState<Perk[]>([]);
  const { user, setUser, isAdmin } = useContext(UserContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const INITIAL_VALUES = {
    employmentStatusName: user?.employmentStatus
      ? user.employmentStatus
      : EMPLOYMENT_STATUS.UNEMPLOYED,
    profession: user ? user.profession : null,
    perkIds: user ? user.perkIds : null
  } as ProfileWizardStep2Data;

  useEffect(() => {
    getPerks().then((response) => {
      setPerks(response.data);
    });
  }, []);

  const validationSchema = yup.object().shape({
    employmentStatusName: yup.string().oneOf(EmploymentStatusArray).required(),
    profession: yup
      .string()
      .max(64, t("maxLength", { count: 64 }))
      .nullable()
  });

  const handleSubmit = (
    values: ProfileWizardStep2Data,
    { setSubmitting }: FormikHelpers<ProfileWizardStep2Data>
  ) => {
    if (isAdmin && user) {
      adminPostSkills(values as ProfileWizardStep2Data, user.id)
        .then((res) => {
          setUser(res.data);
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        });
    } else {
      postSkills(values as ProfileWizardStep2Data)
        .then((res) => {
          setUser(res.data);
          dispatch(saveUserDetails(res.data));
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        });
    }
    setSubmitting(false);
    afterSubmit();
  };
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        submitForm
      }: FormikProps<ProfileWizardStep2Data>) => (
        <MForm onSubmit={handleSubmit} onChange={handleChange}>
          <StepColumnContainer>
            <TitleContainer>
              <WizardTitle>{t("wizardStepTwoTitle")}</WizardTitle>
              <CloseDialogButton close={close} />
            </TitleContainer>
            <FlexSBContainer style={{ maxWidth: "500px", flexWrap: "wrap" }}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>{t("employmentStatus")}</InputLabel>
                <Select
                  name="employmentStatusName"
                  value={values.employmentStatusName}
                  onChange={handleChange}
                  variant="filled"
                >
                  <MenuItem value={EMPLOYMENT_STATUS.STUDENT}>
                    {t("student")}
                  </MenuItem>
                  <MenuItem value={EMPLOYMENT_STATUS.EMPLOYED}>
                    {t("employed")}
                  </MenuItem>
                  <MenuItem value={EMPLOYMENT_STATUS.UNEMPLOYED}>
                    {t("unemployed")}
                  </MenuItem>
                  <MenuItem value={EMPLOYMENT_STATUS.RETIRED}>
                    {t("retired")}
                  </MenuItem>
                  <MenuItem value={EMPLOYMENT_STATUS.DISCIPLE}>
                    {t("disciple")}
                  </MenuItem>
                </Select>
              </FormControl>
              <InputField
                name="profession"
                label={t("profession")}
                variant="filled"
                fullWidth
              />
            </FlexSBContainer>
            <Autocomplete
              value={perks.filter((perk) =>
                values.perkIds?.some((perkId) => perkId == perk.id)
              )}
              multiple
              onChange={(event, value) =>
                setFieldValue(
                  "perkIds",
                  value.map((perk) => perk.id)
                )
              }
              limitTags={6}
              options={perks}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={
                      selected ||
                      values.perkIds?.some((perkId) => option.id == perkId)
                    }
                    key={uuid()}
                  />
                  {`${option.type} - ${option.name}`}
                </>
              )}
              fullWidth
              style={{ maxWidth: "500px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  placeholder={t("categories")}
                  // multiline={true}
                  // minRows={5}
                  onChange={handleChange}
                />
              )}
            />
          </StepColumnContainer>
          <ButtonContainer>
            <MButton
              color="secondary"
              variant="contained"
              onClick={cancel}
              startIcon={<NavigateBefore />}
            >
              {t("createActionFormPreviousStep")}
            </MButton>
            <MButtonContrast
              type="submit"
              name="submit"
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
              endIcon={<NavigateNext />}
            >
              {t("createActionFormNextStep")}
            </MButtonContrast>
          </ButtonContainer>
          <FlexEndContainer>
            <MButton
              autoFocus
              onClick={() => {
                submitForm().then(close);
              }}
              variant="contained"
              color="primary"
            >
              {t("saveAndExit")}
            </MButton>
          </FlexEndContainer>
        </MForm>
      )}
    </Formik>
  );
};

export default StepTwo;
