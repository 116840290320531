import {
  LOGOUT,
  SAVE_PICTURE,
  SAVE_USER_DETAILS,
  userDetailsActionTypes
} from "./userDetailsActionTypes";
import UserDetailsDTO from "../../api/models/UserDetailsDTO";

export const saveUserDetails = (
  user: UserDetailsDTO
): userDetailsActionTypes => {
  return {
    type: SAVE_USER_DETAILS,
    payload: user
  };
};

export const saveProfilePicture = (
  pictureInBase64: string
): userDetailsActionTypes => ({
  type: SAVE_PICTURE,
  payload: pictureInBase64
});

export const logout = (): userDetailsActionTypes => {
  return {
    type: LOGOUT,
    payload: undefined
  };
};
