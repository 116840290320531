import { AxiosRequestConfig, AxiosResponse } from "axios";
import { DELETE_REVIEW_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";

export const deleteReviewParams = (reviewId: number): AxiosRequestConfig =>
  ({
    url: DELETE_REVIEW_URL(reviewId),
    method: "DELETE"
  } as AxiosRequestConfig);

export const deleteReview = async (
  reviewId: number
): Promise<AxiosResponse<void>> =>
  await axiosInstance.request(deleteReviewParams(reviewId));
