import { ReactElement, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import FeedbackMessagePageProps from "pages/FeedbackMessagePage/models/FeedbackMessagePageProps";
import ErrorInfo from "constants/ErrorInfo";
import { confirmEmail } from "api/confirmEmail";
import DragonSpinner from "components/shared/LoadingAnimation";
export default interface ConfirmEmailParams {
  token?: string;
}

export const ConfirmEmailPage = (): ReactElement => {
  const { token } = useParams<ConfirmEmailParams>();

  const history = useHistory();

  useEffect(() => {
    confirmEmail({ token: token })
      .then(() => {
        // render success page
        history.push("/informacija", {
          feedbackMessageKey: "mailConfirmationSuccess"
        } as FeedbackMessagePageProps);
      })
      .catch((error) => {
        history.push("/greska", {
          status: error?.response?.data?.status,
          message: error?.response?.data?.message,
          description: error?.response?.data?.description
        } as ErrorInfo);
      });
  }, []);

  return (
    <PageContainer>
      <DragonSpinner />
    </PageContainer>
  );
};
