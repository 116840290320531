import { ReactElement } from "react";
import SVGIcon from "../../shared/SVGIcon";
import { SVGIconContainer } from "../../shared/SVGIcon/SVGIconElements";
import LanguageOptionProps from "./models/LanguageOptionProps";

/**
 * A single language option that can be picked within the language picker.
 *
 * @param languageCode language code to which the component instance corresponds to. {@see LanguageCode}
 */
const LanguageOption = ({
  languageCode
}: LanguageOptionProps): ReactElement => {
  return (
    <SVGIconContainer>
      <SVGIcon name={languageCode} />
    </SVGIconContainer>
  );
};

export default LanguageOption;
