// TODO - add VISUAL component for 1st part of form

import { ReactElement } from "react";
import { ActionFormStepOneContainer } from "./ActionFormElements";
import InputField from "../../shared/formFields/InputField";
import { useTranslation } from "react-i18next";

const StepOne = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <ActionFormStepOneContainer>
      <InputField
        variant="filled"
        label={t("createActionStep1Title")}
        fullWidth
        name="title"
      />
      <InputField
        variant="filled"
        label={t("createActionStep1ShortDescription")}
        fullWidth
        name="shortDescription"
        minRows={3}
        multiline={true}
      />
      <InputField
        variant="filled"
        label={t("createActionStep1Description")}
        fullWidth
        name="description"
        minRows={9}
        multiline={true}
      />
    </ActionFormStepOneContainer>
  );
};

export default StepOne;
