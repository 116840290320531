import {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState
} from "react";
import VolunteerMinimumDetailsDTO from "./models/VolunteerMinimumDetailsDTO";
import { getVolunteers } from "../../../api/getVolunteers";
import VolunteerTile from "./VolunteerTile";
import {
  CenterTextContainer,
  NameFilterContainer,
  SearchTextField,
  SelectFilterContainer,
  VolunteerListContainer
} from "./VolunteerListElements";
import { useTranslation } from "react-i18next";
import FilterPopup from "./FilterPopup";
import FilterEntry from "./models/FilterEntry";
import {
  Checkbox,
  Dialog,
  DialogTitle,
  InputAdornment,
  TextField
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { getPerks } from "../../../api/getPerks";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import { MainTitle } from "../../shared/layout/LayoutElements";
import {
  MButton,
  MButtonContrast,
  MFilterButton
} from "../../shared/buttons/ButtonElements";
import {
  FilterButtonsContainer,
  FilterContainer,
  FilterFlexColumnContainer
} from "../ActionFeed/ActionFeedElements";

const VolunteersList = (): ReactElement => {
  const [volunteers, setVolunteers] = useState<VolunteerMinimumDetailsDTO[]>(
    []
  );
  const [filteredVolunteerList, setFilteredVolunteerList] = useState<
    VolunteerMinimumDetailsDTO[]
  >([]);
  const [nameFilterValue, setNameFilterValue] = useState<string>("");
  const [roleFilterValue, setRoleFilterValue] = useState<number>(0);
  const [selectedPerks, setSelectedPerks] = useState<number[]>([]);
  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  const [perkFilterValues, setPerkFilterValues] = useState<FilterEntry[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getVolunteers()
      .then((res) => {
        setVolunteers(res.data.volunteerList);
        setFilteredVolunteerList(res.data.volunteerList);
      })
      .catch(() => setSnackbarErrorMessageKey(t("downloadFailure")));
  }, []);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  useEffect(() => {
    getPerks().then((res) => {
      setPerkFilterValues(
        res.data.map((perk) => {
          return {
            displayValue: `${perk.type} - ${perk.name}`,
            value: perk.id
          };
        })
      );
    });
  }, [t]);

  const roleFilterValues: FilterEntry[] = [
    { displayValue: t("any"), value: 0 },
    { displayValue: t("volunteer"), value: 1 },
    { displayValue: t("coordinator"), value: 2 },
    { displayValue: t("administrator"), value: 3 }
  ];

  const isRole = (volunteer: VolunteerMinimumDetailsDTO) => {
    return roleFilterValue == 0 || volunteer.roles.length == roleFilterValue;
  };

  const isName = (volunteer: VolunteerMinimumDetailsDTO) => {
    if (nameFilterValue.trim().length == 0) {
      return true;
    } else {
      let firstAndLastName = `${volunteer.firstName
        .trim()
        .toLowerCase()} ${volunteer.lastName.trim().toLowerCase()}`;
      return firstAndLastName.includes(nameFilterValue.trim().toLowerCase());
    }
  };

  const isPerk = (volunteer: VolunteerMinimumDetailsDTO) => {
    return (
      selectedPerks.length == 0 ||
      selectedPerks.some((perk) => volunteer.perks.includes(perk))
    );
  };

  useEffect(() => {
    filter();
  }, [roleFilterValue, nameFilterValue, selectedPerks]);

  const filter = () => {
    setFilteredVolunteerList(
      volunteers.filter((v) => isRole(v) && isName(v) && isPerk(v))
    );
  };

  const handleNameFilterValue = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setNameFilterValue(e.target.value);
  };

  const handleFliterOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterClear = () => {
    setSelectedPerks([]);
    setRoleFilterValue(0);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <VolunteerListContainer>
      <FilterContainer>
        <NameFilterContainer>
          <SearchTextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              disableUnderline: true
            }}
            value={nameFilterValue}
            onChange={handleNameFilterValue}
          />
        </NameFilterContainer>
        <MFilterButton
          onClick={handleFliterOpen}
          variant="contained"
          startIcon={<FilterListIcon />}
        >
          {t("filters")}
        </MFilterButton>
        <Dialog onClose={handleFilterClose} open={openFilterDialog}>
          <FilterFlexColumnContainer>
            <DialogTitle>{t("filters")}</DialogTitle>
            <SelectFilterContainer>
              <FilterListIcon />
              <FilterPopup
                filterEntries={roleFilterValues}
                filterValue={roleFilterValue}
                setFilterValue={setRoleFilterValue}
              />
            </SelectFilterContainer>
            <Autocomplete
              multiple
              value={perkFilterValues.filter((fv) =>
                selectedPerks.includes(fv.value)
              )}
              onChange={(event, value) =>
                setSelectedPerks(value.map((v) => v.value))
              }
              limitTags={2}
              id="checkboxes-tags-demo"
              options={perkFilterValues}
              disableCloseOnSelect
              getOptionLabel={(option) => option.displayValue}
              noOptionsText={t("noCategory")}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.displayValue}
                </>
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t("categories")}
                />
              )}
            />{" "}
            <FilterButtonsContainer>
              <MButtonContrast
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => handleFilterClear()}
              >
                {t("clear")}
              </MButtonContrast>
              <MButton
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => handleFilterClose()}
              >
                {t("close")}
              </MButton>
            </FilterButtonsContainer>
          </FilterFlexColumnContainer>
        </Dialog>
      </FilterContainer>
      {filteredVolunteerList
        .sort((first, second) =>
          (first.firstName + first.lastName).localeCompare(
            second.firstName + second.lastName
          )
        )
        .map((volunteer) => (
          <VolunteerTile {...volunteer} key={volunteer.id} />
        ))}
      {filteredVolunteerList.length == 0 && (
        <CenterTextContainer>
          <MainTitle>{t("noUsersFound")}</MainTitle>
        </CenterTextContainer>
      )}
    </VolunteerListContainer>
  );
};

export default VolunteersList;
