import { USER_DETAILS_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import UserDetailsProfileDTO from "./models/UserDetailsDTO";

export const getUserDetailsParams = (): AxiosRequestConfig =>
  ({
    url: USER_DETAILS_URL,
    method: "GET"
  } as AxiosRequestConfig);

export const getUserDetails = async (): Promise<
  AxiosResponse<UserDetailsProfileDTO>
> => {
  return await axiosInstance.request(getUserDetailsParams());
};
