import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import { Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { MButtonContrast } from "../../components/shared/buttons/ButtonElements";
import FeedbackMessagePageProps from "./models/FeedbackMessagePageProps";

// could also be a component instead of a page
const FeedbackMessagePage = (): ReactElement => {
  const { t } = useTranslation();
  const {
    state: { feedbackMessageKey }
  } = useLocation<FeedbackMessagePageProps>();

  // TODO STYLE
  return (
    <>
      <PageContainer>
        <Typography variant="h3">{t(feedbackMessageKey)}</Typography>
        <Link to="/">
          <MButtonContrast variant="contained" color="secondary">
            {t("returnHome")}
          </MButtonContrast>
        </Link>
      </PageContainer>
    </>
  );
};

export default FeedbackMessagePage;
