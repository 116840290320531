import LanguageCode from "../../locale/types/LanguageCode";
import { useState } from "react";
import SUPPORTED_LANGUAGES from "../../constants/supportedLanguages";
import Language from "../../locale/types/Language";
import { useTranslation } from "react-i18next";

/**
 * Hook that provides the functionality of a language picker.
 *
 * Exposes the following objects:
 *  - currently selected language {@see LanguageCode}
 *  - function to set the language
 *  - record of all supported languages {@see SUPPORTED_LANGUAGES}
 */
const useLanguagePicker = (): readonly [
  LanguageCode,
  (lc: LanguageCode) => void,
  Record<LanguageCode, Language>
] => {
  const { i18n } = useTranslation();
  const [language, pickLanguage] = useState<LanguageCode>(
    i18n.language as LanguageCode
  ); // currently selected language

  /**
   * Function that sets the application language to the provided value.
   *
   * @param language new language to be set
   */
  const setLanguage = (language: LanguageCode): void => {
    i18n.changeLanguage(language, () => {
      pickLanguage(i18n.language as LanguageCode);
    });
  };

  return [language, setLanguage, SUPPORTED_LANGUAGES] as const;
};

export default useLanguagePicker;
