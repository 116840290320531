import { AxiosRequestConfig, AxiosResponse } from "axios";
import { SPECIFIC_DAILY_WORK_RECORD } from "./endpoints";
import axiosInstance from "./axiosInstance";
import ActionUserDTO from "./models/ActionUserDTO";
import UpdateDailyWorkRecordDTO from "./models/UpdateDailyWorkRecordDTO ";

export const patchDailyWorkRecordParams = (
  data: UpdateDailyWorkRecordDTO,
  dailyWorkRecordId: number
): AxiosRequestConfig =>
  ({
    url: SPECIFIC_DAILY_WORK_RECORD(dailyWorkRecordId),
    method: "PATCH",
    data: data
  } as AxiosRequestConfig);

export const patchDailyWorkRecord = async (
  dailyWorkRecordId: number,
  isApproved: boolean,
  workingHours: number
): Promise<AxiosResponse<ActionUserDTO[]>> => {
  return await axiosInstance.request(
    patchDailyWorkRecordParams(
      { isApproved, workingHours } as UpdateDailyWorkRecordDTO,
      dailyWorkRecordId
    )
  );
};
