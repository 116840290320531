import { Typography } from "@material-ui/core";
import MailRounded from "@material-ui/icons/MailRounded";
import { MButtonMaxWidth } from "../../shared/buttons/ButtonElements";
import {
  FlexColumnGapContainer,
  FlexSBContainer,
  MForm,
  SignWrapper
} from "../../shared/layout/LayoutElements";
import {
  AnchorLink,
  IconContainer,
  IconHeaderContainer
} from "../Login/FormElements";
import Copyright from "../../shared/Copyright";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import EmailFormData from "./models/EmailFormData";
import { changePasswordEnterEmail } from "../../../api/changePasswordEnterEmail";
import { EMAIL_INITIAL_VALUES } from "../../../constants/formInitialValues";
import { Formik, FormikHelpers, FormikProps } from "formik";
import InputField from "../../shared/formFields/InputField";
import { useHistory } from "react-router-dom";
import FeedbackMessagePageProps from "../../../pages/FeedbackMessagePage/models/FeedbackMessagePageProps";
import ErrorInfo from "../../../constants/ErrorInfo";

const EnterMail = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const validationSchema = yup.object().shape({
    email: yup.string().email(t("validEmail")).required("required")
  });

  const onSubmit = async (
    values: EmailFormData,
    { setSubmitting }: FormikHelpers<EmailFormData>
  ) => {
    changePasswordEnterEmail(values)
      .then(() => {
        // render success page
        history.push("/informacija", {
          feedbackMessageKey: "mailSendSuccess"
        } as FeedbackMessagePageProps);
      })
      .catch((error) => {
        history.push("/greska", {
          status: error?.response?.data?.status,
          message: error?.response?.data?.message,
          description: error?.response?.data?.description
        } as ErrorInfo);
      });
    setSubmitting(false); // this seems to just not work
  };

  // TODO [STYLE]
  return (
    <>
      <SignWrapper>
        <IconHeaderContainer>
          <IconContainer>
            <MailRounded />
          </IconContainer>
          <Typography variant="h5">{t("forgotPasswordEmailHint")}</Typography>
        </IconHeaderContainer>
        <Formik
          initialValues={EMAIL_INITIAL_VALUES}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange
          }: FormikProps<EmailFormData>) => (
            <>
              <MForm onSubmit={handleSubmit} onChange={handleChange}>
                <FlexColumnGapContainer>
                  <InputField
                    type="email"
                    variant="filled"
                    label={t("email")}
                    name="email"
                    fullWidth
                  />

                  <MButtonMaxWidth
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    type="submit"
                    name="submit"
                  >
                    {t("send")}
                  </MButtonMaxWidth>
                </FlexColumnGapContainer>
              </MForm>
            </>
          )}
        </Formik>
        <FlexSBContainer>
          {t("emailMustBeConfirmed")}
          <AnchorLink to="/prijava">
            <Typography variant="body2">{t("backToLogin")}</Typography>
          </AnchorLink>
        </FlexSBContainer>
        <Copyright />
      </SignWrapper>
    </>
  );
};

export default EnterMail;
