import { ReactElement } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthorizedRouteProps } from "./models/AuthorizedRouteProps";
import { useAppSelector } from "../../store/reducers/userDetailsReducer";

const AuthorizedRoute = ({
  component: Component,
  role,
  ...rest
}: AuthorizedRouteProps): ReactElement => {
  const { user } = useAppSelector((user) => user.UserState);

  return (
    <Route
      {...rest}
      render={(props) => {
        return user?.roles.includes(role) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/prijava",
              state: {
                snackbarData: {
                  severity: "info",
                  messageKey: "unauthorized"
                }
              }
            }}
          />
        );
      }}
    />
  );
};

export default AuthorizedRoute;
