import {
  ADD,
  notificationActionType,
  REMOVE
} from "../actions/notificationActionType";
import { AppDispatch, RootState } from "../store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { NotificationDTO } from "../../api/models/NotificationDTO";

export interface NotificationState {
  notifications: NotificationDTO[];
}

export const initialNotificationState: NotificationState = {
  notifications: []
};

export default (
  state: NotificationState = initialNotificationState,
  action: notificationActionType
): NotificationState => {
  switch (action.type) {
    case ADD:
      return {
        notifications: [...state.notifications, action.payload]
      };
    case REMOVE:
      return {
        notifications: [
          ...state.notifications.slice(0, action.payload),
          ...state.notifications.slice(action.payload + 1)
        ]
      };
    default:
      return state;
  }
};

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
