import { ReactElement, useContext, useEffect, useState } from "react";
import PopupFileUpload from "./PopupFileUpload";
import DocumentTile from "./DocumentTile";
import { MainContentContainer, MainTitle } from "../layout/LayoutElements";
import {
  AfterDocumentsTitleContainer,
  ColumnFlexContainer,
  FlexRowSpaceBetweenContainer,
  RowFlexContainer
} from "./DocumentListElements";
import { useTranslation } from "react-i18next";
import { DocumentListCoreProps } from "./models/DocumentListCoreProps";
import WarningAlert from "../WarningAlert";
import MToolTip from "../ToolTip";
import { patchDocumentStatus } from "../../../api/patchDocumentStatus";
import SnackbarErrorContext from "../SnackbarErrorContext";
import { v4 as uuid } from "uuid";

const DocumentListCore = ({
  documentTitles,
  downloadDocument,
  deleteDocument,
  canDelete,
  canApprove,
  fetchDocuments,
  needUpdate,
  setNeedUpdate,
  uploadDocument,
  userId
}: DocumentListCoreProps): ReactElement => {
  const [showFileUploadDialog, setShowFileUploadDialog] =
    useState<boolean>(false);
  const [
    numberOfDocumentsNeedingApproval,
    setNumberOfDocumentsNeedingApproval
  ] = useState<number>(0);
  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);

  useEffect(() => {
    if (documentTitles) {
      setNumberOfDocumentsNeedingApproval(
        documentTitles.filter((d) => !d.isApproved).length
      );
    }
  }, [documentTitles]);

  // To ensure documents are up to date with those on the server
  useEffect(() => {
    if (needUpdate) {
      fetchDocuments();
      setNeedUpdate(false);
    }
  }, [needUpdate]);

  const { t } = useTranslation();

  const handleDocumentStatusPatch = (
    isApproved: boolean,
    fileName: string
  ): void => {
    patchDocumentStatus(userId, fileName, isApproved)
      .then(() => setNeedUpdate(true))
      .catch(() => setSnackbarErrorMessageKey("documentStatusUpdateFailed"));
  };

  return (
    <MainContentContainer>
      <ColumnFlexContainer>
        {/*Upper div displaying title documents and containing new document button - START*/}
        <FlexRowSpaceBetweenContainer>
          <MainTitle>{t("documents")}</MainTitle>
          <AfterDocumentsTitleContainer>
            {numberOfDocumentsNeedingApproval > 0 && (
              <MToolTip message={t("needsApproval")}>
                <WarningAlert />
              </MToolTip>
            )}
            {canDelete && (
              <PopupFileUpload
                open={showFileUploadDialog}
                setUploadDialogOpen={setShowFileUploadDialog}
                setNeedDocumentUpdate={setNeedUpdate}
                uploadDocument={uploadDocument}
              />
            )}
          </AfterDocumentsTitleContainer>
        </FlexRowSpaceBetweenContainer>
        {/*Upper div displaying title documents and containing new document button - END*/}
        {/*List of all documents of this user - START*/}
        <RowFlexContainer>
          {documentTitles
            .sort((first, second) =>
              first.fileName.localeCompare(second.fileName)
            )
            .map((d) => (
              <DocumentTile
                fileName={d.fileName}
                isApproved={d.isApproved}
                key={uuid()}
                setNeedUpdate={setNeedUpdate}
                deleteDocument={deleteDocument}
                downloadDocument={downloadDocument}
                handleDocumentStatusPatch={handleDocumentStatusPatch}
                canDelete={canDelete}
                canApprove={canApprove}
              />
            ))}
        </RowFlexContainer>
        {/*List of all documents of this user - END*/}
      </ColumnFlexContainer>
    </MainContentContainer>
  );
};

export default DocumentListCore;
