import Language from "../locale/types/Language";
import LanguageCode from "../locale/types/LanguageCode";

/**
 * Languages currently supported within the application.
 */
const SUPPORTED_LANGUAGES: Record<LanguageCode, Language> = {
  hr: {
    name: "Hrvatski",
    code: "hr"
  },
  en: {
    name: "English",
    code: "en"
  }
};

export default SUPPORTED_LANGUAGES;
