import { AxiosRequestConfig, AxiosResponse } from "axios";
import { PERKS_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import CreatePerkDTO from "./models/CreatePerkDTO";

export const postPerkParams = (
  name: string,
  nameEnglish: string,
  perkTypeId: number
): AxiosRequestConfig =>
  ({
    url: PERKS_URL,
    method: "POST",
    data: { name, nameEnglish, perkTypeId } as CreatePerkDTO
  } as AxiosRequestConfig);

export const postPerk = async (
  name: string,
  nameEnglish: string,
  perkTypeId: number
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(
    postPerkParams(name, nameEnglish, perkTypeId)
  );
};
