import DocumentDTO from "../components/app/DocumentList/models/DocumentDTO";
import { ADMIN_DOCUMENT_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import DocumentWithIdDTO from "../components/app/ForeignDocumentList/models/DocumentWithIdDTO";

export const uploadDocumentByIdParams = (
  body: DocumentWithIdDTO
): AxiosRequestConfig =>
  ({
    url: ADMIN_DOCUMENT_URL,
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const uploadDocumentById = async (
  documentDTO: DocumentDTO,
  userId: string
): Promise<AxiosResponse<void>> => {
  const documentWithIdDTO: DocumentWithIdDTO = {
    ...documentDTO,
    userId: userId
  };

  return await axiosInstance.request(
    uploadDocumentByIdParams(documentWithIdDTO)
  );
};
