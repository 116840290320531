import styled from "styled-components";
import { white } from "../../../../constants/colorPallete";

export const ProfileInfoDrawer = styled.div`
  padding-left: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ProfileImageSmall = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 100%;
`;

export const ProfileImageBig = styled.img`
  width: 128px;
  height: 128px;
  border-radius: 100%;
`;

export const InfoContainerDrawer = styled.div`
  padding: 6px 0px 12px 0px;
`;

export const UserName = styled.h4`
  margin: 0px;
  margin-right: 16px;
  font-weight: 600;

  //So big names dont brake sidebar
  max-width: 135px;

  //for cases of HUGE NAMES
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 55px;
  color: ${white};
`;

export const UserRole = styled.h6`
  margin: 0px;
  font-weight: 400;
  color: ${white};
`;
