import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosInstance from "./axiosInstance";
import { DELETE_DOCUMENT_URL } from "./endpoints";

export const deleteDocumentProps = (fileName: string): AxiosRequestConfig =>
  ({
    url: DELETE_DOCUMENT_URL(fileName),
    method: "DELETE"
  } as AxiosRequestConfig);

export const deleteDocument = async (
  fileName: string
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(deleteDocumentProps(fileName));
};
