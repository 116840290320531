import styled from "styled-components";
import { RadioGroup } from "@material-ui/core";
import { theme } from "../../../styles/style";

export const RoleSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  background-color: white;
  color: black;
`;
