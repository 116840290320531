import { useTranslation } from "react-i18next";
import { ScrollNavLink } from "../../app/NavBar/NavBarElements";
import { ReactElement } from "react";
import { getScrollAttributes } from "../../../utils/getScrollAttributes";

const OurWorkLink = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <ScrollNavLink
      activeClass="selectedLink"
      {...getScrollAttributes("whatDoWe")}
    >
      {t("ourWork")}
    </ScrollNavLink>
  );
};

export default OurWorkLink;
