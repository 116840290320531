import { TextField, Typography } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { REGEX_NAME_SURNAME } from "../../../constants/regexConstants";
import { MButtonMaxWidth } from "../../shared/buttons/ButtonElements";
import Copyright from "../../shared/Copyright";
import {
  ColmAlignLeftContainer,
  FlexSBContainer,
  SignWrapper
} from "../../shared/layout/LayoutElements";
import { AnchorLink } from "../Login/FormElements";
import StepOneProps from "./models/StepOneProps";

const StepOne: FunctionComponent<StepOneProps> = ({ nextStep, stepCount }) => {
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [isFirstNameCorrect, setIsFirstNameCorrect] = useState(false);
  const [isLastNameCorrect, setIsLastNameCorrect] = useState(false);

  const { t } = useTranslation();

  const validationFirstNamesSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("required"))
      .matches(REGEX_NAME_SURNAME, t("validFirstName"))
      .max(64, t("maxLength64"))
  });

  const validationLastNamesSchema = yup.object().shape({
    lastName: yup
      .string()
      .required(t("required"))
      .matches(REGEX_NAME_SURNAME, t("validLastName"))
      .max(64, t("maxLength64"))
  });
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstNameValue(e.target.value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastNameValue(e.target.value);
  };

  useEffect(() => {
    if (isFirstNameCorrect && isLastNameCorrect) {
      nextStep();
    }
  }, [isFirstNameCorrect, isLastNameCorrect]);

  useEffect(() => {
    setIsFirstNameCorrect(false);
    setIsLastNameCorrect(false);
  }, [stepCount]);

  const handleValidation = () => {
    validationFirstNamesSchema
      .validate({
        firstName: firstNameValue
      })
      .then(() => {
        setFirstNameError("");
        setIsFirstNameCorrect(true);
      })
      .catch((error) => {
        setIsFirstNameCorrect(false);
        setFirstNameError(error.message);
      });
    validationLastNamesSchema
      .validate({
        lastName: lastNameValue
      })
      .then(() => {
        setIsLastNameCorrect(true);
        setLastNameError("");
      })
      .catch((error) => {
        setIsLastNameCorrect(false);
        setLastNameError(error.message);
      });
  };

  return (
    <>
      <SignWrapper>
        <ColmAlignLeftContainer>
          <Typography variant="subtitle1">
            {t("step")} <strong>1</strong> {t("of")} <strong>2</strong>
          </Typography>
          <Typography variant="h5">{t("dragonName")}</Typography>
          <Typography variant="subtitle2">{t("yuchForm")}</Typography>
        </ColmAlignLeftContainer>
        <TextField
          variant="filled"
          label={t("firstName")}
          fullWidth
          name="firstName"
          error={firstNameError !== ""}
          helperText={firstNameError}
          onChange={handleFirstNameChange}
        />
        <TextField
          variant="filled"
          label={t("lastName")}
          fullWidth
          name="lastName"
          error={lastNameError !== ""}
          helperText={lastNameError}
          onChange={handleLastNameChange}
        />

        <FlexSBContainer>
          <MButtonMaxWidth
            variant="contained"
            color="secondary"
            onClick={handleValidation}
          >
            {t("lastStep")}
          </MButtonMaxWidth>
        </FlexSBContainer>
        <AnchorLink to="/prijava">
          <Typography variant="body2">{t("loginHint")}</Typography>
        </AnchorLink>
        <Copyright />
      </SignWrapper>
    </>
  );
};

export default StepOne;
