import { ReactElement } from "react";
import WorkRecordsProps from "./models/WorkRecordsProps";
import UserAccordionList from "./UserAccordionList";
import UserWorkRecords from "../../shared/UserWorkRecords";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";

const WorkRecords = ({ action }: WorkRecordsProps): ReactElement => {
  const { user } = useAppSelector((state) => state.UserState);
  const userHasHigherPrivileges =
    user != undefined &&
    action.confirmedUsers.filter(
      (confirmedUser) =>
        confirmedUser.id == user.id && confirmedUser.coordinator
    ).length > 0;

  return (
    <>
      {user &&
        (userHasHigherPrivileges ? (
          <UserAccordionList action={action} />
        ) : (
          <UserWorkRecords
            userId={user.id}
            higherPrivileges={userHasHigherPrivileges}
            action={action}
          />
        ))}
    </>
  );
};

export default WorkRecords;
