import axios from "axios";
import { createJwtHeader, getJwtFromCookies } from "../utils/jwt";
import i18n from "../locale/i18n";
import { JWT_HEADER_KEY } from "../constants/apiConstants";

const axiosInstance = axios.create({
  baseURL: "/api",
  headers: {
    "content-type": "application/json"
  },
  responseType: "json"
});

axiosInstance.interceptors.request.use(async (config) => {
  const jwt = getJwtFromCookies();
  if (jwt !== undefined) {
    const jwtHeader = createJwtHeader(jwt);
    config.headers = {
      ...config.headers,
      [JWT_HEADER_KEY]: jwtHeader
    };
  }
  config.headers = {
    ...config.headers,
    "Accept-Language": i18n.language
  };
  return config;
});

export default axiosInstance;
