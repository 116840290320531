import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./styles/style";

import { persistor, store } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { StylesProvider } from "@material-ui/core/styles";
import { NotificationConnectionProvider } from "./components/app/NotificationConnectionContext";

ReactDOM.render(
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <NotificationConnectionProvider>
            <App />
          </NotificationConnectionProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById("root")
);
