import { ReactElement } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { MenuItem } from "@material-ui/core";
import { MSelect } from "../LanguagePicker/LanguagePickerContainer";
import FilterPopupProps from "./models/FilterPopupProps";
import FilterEntry from "./models/FilterEntry";
import { v4 as uuid } from "uuid";

const FilterPopup = ({
  filterEntries,
  filterValue,
  setFilterValue
}: FilterPopupProps): ReactElement => {
  return (
    <MSelect
      fullWidth={true}
      disableUnderline
      IconComponent={KeyboardArrowDownIcon}
      value={filterValue}
      MenuProps={{
        disableScrollLock: true,
        hideBackdrop: true
      }}
      onChange={(event) => setFilterValue(event.target.value as number)}
    >
      {filterEntries.map((entry: FilterEntry) => (
        <MenuItem key={uuid()} value={entry.value}>
          {entry.displayValue}
        </MenuItem>
      ))}
    </MSelect>
  );
};

export default FilterPopup;
