import { ReactElement, useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { FieldHookConfig, useField } from "formik";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Paper } from "@material-ui/core";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import i18next from "i18next";
import { enUS, hr } from "date-fns/locale";
import { CROATIAN } from "../../../constants/languageConstants";
import { normalizeDateToUTC } from "../../../utils/dateTimeUtils";

interface DatePickerProps {
  name: string;
  label: string;
  defaultValue?: Date;
  minDate?: Date;
  maxDate?: Date;
  onAccept?: (date: MaterialUiPickersDate) => void;
  setFieldValue: (name: string, value: string) => void;
}

const DatePickerField = (
  props: FieldHookConfig<MaterialUiPickersDate> & DatePickerProps
): ReactElement => {
  const [selectedDate, setSelectedDate] = useState<Date>(
    props.defaultValue || new Date()
  );
  const [field, meta, helper] = useField(props.name);
  const { error } = meta;
  const { setValue } = helper;
  const { value } = field;

  useEffect(() => {
    if (value == null || value == "") {
      setValue(normalizeDateToUTC(new Date()).toISOString());
    } else {
      setSelectedDate(new Date(value));
    }
  }, [value]);

  function onChange(date: MaterialUiPickersDate) {
    let dateAsDate = date as Date;
    setSelectedDate(dateAsDate);
    const ISODateString = normalizeDateToUTC(dateAsDate).toISOString();
    setValue(ISODateString);
  }

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={i18next.language == CROATIAN ? hr : enUS}
    >
      <KeyboardDatePicker
        name={props.name}
        className="responsivePicker"
        label={props.label}
        format="dd.MM.yyyy"
        margin="normal"
        inputVariant="filled"
        DialogProps={{ PaperComponent: Paper }}
        keyboardIcon={<EventRoundedIcon className="whiteIcon" />}
        error={!!error}
        helperText={error}
        value={selectedDate}
        onChange={onChange}
        minDate={props.minDate}
        maxDate={props.maxDate}
        onAccept={props.onAccept}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerField;
