import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import UseAxiosReturnType from "./models/UseAxiosReturnType";
import axiosInstance from "./axiosInstance";

/**
 *
 * <ul>
 *   <li>BodyT - type of object that is sent in request body</li>
 *   <li>ResponseT - type of object that is received in response body </li>
 *  </ul>
 */
export const useAxios = <BodyT, ResponseT>(
  params: AxiosRequestConfig,
  defaultErrorMessage = "",
  setError: Dispatch<SetStateAction<string>>
): UseAxiosReturnType<ResponseT> => {
  const [data, setData] = useState<ResponseT>({} as ResponseT);
  const [loading, setLoading] = useState<boolean>(true);
  const [response, setResponse] = useState<AxiosResponse<ResponseT>>(
    {} as AxiosResponse<ResponseT>
  );

  const fetchData = (params: AxiosRequestConfig) => {
    try {
      axiosInstance.request<BodyT, AxiosResponse<ResponseT>>(params).then(
        (result) => {
          setResponse(result);
          setData(result.data);
        },
        () => {
          setError(defaultErrorMessage);
        }
      );
    } catch (error) {
      setError(defaultErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(params);
  }, []);

  return { data, setData, loading, response };
};
