import { PageContainer } from "../../components/shared/layout/LayoutElements";
import ErrorEgg from "../../assets/svg/EggCryBabysvg.svg";
import { Snackbar, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  LinkWrapper,
  MButtonContrast
} from "../../components/shared/buttons/ButtonElements";
import { useTranslation } from "react-i18next";
import { ReactElement, useEffect, useState } from "react";
import ErrorInfo from "../../constants/ErrorInfo";
import { useLocation } from "react-router-dom";

const ErrorPage = (): ReactElement => {
  const { t } = useTranslation();
  const [snackbarErrorMessageKey, setSnackbarErrorMessageKey] = useState("");
  const location = useLocation<ErrorInfo>();

  useEffect(() => {
    if (location?.state?.description != undefined) {
      setSnackbarErrorMessageKey(location.state.description);
    }
  }, []);

  return (
    <>
      <PageContainer>
        <img className="bigSvgIcon" src={ErrorEgg} alt="" />
        <Typography variant="h3">{t("somethingsWrong")}</Typography>
        <LinkWrapper to="/">
          <MButtonContrast variant="contained" color="secondary">
            {t("returnHome")}
          </MButtonContrast>
        </LinkWrapper>
      </PageContainer>
      <Snackbar
        open={snackbarErrorMessageKey.length !== 0}
        autoHideDuration={10000}
        onClose={() => setSnackbarErrorMessageKey("")}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          {t(snackbarErrorMessageKey)}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ErrorPage;
