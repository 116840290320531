import { Paper } from "@material-ui/core";
import styled from "styled-components";
import background from "../../../assets/svg/AbstractGradient.svg";
import { white } from "../../../constants/colorPallete";
import { theme } from "../../../styles/style";
export const StepColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
`;
export const StepRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;
export const WizardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  margin-left: 18px;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
`;
export const SelectionContainer = styled.div`
  color: ${white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
export const MWizardPaper = styled(Paper)`
  max-width: 600px;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  padding: 18px;
  border-radius: 18px;
  ${theme.breakpoints.down("md")} {
    height: auto;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FlexEndContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
export const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
`;
