import DateFnsUtils from "@date-io/date-fns";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import countryList from "react-select-country-list";
import * as yup from "yup";
import { adminPostPersonalInfo } from "../../../api/adminPostPersonalInfo";
import { postPersonalInfo } from "../../../api/postPersonalInfo";
import {
  mPhoneButtonStyle,
  mPhoneInputStyle
} from "../../../constants/cssConstants";
import ErrorInfo from "../../../constants/ErrorInfo";
import { REGEX_NAME_SURNAME } from "../../../constants/regexConstants";
import { saveUserDetails } from "../../../store/actions/userDetailsActions";
import { getCountryCode } from "../../../utils/getCountryCode";
import { MButton, MButtonContrast } from "../../shared/buttons/ButtonElements";
import CloseDialogButton from "../../shared/buttons/CloseDialogButton";
import InputField from "../../shared/formFields/InputField";
import {
  CenterContainer,
  FlexSBContainer,
  MForm,
  SignWrapper
} from "../../shared/layout/LayoutElements";
import { WizardTitle } from "../../shared/text/TextElements";
import { ButtonContainer } from "../ActionForm/ActionFormElements";
import { CountryData } from "./models/CountryData";
import { ProfileWizardStep1Data } from "./models/ProfileWizardData";
import ProfileWizardStepProps from "./models/ProfileWizardStepProps";
import { FlexEndContainer, TitleContainer } from "./ProfileWizardElements";
import UserContext from "./UserContext";
import i18next from "i18next";
import { enUS, hr } from "date-fns/locale";
import { CROATIAN } from "../../../constants/languageConstants";

const StepOne = ({
  afterSubmit,
  cancel,
  close
}: ProfileWizardStepProps): ReactElement => {
  const { user, setUser, isAdmin } = useContext(UserContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const INITIAL_VALUES = {
    address: user ? user.address : null,
    country:
      user && user.country
        ? { name: user.country, code: getCountryCode(user.country) }
        : null,
    dateOfBirth:
      user && user.dateOfBirth
        ? new Date(user.dateOfBirth).toISOString()
        : null,
    email: user ? user.email : null,
    firstName: user ? user.firstName : null,
    lastName: user ? user.lastName : null,
    gender: user ? user.gender : null,
    phone: user ? user.phone : null,
    phoneIsPrivate: user ? user.phoneIsPrivate : false
  } as ProfileWizardStep1Data;

  const validationSchema = yup.object().shape({
    address: yup
      .string()
      .max(255, t("maxLength", { count: 255 }))
      .nullable(),
    dateOfBirth: yup.date().max(new Date()).nullable(),
    email: yup.string().required(t("required")).email(t("validEmail")),
    firstName: yup
      .string()
      .required(t("required"))
      .matches(REGEX_NAME_SURNAME, t("validName"))
      .max(64, t("maxLength64")),
    lastName: yup
      .string()
      .required(t("required"))
      .matches(REGEX_NAME_SURNAME, t("validLastName"))
      .max(64, t("maxLength64")),
    gender: yup.string().max(1).min(1).nullable(),
    phoneIsPrivate: yup.bool().required(),
    phone: yup
      .string()
      .max(20, t("maxLength", { count: 20 }))
      .min(8, t("minLength", { count: 8 }))
      .nullable()
  });

  const handleSubmit = (
    values: ProfileWizardStep1Data,
    { setSubmitting }: FormikHelpers<ProfileWizardStep1Data>
  ) => {
    if (isAdmin && user) {
      adminPostPersonalInfo(values as ProfileWizardStep1Data, user.id)
        .then((res) => {
          setUser(res.data);
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        });
    } else {
      postPersonalInfo(values as ProfileWizardStep1Data)
        .then((res) => {
          dispatch(saveUserDetails(res.data));
          setUser(res.data);
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        });
    }
    setSubmitting(false);
  };

  const submitAndNext = (
    values: ProfileWizardStep1Data,
    helpers: FormikHelpers<ProfileWizardStep1Data>
  ) => {
    handleSubmit(values, helpers);
    afterSubmit();
  };

  return (
    <Formik
      className="noScroll"
      initialValues={INITIAL_VALUES}
      onSubmit={submitAndNext}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({
        isSubmitting,
        handleChange,
        handleSubmit,
        values,
        setFieldValue,
        submitForm
      }: FormikProps<ProfileWizardStep1Data>) => (
        <MForm onSubmit={handleSubmit} onChange={handleChange}>
          <CenterContainer>
            <SignWrapper>
              <TitleContainer>
                <WizardTitle>{t("wizardStepOneTitle")}</WizardTitle>
                <CloseDialogButton close={close} />
              </TitleContainer>
              <FlexSBContainer>
                <InputField
                  variant="filled"
                  label={t("firstName")}
                  fullWidth
                  name="firstName"
                />
                <InputField
                  variant="filled"
                  label={t("lastName")}
                  fullWidth
                  name="lastName"
                />
              </FlexSBContainer>
              <InputField
                variant="filled"
                label={t("email")}
                fullWidth
                name="email"
              />
              <br />
              <FlexSBContainer>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={i18next.language == CROATIAN ? hr : enUS}
                >
                  <KeyboardDatePicker
                    name="dateOfBirth"
                    label={t("dateOfBirth")}
                    format="dd.MM.yyyy"
                    value={values.dateOfBirth}
                    inputVariant="filled"
                    keyboardIcon={<EventRoundedIcon className="whiteIcon" />}
                    DialogProps={{ PaperComponent: Paper }}
                    onChange={(value) => setFieldValue("dateOfBirth", value)}
                    disableFuture
                  />
                </MuiPickersUtilsProvider>
                <FormControl style={{ minWidth: "90px" }} variant="filled">
                  <InputLabel>{t("gender")}</InputLabel>
                  <Select
                    name="gender"
                    variant="filled"
                    value={values.gender}
                    onChange={handleChange}
                  >
                    <MenuItem value="M">{t("male")}</MenuItem>
                    <MenuItem value="F">{t("female")}</MenuItem>
                    <MenuItem value="O">{t("other")}</MenuItem>
                  </Select>
                </FormControl>
              </FlexSBContainer>
              <InputField
                variant="filled"
                label={t("address")}
                fullWidth
                name="address"
              />
              <FlexSBContainer>
                <PhoneInput
                  value={values.phone}
                  inputProps={{ name: "phone", label: t("phone") }}
                  onChange={(value) =>
                    setFieldValue("phone", value ? "+" + value : null)
                  }
                  country="hr"
                  specialLabel=""
                  enableSearch={true}
                  masks={{ hr: ".. ... ...." }}
                  inputStyle={mPhoneInputStyle}
                  buttonStyle={mPhoneButtonStyle}
                  dropdownStyle={{ color: "black" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.phoneIsPrivate}
                      onChange={(event) => {
                        setFieldValue("phoneIsPrivate", event.target.checked);
                      }}
                    />
                  }
                  label={t("private")}
                />
              </FlexSBContainer>
              <Autocomplete
                value={
                  values.country &&
                  ({
                    label: values.country.name,
                    value: values.country.code
                  } as CountryData)
                }
                onChange={(event, value) => {
                  setFieldValue(
                    "country",
                    value
                      ? {
                          name: value.label,
                          code: value.value
                        }
                      : null
                  );
                }}
                options={countryList().getData()}
                getOptionLabel={(option) => option.label}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t("country")}
                    fullWidth
                  />
                )}
              />
            </SignWrapper>
          </CenterContainer>
          <ButtonContainer>
            <MButton
              color="secondary"
              variant="contained"
              onClick={cancel}
              disabled
              startIcon={<NavigateBefore />}
            >
              {t("createActionFormPreviousStep")}
            </MButton>
            <MButtonContrast
              type="submit"
              name="submit"
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
              endIcon={<NavigateNext />}
            >
              {t("createActionFormNextStep")}
            </MButtonContrast>
          </ButtonContainer>
          <FlexEndContainer>
            <MButton
              autoFocus
              onClick={() => {
                submitForm().then(close);
              }}
              variant="contained"
              color="primary"
            >
              {t("saveAndExit")}
            </MButton>
          </FlexEndContainer>
        </MForm>
      )}
    </Formik>
  );
};

export default StepOne;
