import { ReactElement } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import "./locale/i18n";
import NavBar from "./components/app/NavBar";
import Footer from "./components/app/Footer";
import ScrollToTop from "./components/app/ScrollToTop";

function App(): ReactElement {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <NavBar />
        <Routes />
      </BrowserRouter>
      <Footer />
      {/* This background svg will be optimised. for now we use a temporary svg in the body tag in app.css */}
      {/* <BackgroundImage /> */}
    </>
  );
}

export default App;
