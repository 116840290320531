import ActionDTO from "../../../pages/ActionFeed/models/ActionDTO";
import { actionContainsPerk } from "../../shared/action/perkUtilFunctions";
import PerkNoQuantityDTO from "../../../api/models/PerkNoQuantityDTO";

export const containsPerk = (
  action: ActionDTO,
  selectedPerks: PerkNoQuantityDTO[]
): boolean => {
  return (
    selectedPerks.length == 0 ||
    selectedPerks.some((perk) => actionContainsPerk(action, perk))
  );
};

export const containsString = (
  action: ActionDTO,
  stringFilterValue: string
): boolean => {
  if (stringFilterValue.trim().length == 0) {
    return true;
  } else {
    const filterValue = stringFilterValue.trim().toLowerCase();
    return (
      (action.title &&
        action.title.trim().toLowerCase().includes(filterValue)) ||
      (action.shortDescription &&
        action.shortDescription.trim().toLowerCase().includes(filterValue)) ||
      action.location.trim().toLowerCase().includes(filterValue)
    );
  }
};

export const containsDate = (action: ActionDTO, date: Date | null): boolean => {
  if (date == null) return true;

  date.setHours(0);
  date.setMinutes(0);

  const startDate = new Date(action.startDate);
  startDate.setHours(0);
  startDate.setMinutes(0);

  const endDate = new Date(action.endDate);
  endDate.setHours(0);
  endDate.setMinutes(0);

  return startDate <= date && endDate >= date;
};
