import { AxiosRequestConfig, AxiosResponse } from "axios";
import { JOB_TYPES_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import JobType from "./models/JobType";

export const jobTypeParams = (): AxiosRequestConfig =>
  ({
    url: JOB_TYPES_URL,
    method: "GET"
  } as AxiosRequestConfig);

export const getJobTypes = async (): Promise<AxiosResponse<JobType[]>> => {
  return await axiosInstance.request(jobTypeParams());
};
