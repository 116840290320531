import { ProfileWizardStep2Data } from "../components/app/ProfileWizard/models/ProfileWizardData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { SKILLS_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import UserDetailsDTO from "./models/UserDetailsDTO";

export const skillsParams = (
  body: ProfileWizardStep2Data
): AxiosRequestConfig =>
  ({
    url: SKILLS_URL,
    method: "PATCH",
    data: body
  } as AxiosRequestConfig);

export const postSkills = async (
  body: ProfileWizardStep2Data
): Promise<AxiosResponse<UserDetailsDTO>> =>
  await axiosInstance.request(skillsParams(body));
