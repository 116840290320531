import { ReactElement, useRef, useState } from "react";
import { uploadPicture } from "../../../api/uploadPicture";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import { useDispatch } from "react-redux";
import AvatarEditorCore from "../../shared/AvatarEditorCore";
import { saveProfilePicture } from "../../../store/actions/userDetailsActions";

const ProfilePictureEditor = (): ReactElement => {
  const dispatch = useDispatch();
  const { profilePicture } = useAppSelector((state) => state.UserState);

  const [base64Image, setBase64Image] = useState<string>("");
  const editorRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const [showProfilePictureEditorDialog, setShowProfilePictureEditorDialog] =
    useState<boolean>(false);
  const [isPictureReadyToSend, setIsPictureReadyToSend] =
    useState<boolean>(false);
  const [errorMessageId, setErrorMessageId] = useState<string>("");
  const [scale, setScale] = useState<number>(1.2);

  const handleProfilePictureEditorDialogOpen = () => {
    setShowProfilePictureEditorDialog(true);
    setScale(1.2);
  };

  const handleProfilePictureEditorDialogClose = () => {
    setIsPictureReadyToSend(false);
    setShowProfilePictureEditorDialog(false);
    setBase64Image("");
  };

  const onUpload = (): void => {
    const canvasScaled = editorRef?.current?.getImageScaledToCanvas();
    // Variable is used because state does not guarantee immediate change
    // and it must be insured that new image gets sent to server
    let imageInBase64Format = canvasScaled.toDataURL();

    // Split is necessary for the file to be stored correctly on server
    // It is not split before because html img tag requires the original format
    uploadPicture({ picture: imageInBase64Format.split(",")[1] })
      .then(() => {
        dispatch(saveProfilePicture(imageInBase64Format));
      })
      .catch(() => {
        setErrorMessageId("uploadFailure");
      });
    handleProfilePictureEditorDialogClose();
  };

  return (
    <AvatarEditorCore
      base64Image={base64Image}
      setBase64Image={setBase64Image}
      scale={scale}
      setScale={setScale}
      errorMessageId={errorMessageId}
      setErrorMessageId={setErrorMessageId}
      handleProfilePictureEditorDialogClose={
        handleProfilePictureEditorDialogClose
      }
      handleProfilePictureEditorDialogOpen={
        handleProfilePictureEditorDialogOpen
      }
      isPictureReadyToSend={isPictureReadyToSend}
      setIsPictureReadyToSend={setIsPictureReadyToSend}
      showProfilePictureEditorDialog={showProfilePictureEditorDialog}
      profilePicture={profilePicture}
      editorRef={editorRef}
      onUpload={onUpload}
    />
  );
};

export default ProfilePictureEditor;
