import { PROFILE_PICTURE_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export const getImageParams = (): AxiosRequestConfig =>
  ({
    url: PROFILE_PICTURE_URL,
    method: "GET",
    responseType: "blob"
  } as AxiosRequestConfig);

export const getImage = async (): Promise<AxiosResponse<Blob>> => {
  return await axiosInstance.request(getImageParams());
};
