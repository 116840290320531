import styled from "styled-components";
import { TextField, Typography } from "@material-ui/core";
import { theme } from "../../../styles/style";

export const ActionFeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding-inline: 5%;
`;

export const ActionCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const LeftActionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  flex: 1;
  ${theme.breakpoints.up("sm")} {
    min-width: 500px;
  }
  ${theme.breakpoints.down("sm")} {
    min-width: 400px;
  }
  ${theme.breakpoints.down("xs")} {
    min-width: 280px;
  }
  flex-wrap: wrap;
`;

export const RightActionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const ActionCardDataContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const ActionCardLocationTitle = styled.p`
  font-weight: 300;
  color: white;
  margin-block: 5px;
`;
export const CategoriesComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 5px;
`;
export const CategoryGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const ItalicText = styled.p`
  font-weight: bold;
  font-style: italic;
  font-size: 16px;
  margin-block: 5px;
`;

export const ActionCardDescription = styled(Typography)`
  word-wrap: break-word;
  word-break: break-word;
`;

export const FilterContainer = styled.div`
  width: 1030px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  ${theme.breakpoints.down("md")} {
    width: 790px;
    gap: 16px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 480px;
  }
  ${theme.breakpoints.down("xs")} {
    width: 360px;
  }
  @media (max-width: 400px) {
    width: 300px;
  }
`;
export const TextFilterContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  display: flex;
  padding: 4px;
  width: 200px;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
`;

export const SearchTextField = styled(TextField)`
  & > div > input {
    color: black;
  }
  & > div > div > svg {
    color: black;
  }
  div {
    background-color: white;
  }
`;

export const FilterButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const FilterFlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
`;
