export const REGISTER_URL = "/register";

export const LOGIN_URL = "/login";

export const USER_DETAILS_URL = "/users";

export const GET_ALL_ACTIONS_URL = "/actions";

export const ACTION_URL = (actionId: number): string =>
  `${GET_ALL_ACTIONS_URL}/${actionId}`;

export const DOCUMENT_URL = "/documents";

export const DOCUMENT_CATEGORIES_URL = "/document-categories";

export const VOLUNTEER_LIST_URL = "/users/volunteers";

export const PERKS_URL = "/perks";

export const DAILY_WORK_RECORDS = "/daily-work-records";

export const DELETE_PERK_URL = (targetPerkId: number): string =>
  `${PERKS_URL}/${targetPerkId}`;

export const PERK_TYPES_URL = "/perk-types";

export const DELETE_PERK_TYPE_URL = (targetPerkTypeId: number): string =>
  `${PERK_TYPES_URL}/${targetPerkTypeId}`;

export const VOLUNTEER_PICTURE_URL = "/volunteers/profile-picture";

export const REVIEWS_URL = "/reviews";

// parametrized by targetUserId
export const GET_REVIEWS_URL = (targetUserId: string): string =>
  `${REVIEWS_URL}/${targetUserId}`;

export const DELETE_REVIEW_URL = (reviewId: number): string =>
  `${REVIEWS_URL}/${reviewId}`;

export const PROFILE_PICTURE_URL = "/users/profile-picture";

export const SIGN_UP_FOR_ACTION_URL = (actionId: number): string =>
  `/actions/${actionId}`;

export const ADMIN_PICTURE_URL = "/admin/profile-picture";

export const ADMIN_DOCUMENT_URL = "/admin/documents";

export const ADMIN_REPORT_URL = "/admin/report";

export const DELETE_DOCUMENT_BY_ID_URL = (
  targetUserId: string,
  fileName: string
): string => `${ADMIN_DOCUMENT_URL}/${targetUserId}/${fileName}`;

export const GET_DOCUMENT_BY_ID_URL = (
  targetUserId: string,
  fileName: string
): string => `${DOCUMENT_URL}/${targetUserId}/${fileName}`;

export const GET_IMAGE_BY_ID_URL = (targetUserId: string): string =>
  `${PROFILE_PICTURE_URL}/${targetUserId}`;

export const GET_USER_DETAILS_BY_ID_URL = (targetUserId: string): string =>
  `${USER_DETAILS_URL}/${targetUserId}`;

export const GET_ACTIVE_ACTIONS_FOR_USER_URL = (targetUserId: string): string =>
  `${USER_DETAILS_URL}/${targetUserId}/active-actions`;

export const GET_DAILY_WORK_RECORDS_FOR_ACTION_FOR_USERS = (
  actionId: number
): string => `${DAILY_WORK_RECORDS}/actions/${actionId}/users`;

export const GET_DAILY_WORK_RECORDS_FOR_ACTION_FOR_USER = (
  actionId: number,
  userId: string
): string =>
  `${GET_DAILY_WORK_RECORDS_FOR_ACTION_FOR_USERS(actionId)}/${userId}`;

export const GET_FINISHED_ACTIONS_FOR_USER_URL = (
  targetUserId: string
): string => `${USER_DETAILS_URL}/${targetUserId}/finished-actions`;

export const GET_ARCHIVED_ACTIONS_FOR_USER_URL = (
  targetUserId: string
): string => `${USER_DETAILS_URL}/${targetUserId}/archived-actions`;

export const GET_ADMIN_INTERESTS_BY_ID_URL = (targetUserId: string): string =>
  `${ADMIN_INTERESTS_URL}/${targetUserId}`;

export const GET_ADMIN_PERSONAL_INFO_BY_ID_URL = (
  targetUserId: string
): string => `${ADMIN_PERSONAL_INFO_URL}/${targetUserId}`;

export const GET_ADMIN_SKILLS_BY_ID_URL = (targetUserId: string): string =>
  `${ADMIN_SKILLS_URL}/${targetUserId}`;

export const GET_DOCUMENT_URL = (targetFileName: string): string =>
  `${DOCUMENT_URL}/${targetFileName}`;
export const DELETE_DOCUMENT_URL = (targetFileName: string): string =>
  `${DOCUMENT_URL}/${targetFileName}`;

export const SPECIFIC_DAILY_WORK_RECORD = (dailyWorkRecordId: number): string =>
  `${DAILY_WORK_RECORDS}/${dailyWorkRecordId}`;

export const CHANGE_PASSWORD_EMAIL_URL = "/change-password/email";
export const CONFIRM_EMAIL_URL = "/confirm-email";
export const RESEND_EMAIL_URL = "/confirm-email/resend";

export const CHANGE_PASSWORD_PASSWORD_URL = "/change-password/new-password";

export const JOB_TYPES_URL = "/job-types";

export const PATCH_USER_ROLES_BY_ID_URL = (targetUserId: string): string =>
  `/admin/${targetUserId}/roles`;

export const GET_ACTION_USERS_FOR_ACTION_ID = (actionId: string): string =>
  `/actions/${actionId}/users`;

export const PERSONAL_INFO_URL = "/users/personal-info";

export const SKILLS_URL = "/users/skills";

export const INTERESTS_URL = "/users/interests";

export const ADMIN_PERSONAL_INFO_URL = "admin/personal-info";

export const ADMIN_SKILLS_URL = "admin/skills";

export const ADMIN_INTERESTS_URL = "admin/interests";

export const NOTIFICATION_BY_ID_URL = (notificationId: number): string =>
  `/notifications/${notificationId}`;
