import { Badge } from "@material-ui/core";
import { ReactElement, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MAX_DESCRIPTION_LENGTH } from "../../../constants/userActionsConstants";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import { getCutTextThreeDots } from "../../../utils/getCutTextThreeDots";
import DatesComponent from "../../shared/action/DatesComponent";
import ActionTitleClickable from "../../shared/ActionTitle/ActionTitleClickable";
import { MButton, MButtonContrast } from "../../shared/buttons/ButtonElements";
import {
  ButtonContainer,
  MainContentContainer
} from "../../shared/layout/LayoutElements";
import LocationLabel from "../../shared/LocationLabel";
import {
  ActionCardDataContent,
  ActionCardDescription,
  LeftActionCardContainer,
  RightActionCardContainer
} from "../ActionFeed/ActionFeedElements";
import { BasicActionTileContainer } from "./UserActionsElements";
import { deleteAction } from "../../../api/deleteAction";
import BasicActionTileProps from "./models/BasicActionTileProps";
import ConfirmDialog from "../../shared/ConfirmDialog";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import DeleteIcon from "@material-ui/icons/Delete";
import FabReactive from "../../shared/FabReactive";
import useAuth from "../../../hooks/useAuth";
import Role from "../../../constants/roles";
import { patchAction } from "../../../api/patchAction";
import TimesComponent from "../../shared/action/TimesComponent";

const BasicActionTile = ({
  basicActionDetailsDTO,
  setNeedToUpdateActions
}: BasicActionTileProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useAppSelector((state) => state.UserState);
  const { role } = useAuth();

  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false);
  const [isArchiveConfirmDialogOpen, setIsArchiveConfirmDialogOpen] =
    useState(false);
  const [isUnarchiveConfirmDialogOpen, setIsUnarchiveConfirmDialogOpen] =
    useState(false);

  const handleDelete = (): void => {
    deleteAction(basicActionDetailsDTO.id)
      .then(() => {
        setNeedToUpdateActions(true);
      })
      .catch(() => setSnackbarErrorMessageKey("couldNotDeleteAction"));
  };

  const handleArchive = (): void => {
    patchAction({ isArchived: true }, basicActionDetailsDTO.id)
      .then(() => {
        setNeedToUpdateActions(true);
      })
      .catch(() => setSnackbarErrorMessageKey("couldNotArchiveAction"));
  };

  const handleUnarchive = (): void => {
    patchAction({ isArchived: false }, basicActionDetailsDTO.id)
      .then(() => {
        setNeedToUpdateActions(true);
      })
      .catch(() => setSnackbarErrorMessageKey("couldNotArchiveAction"));
  };

  return (
    <>
      <ConfirmDialog
        title={t("confirmActionDelete")}
        content={t("confirmActionDeleteDetails", {
          title: basicActionDetailsDTO.title
        })}
        open={isDeleteConfirmDialogOpen}
        setOpen={setIsDeleteConfirmDialogOpen}
        onConfirm={handleDelete}
      />
      <ConfirmDialog
        title={t("confirmActionArchive")}
        content={t("confirmActionArchiveDetails", {
          title: basicActionDetailsDTO.title
        })}
        open={isArchiveConfirmDialogOpen}
        setOpen={setIsArchiveConfirmDialogOpen}
        onConfirm={handleArchive}
      />
      <ConfirmDialog
        title={t("confirmActionUnarchive")}
        content={t("confirmActionUnarchiveDetails", {
          title: basicActionDetailsDTO.title
        })}
        open={isUnarchiveConfirmDialogOpen}
        setOpen={setIsUnarchiveConfirmDialogOpen}
        onConfirm={handleUnarchive}
      />
      <MainContentContainer>
        <BasicActionTileContainer>
          <LeftActionCardContainer>
            <ActionCardDataContent>
              <ActionTitleClickable
                title={basicActionDetailsDTO.title}
                id={basicActionDetailsDTO.id}
              />
              <LocationLabel location={basicActionDetailsDTO.location} />
              <ActionCardDescription>
                {getCutTextThreeDots(
                  basicActionDetailsDTO.shortDescription,
                  MAX_DESCRIPTION_LENGTH
                )}
              </ActionCardDescription>
            </ActionCardDataContent>
          </LeftActionCardContainer>
          <RightActionCardContainer>
            <DatesComponent
              startDate={basicActionDetailsDTO.startDate}
              endDate={basicActionDetailsDTO.endDate}
            />
            <TimesComponent
              startTime={basicActionDetailsDTO.startTime}
              endTime={basicActionDetailsDTO.endTime}
            />
            <ButtonContainer>
              {user &&
                basicActionDetailsDTO.coordinatorIds.some(
                  (coordinatorId) => coordinatorId == user.id
                ) && (
                  <Badge
                    badgeContent={basicActionDetailsDTO.unconfirmedUsers}
                    color="error"
                  >
                    <MButtonContrast
                      variant="contained"
                      onClick={() =>
                        history.push(
                          `/akcije/${basicActionDetailsDTO.id}/korisnici`
                        )
                      }
                      color="secondary"
                    >
                      {t("actionUserRequests")}
                    </MButtonContrast>
                  </Badge>
                )}
              <MButton
                variant="contained"
                color="secondary"
                onClick={() =>
                  history.push(`/akcije/${basicActionDetailsDTO.id}`)
                }
              >
                {t("moreInfoForAction")}
              </MButton>
              {user &&
                !basicActionDetailsDTO.isArchived &&
                (role == Role.ADMINISTRATOR ||
                  basicActionDetailsDTO.coordinatorIds.some(
                    (coordinatorId) => coordinatorId == user.id
                  )) && (
                  <FabReactive
                    icon={<DeleteIcon />}
                    label={t("delete")}
                    onClick={() => setIsDeleteConfirmDialogOpen(true)}
                  />
                )}
              {basicActionDetailsDTO.isArchived && role == Role.ADMINISTRATOR && (
                <MButton
                  variant="contained"
                  color="secondary"
                  onClick={() => setIsUnarchiveConfirmDialogOpen(true)}
                >
                  {t("unarchive")}
                </MButton>
              )}
              {user &&
                !basicActionDetailsDTO.isArchived &&
                (role == Role.ADMINISTRATOR ||
                  basicActionDetailsDTO.coordinatorIds.some(
                    (coordinatorId) => coordinatorId == user.id
                  )) && (
                  <MButton
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsArchiveConfirmDialogOpen(true)}
                  >
                    {t("archive")}
                  </MButton>
                )}
            </ButtonContainer>
          </RightActionCardContainer>
        </BasicActionTileContainer>
      </MainContentContainer>
    </>
  );
};

export default BasicActionTile;
