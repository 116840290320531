import { Fab, Typography } from "@material-ui/core";
import { ReactElement, useEffect, useState } from "react";
import { FlexFab } from "../buttons/ButtonElements";
import MToolTip from "../ToolTip";
import FabReactiveProps from "./models/FabReactiveProps";

const FabReactive = ({
  label,
  icon,
  onClick,
  disabled
}: FabReactiveProps): ReactElement => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakPoint = 980;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const renderOnlyIcon = (
    <>
      <MToolTip message={label}>
        <Fab
          onClick={onClick}
          color="secondary"
          aria-label={label}
          size="small"
          variant={"circular"}
          disabled={disabled}
        >
          {icon}
        </Fab>
      </MToolTip>
    </>
  );

  const renderWithMessage = (
    <>
      <FlexFab
        onClick={onClick}
        color="secondary"
        aria-label={label}
        variant={"extended"}
        disabled={disabled}
      >
        {icon}
        <Typography variant={"button"}>{label}</Typography>
      </FlexFab>
    </>
  );

  return <>{width > breakPoint ? renderWithMessage : renderOnlyIcon}</>;
};

export default FabReactive;
