import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../store/reducers/userDetailsReducer";
import { removeJwt } from "../../utils/jwt";
import { logout } from "../../store/actions/userDetailsActions";
import { useDispatch } from "react-redux";
import AuthenticationHelpers from "./models/AuthenticationHelpers";
import Role from "../../constants/roles";
import { getMostSignificantRole } from "../../utils/roleUtil";
import { useNotificationConnection } from "../../components/app/NotificationConnectionContext";

const useAuth = (): AuthenticationHelpers => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { disconnect } = useNotificationConnection();
  const { logged: isLoggedIn, user } = useAppSelector((user) => user.UserState);

  const redirectToRegister = () => {
    history.push("/registracija");
  };

  const redirectToLogin = () => {
    history.push("/prijava");
  };

  const handleLogout = () => {
    disconnect(); // called before removing jwt because its needed to authorize user
    dispatch(logout());
    removeJwt();
  };

  const getRole = () => {
    if (isLoggedIn && user) {
      return getMostSignificantRole(user.roles);
    } else {
      return Role.GUEST;
    }
  };

  const role = getRole();

  return {
    isLoggedIn,
    redirectToLogin,
    redirectToRegister,
    handleLogout,
    role
  };
};

export default useAuth;
