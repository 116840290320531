import { ReactElement, useContext, useState } from "react";
import { Dialog, TextField } from "@material-ui/core";
import AddCategoryDialogProps from "./models/AddCategoryDialogProps";
import { useTranslation } from "react-i18next";
import { TextInputContainer } from "./CategoryEditFormElements";
import { MButtonMaxWidth } from "../../shared/buttons/ButtonElements";
import { postPerkType } from "../../../api/postPerkType";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import { postPerk } from "../../../api/postPerk";

const AddPerkPerkTypeDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  updateData,
  perkTypeId
}: AddCategoryDialogProps): ReactElement => {
  const [nameCroatian, setNameCroatian] = useState("");
  const [nameEnglish, setNameEnglish] = useState("");
  const { t } = useTranslation();

  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);

  const handleDialogClose = () => {
    setNameCroatian("");
    setNameEnglish("");
    setIsDialogOpen(false);
  };

  const handlePerkSubmit = () => {
    postPerk(nameCroatian, nameEnglish, perkTypeId as number)
      .then(() => {
        updateData(perkTypeId as number);
        handleDialogClose();
      })
      .catch(() => {
        setSnackbarErrorMessageKey("couldNotCreateSubcategory");
        handleDialogClose();
      });
  };

  const handlePerkTypeSubmit = () => {
    postPerkType(nameCroatian, nameEnglish)
      .then(() => {
        updateData();
        handleDialogClose();
      })
      .catch(() => {
        setSnackbarErrorMessageKey("couldNotCreateCategory");
        handleDialogClose();
      });
  };

  const handleSubmit = () => {
    perkTypeId ? handlePerkSubmit() : handlePerkTypeSubmit();
  };

  return (
    <Dialog open={isDialogOpen} onClose={handleDialogClose}>
      <TextInputContainer>
        <TextField
          variant="filled"
          type="text"
          value={nameCroatian}
          label={t("nameCroatian")}
          inputProps={{ maxLength: 32 }}
          onChange={(event) => setNameCroatian(event.target.value)}
        />
        <TextField
          variant="filled"
          type="text"
          value={nameEnglish}
          label={t("nameEnglish")}
          inputProps={{ maxLength: 32 }}
          onChange={(event) => setNameEnglish(event.target.value)}
        />
        <MButtonMaxWidth
          disabled={
            nameCroatian.trim().length == 0 || nameEnglish.trim().length == 0
          }
          type={"submit"}
          variant={"contained"}
          color={"secondary"}
          onClick={handleSubmit}
        >
          {t("upload")}
        </MButtonMaxWidth>
      </TextInputContainer>
    </Dialog>
  );
};

export default AddPerkPerkTypeDialog;
