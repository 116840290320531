import ActionFormData from "../components/app/ActionForm/models/ActionFormData";
import CreateReviewFormData from "../components/app/CreateReviewForm/models/CreateReviewFormData";
import EmailFormData from "../components/app/RestartPassword/models/EmailFormData";
import PasswordFormData from "../components/app/RestartPassword/models/PasswordFormData";

export const ACTION_FORM_INITIAL_VALUES: ActionFormData = {
  description: "",
  descriptionEnglish: "",
  endDate: "",
  location: "",
  requiredPerks: [],
  shortDescription: "",
  shortDescriptionEnglish: "",
  startDate: "",
  title: "",
  titleEnglish: "",
  startTime: "",
  endTime: "",
  startDates: [],
  endDates: [],
  repetitive: false
};

export const EMAIL_INITIAL_VALUES: EmailFormData = {
  email: ""
};

export const PASSWORD_INITIAL_VALUES: PasswordFormData = {
  confirmPassword: "",
  password: ""
};

export const CREATE_REVIEW_INITIAL_VALUES: CreateReviewFormData = {
  text: ""
};
