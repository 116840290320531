import UserDetailsDTO from "../../api/models/UserDetailsDTO";

export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const LOGOUT = "LOGOUT";
export const SAVE_PICTURE = "SAVE_PICTURE";

interface saveUserDetails {
  type: typeof SAVE_USER_DETAILS;
  payload: UserDetailsDTO;
}

interface savePicture {
  type: typeof SAVE_PICTURE;
  payload: string;
}

interface logout {
  type: typeof LOGOUT;
  payload?: undefined;
}

export type userDetailsActionTypes = saveUserDetails | savePicture | logout;
