import { ReactElement, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  MainContentContainer,
  MainTitle
} from "../../shared/layout/LayoutElements";
import ReportCardProps from "../../../api/models/ActionReportDetailsDTO";
import { useTranslation } from "react-i18next";
import { TableColumnContainer } from "./ReportElements";
import GraphDialog from "./GraphDialog";
import { MButton } from "../../shared/buttons/ButtonElements";
import { getIntlDateString } from "../../../utils/dateTimeUtils";

const ReportCard = ({
  userReportDetailsDTOSet,
  genderGroups,
  ageGroups,
  totalWorkHours,
  title,
  totalActionsInPeriod
}: ReportCardProps): ReactElement => {
  const [showGraphsDialog, setShowGraphsDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <GraphDialog
        open={showGraphsDialog}
        setOpen={setShowGraphsDialog}
        genderGroups={genderGroups}
        ageGroups={ageGroups}
      />
      <MainContentContainer>
        <MainTitle>{title}</MainTitle>
        <Typography variant="subtitle1">
          <strong>{t("totalWorkHours")}</strong>: {totalWorkHours}
        </Typography>
        {totalActionsInPeriod && (
          <Typography variant="subtitle1">
            <strong>{t("totalActions")}</strong>: {totalActionsInPeriod}
          </Typography>
        )}
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="subtitle1">
              <strong>{t("details")}</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableColumnContainer>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("ageGroup")}</TableCell>
                      <TableCell align="right">
                        {t("numberOfVolunteers")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ageGroups.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {t(row.name)}
                        </TableCell>
                        <TableCell align="right">{row.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("gender")}</TableCell>
                      <TableCell align="right">
                        {t("numberOfVolunteers")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {genderGroups.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {t(row.name)}
                        </TableCell>
                        <TableCell align="right">{row.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("firstName")}</TableCell>
                      <TableCell>{t("lastName")}</TableCell>
                      <TableCell>{t("email")}</TableCell>
                      <TableCell>{t("gender")}</TableCell>
                      <TableCell>{t("dateOfBirth")}</TableCell>
                      <TableCell>{t("workHours")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userReportDetailsDTOSet.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.firstName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.lastName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.email}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.gender == null ? t("unknown") : row.gender}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.dateOfBirth == null
                            ? t("unknown")
                            : getIntlDateString(row.dateOfBirth)}
                        </TableCell>
                        <TableCell align="right">{row.workingHours}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <MButton
                color={"primary"}
                onClick={() => setShowGraphsDialog(true)}
              >
                {t("showGraphs")}
              </MButton>
            </TableColumnContainer>
          </AccordionDetails>
        </Accordion>
      </MainContentContainer>
    </>
  );
};

export default ReportCard;
