import { User } from "../../../pages/ActionDetails/models/ActionDetailsDTO";
import { ReactElement } from "react";
import {
  CategoryMini,
  UserDetailsContainer,
  UserDetailsNameText,
  UserDetailsNoMargin,
  UserDetailsRoleText,
  UserDetailsRowContainer,
  UserIconContainer,
  UserListContainer
} from "./ActionDetailsElements";
import { Person } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Tooltip } from "@material-ui/core";

interface UserListProps {
  confirmedUsers: User[];
}

interface UserProp {
  user: User;
}

const UserList = ({ confirmedUsers }: UserListProps): ReactElement => {
  return (
    <UserListContainer>
      {confirmedUsers.map((user) => (
        <UserComponent key={uuid()} user={user} />
      ))}
    </UserListContainer>
  );
};

const getUserPerkString = (user: User): string => {
  if (user.perks.length == 0) return "";
  return (
    user.perks[user.perks.length - 1].name +
    (user.perks.length > 1 ? ` + ${user.perks.length - 1}` : "")
  );
};

const UserComponent = ({ user }: UserProp): ReactElement => {
  const { t } = useTranslation();

  return (
    <UserDetailsContainer>
      <UserDetailsRowContainer>
        <UserIconContainer>
          <Person />
          <UserDetailsRoleText coordinator={user.coordinator}>
            {user.coordinator ? t("coordinator") : t("volunteer")}
          </UserDetailsRoleText>
        </UserIconContainer>
        <Tooltip title={user.perks.map((perk) => perk.name).join(", ")}>
          <CategoryMini>{getUserPerkString(user)}</CategoryMini>
        </Tooltip>
      </UserDetailsRowContainer>
      <UserDetailsRowContainer>
        <UserDetailsNameText>
          {user.firstName + " " + user.lastName}
        </UserDetailsNameText>
        <UserDetailsNoMargin> - </UserDetailsNoMargin>
        <UserDetailsNoMargin>{user.phone}</UserDetailsNoMargin>
      </UserDetailsRowContainer>
    </UserDetailsContainer>
  );
};
export default UserList;
