import { ReactElement } from "react";
import { Dialog, Paper } from "@material-ui/core";
import GraphDialogProps from "./models/GraphDialogProps";
import { Cell, Pie, PieChart } from "recharts";
import { GraphContainer } from "./ReportElements";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import {
  PI_CHART_HEIGHT,
  PI_CHART_WIDTH,
  NOT_EMPTY_PREDICATE,
  PI_CHART_COLORS
} from "../../../constants/reportConstants";

import { Scrollbars } from "react-custom-scrollbars-2";

const GraphDialog = ({
  open,
  setOpen,
  genderGroups,
  ageGroups
}: GraphDialogProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} PaperComponent={Paper} onClose={() => setOpen(false)}>
      <Scrollbars autoHeight autoHeightMin={450}>
        <GraphContainer>
          <PieChart
            key={uuid()}
            width={PI_CHART_WIDTH}
            height={PI_CHART_HEIGHT}
          >
            <Pie
              data={genderGroups.filter(NOT_EMPTY_PREDICATE)}
              dataKey="quantity"
              nameKey="name"
              cx="50%"
              cy="50%"
              key={uuid()}
              outerRadius={50}
              label={(value) => `${t(value.name)} (${value.quantity})`}
            >
              {genderGroups.filter(NOT_EMPTY_PREDICATE).map((entry, index) => (
                <Cell
                  fill={PI_CHART_COLORS[index % PI_CHART_COLORS.length]}
                  key={uuid()}
                />
              ))}
            </Pie>
          </PieChart>
          <PieChart
            width={PI_CHART_WIDTH}
            height={PI_CHART_HEIGHT}
            key={uuid()}
          >
            <Pie
              data={ageGroups.filter(NOT_EMPTY_PREDICATE)}
              dataKey="quantity"
              nameKey="a"
              cx="50%"
              cy="50%"
              outerRadius={50}
              key={uuid()}
              label={(value) => `${t(value.name)} (${value.quantity})`}
            >
              {ageGroups.filter(NOT_EMPTY_PREDICATE).map((entry, index) => (
                <Cell
                  fill={PI_CHART_COLORS[index % PI_CHART_COLORS.length]}
                  key={uuid()}
                />
              ))}
            </Pie>
          </PieChart>
        </GraphContainer>
      </Scrollbars>
    </Dialog>
  );
};

export default GraphDialog;
