import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import {
  InfoContainerDrawer,
  UserName,
  UserRole
} from "../../app/NavBar/SideDrawer/SideDrawerElements";
import RoleGetter from "../action/RoleGetter";
import { ReactElement } from "react";

const NameRole = (): ReactElement => {
  const { user } = useAppSelector((state) => state.UserState);
  return (
    <InfoContainerDrawer>
      <UserName>{`${user?.firstName} ${user?.lastName}`}</UserName>
      <UserRole>
        <RoleGetter />
      </UserRole>
    </InfoContainerDrawer>
  );
};

export default NameRole;
