import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { DELETE_DOCUMENT_BY_ID_URL } from "./endpoints";

export const deleteDocumentByIdParams = (
  fileName: string,
  userId: string
): AxiosRequestConfig =>
  ({
    url: DELETE_DOCUMENT_BY_ID_URL(userId, fileName),
    method: "DELETE"
  } as AxiosRequestConfig);

export const deleteDocumentById = async (
  fileName: string,
  userId: string
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(
    deleteDocumentByIdParams(fileName, userId)
  );
};
