import { useInViewAnimate } from "framer-motion-hooks";
import { ReactElement } from "react";
import { hideVariant } from "../../../constants/animationVariantConstants";
import {
  HeroInfoBody,
  HeroInfoLeftAlignContainer,
  HeroInfoRightAlignContainer,
  HeroInfoTitle
} from "./HeroPageElements";
import { HeroInfoProps } from "./models/HeroInfoProps";

const HeroInfoContainer = ({
  id,
  title,
  body,
  alignRight
}: HeroInfoProps): ReactElement => {
  const { inViewRef, animation } = useInViewAnimate({ animate: "visible" });

  const renderLeft = () => {
    return (
      <>
        <HeroInfoLeftAlignContainer
          initial="hidden"
          ref={inViewRef}
          animate={animation}
          variants={hideVariant}
          id={id}
        >
          <HeroInfoTitle>{title}</HeroInfoTitle>
          <HeroInfoBody>{body}</HeroInfoBody>
        </HeroInfoLeftAlignContainer>
      </>
    );
  };
  const renderRight = () => {
    return (
      <>
        <HeroInfoRightAlignContainer
          initial="hidden"
          ref={inViewRef}
          animate={animation}
          variants={hideVariant}
          id={id}
        >
          <HeroInfoTitle>{title}</HeroInfoTitle>
          <HeroInfoBody>{body}</HeroInfoBody>
        </HeroInfoRightAlignContainer>
      </>
    );
  };
  return <>{alignRight ? renderRight() : renderLeft()}</>;
};

export default HeroInfoContainer;
