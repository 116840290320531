import ActionDetailsDTO, {
  Perk
} from "../../../pages/ActionDetails/models/ActionDetailsDTO";
import ActionDTO from "../../../pages/ActionFeed/models/ActionDTO";

export const userContainsPerk = (userPerks: Perk[], perk: Perk): boolean => {
  for (let userPerk of userPerks) {
    if (userPerk.id == perk.id) return true;
  }
  return false;
};

export const filterFilledPerks = (action: ActionDetailsDTO): Perk[] => {
  if (action) {
    return action.requirements
      .filter(
        (requirement) =>
          action.confirmedUsers.filter((confirmedUser) =>
            userContainsPerk(confirmedUser.perks, requirement.perk)
          ).length < requirement.quantity
      )
      .map((requirement) => requirement.perk);
  } else return [];
};

export const actionContainsPerk = (action: ActionDTO, perk: Perk): boolean => {
  for (let actionPerk of action.requirements.map(
    (requirement) => requirement.perk
  )) {
    if (actionPerk.id == perk.id) return true;
  }
  return false;
};
