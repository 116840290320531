import { Typography } from "@material-ui/core";
import LockOpenRounded from "@material-ui/icons/LockOpenRounded";
import { MButtonMaxWidth } from "../../shared/buttons/ButtonElements";
import {
  FlexSBContainer,
  MForm,
  SignWrapper
} from "../../shared/layout/LayoutElements";
import {
  AnchorLink,
  IconContainer,
  IconHeaderContainer
} from "../Login/FormElements";
import Copyright from "../../shared/Copyright";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Formik, FormikHelpers, FormikProps } from "formik";

import * as yup from "yup";
import { PASSWORD_INITIAL_VALUES } from "../../../constants/formInitialValues";
import PasswordFormData from "./models/PasswordFormData";
import { changePasswordEnterPassword } from "../../../api/changePasswordEnterPassword";
import RestartPasswordParams from "../../../pages/RestartPassword/models/RestartPasswordParams";
import ErrorInfo from "../../../constants/ErrorInfo";
import FeedbackMessagePageProps from "../../../pages/FeedbackMessagePage/models/FeedbackMessagePageProps";
import InputField from "../../shared/formFields/InputField";
import { REGEX_PASSWORD } from "../../../constants/regexConstants";

const EnterPassword = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const { id } = useParams<RestartPasswordParams>();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, t("validPasswordLength"))
      .max(64, t("maxLength", { count: 64 }))
      .matches(REGEX_PASSWORD, t("validPasswordFormat"))
      .required(t("required")),
    confirmPassword: yup
      .string()
      .required(t("required"))
      .oneOf([yup.ref("password"), null], t("validPasswordMatch"))
  });

  const onSubmit = async (
    { password }: PasswordFormData,
    { setSubmitting }: FormikHelpers<PasswordFormData>
  ) => {
    if (id) {
      changePasswordEnterPassword({ password, token: id })
        .then(() => {
          history.push("/informacija", {
            feedbackMessageKey: "passwordResetSuccess"
          } as FeedbackMessagePageProps);
        })
        .catch((error) => {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        });
    } else {
      history.push("/greska");
    }
    setSubmitting(false);
  };

  return (
    <>
      <SignWrapper>
        <IconHeaderContainer>
          <IconContainer>
            <LockOpenRounded />
          </IconContainer>
          <Typography variant="h5">{t("enterNewPassword")}</Typography>
        </IconHeaderContainer>
        <Formik
          initialValues={PASSWORD_INITIAL_VALUES}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange
          }: FormikProps<PasswordFormData>) => (
            <>
              <MForm onSubmit={handleSubmit} onChange={handleChange}>
                <InputField
                  variant="filled"
                  label={t("password")}
                  fullWidth
                  type="password"
                  name="password"
                />
                <InputField
                  variant="filled"
                  label={t("confirmPassword")}
                  fullWidth
                  type="password"
                  name="confirmPassword"
                />
                <MButtonMaxWidth
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                  type="submit"
                  name="submit"
                >
                  {t("confirm")}
                </MButtonMaxWidth>
              </MForm>
            </>
          )}
        </Formik>
        <FlexSBContainer>
          <AnchorLink to="prijava">
            <Typography variant="body2">{t("backToLogin")}</Typography>
          </AnchorLink>
        </FlexSBContainer>
        <Copyright />
      </SignWrapper>
    </>
  );
};
export default EnterPassword;
