import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATION_EN } from "./en/translations";
import { TRANSLATION_HR } from "./hr/translations";

// main config file for i18n

i18next.use(initReactI18next).init({
  fallbackLng: "hr",
  resources: {
    // translations for each language
    en: {
      translation: TRANSLATION_EN
    },
    hr: {
      translation: TRANSLATION_HR
    }
  }
});

export default i18next;
