export const mTranPurple = "rgba(59, 18, 100, 0.28)";
export const white = "#fff";
export const mTranBlack = "#00000035";
export const mDarkGreen = "rgba(25, 158, 25, 0.18)";
export const mBlackGreen = "#003b00";
export const mPrimary = "#199e19";
export const mTranPrimary = "#69ff6969";
export const mLightGreen = "#6AF74F";
export const mSecondary = "#394441";
export const mTranSecondary = "#39444169";
export const mWarning = "#FF6B00";
export const mWhiteOrange = "#fff1e6";
export const mError = "#FFB74D";
export const mLightBlue = "#64b5f6";
