import { NotificationDTO } from "../../api/models/NotificationDTO";
import { ADD, notificationActionType, REMOVE } from "./notificationActionType";

export const createNotification = (
  notification: NotificationDTO
): notificationActionType => ({
  type: ADD,
  payload: notification
});

/**
 *
 * @param index index (within the stored array) of the notification to remove.
 */
export const deleteNotification = (index: number): notificationActionType => ({
  type: REMOVE,
  payload: index
});
