import { VOLUNTEER_LIST_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import VolunteerListDTO from "../components/app/VolunteersList/models/VolunteerListDTO";

export const getVolunteersParams = (): AxiosRequestConfig =>
  ({
    url: VOLUNTEER_LIST_URL,
    method: "GET"
  } as AxiosRequestConfig);

export const getVolunteers = async (): Promise<
  AxiosResponse<VolunteerListDTO>
> => {
  return await axiosInstance.request(getVolunteersParams());
};
