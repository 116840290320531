import userDetailsReducer from "./reducers/userDetailsReducer";
import storage from "redux-persist/lib/storage";
import { persistCombineReducers } from "redux-persist";
import notificationReducer from "./reducers/notificationReducer";

const persistConfig = {
  key: "root",
  storage
};

export default persistCombineReducers(persistConfig, {
  UserState: userDetailsReducer,
  NotificationState: notificationReducer
});
