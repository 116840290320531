import LocationOnIcon from "@material-ui/icons/LocationOn";
import { ReactElement } from "react";
import { ActionLocation } from "../action/ActionSharedElements";
import { LocationTitle } from "./LocationLabelElements";
import LocationLabelProps from "./models/LocationLabelProps";

const LocationLabel = ({ location }: LocationLabelProps): ReactElement => {
  return (
    <ActionLocation>
      <LocationOnIcon className="whiteIcon" />
      <LocationTitle>{location}</LocationTitle>
    </ActionLocation>
  );
};

export default LocationLabel;
