import {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState
} from "react";
import UserActionsProps from "./models/UserActionsProps";
import { getActiveActionsForUser } from "../../../api/getActiveActionsForUser";
import { getFinishedActionsForUser } from "../../../api/getFinishedActionsForUser";
import { getArchivedActionsForUser } from "../../../api/getArchivedActionsForUser";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import BasicActionDetailsDTO from "../../../api/models/BasicActionDetailsDTO";
import BasicActionTile from "./BasicActionTile";
import { useTranslation } from "react-i18next";
import { MButton, StyledPagination } from "../../shared/buttons/ButtonElements";
import { NavigationContainer } from "./UserActionsElements";
import { ButtonGroup } from "@material-ui/core";
import {
  PAGE_SIZE,
  REQUIRED_ACTIONS_ON_SCREEN_FOR_BOTTOM_PAGINATION
} from "../../../constants/paginationConstants";

const UserActions = ({ id }: UserActionsProps): ReactElement => {
  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  const [activeActions, setActiveActions] = useState<BasicActionDetailsDTO[]>(
    []
  );
  const [finishedeActions, setFinishedActions] = useState<
    BasicActionDetailsDTO[]
  >([]);
  const [archivedActions, setArchivedActions] = useState<
    BasicActionDetailsDTO[]
  >([]);
  const [tabValue, setTabValue] = useState(1);
  const [activeActionsPage, setActiveActionsPage] = useState(1);
  const [finishedActionsPage, setFinishedActionsPage] = useState(1);
  const [archivedActionsPage, setArchivedActionsPage] = useState(1);
  const [needToUpdateActions, setNeedToUpdateActions] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (needToUpdateActions) {
      updateActions();
      setNeedToUpdateActions(false);
    }
  }, [needToUpdateActions]);

  useEffect(() => {
    setNeedToUpdateActions(true);
  }, [t]);

  const updateActions = (): void => {
    getActiveActionsForUser(id)
      .then((res) => setActiveActions(res.data))
      .catch(() => setSnackbarErrorMessageKey("downloadFailure"));
    getFinishedActionsForUser(id)
      .then((res) => setFinishedActions(res.data))
      .catch(() => setSnackbarErrorMessageKey("downloadFailure"));
    getArchivedActionsForUser(id)
      .then((res) => setArchivedActions(res.data))
      .catch(() => setSnackbarErrorMessageKey("downloadFailure"));
  };

  const getSelectedActions = (): BasicActionDetailsDTO[] => {
    if (tabValue == 1) {
      return activeActions;
    } else if (tabValue == 2) {
      return finishedeActions;
    }
    return archivedActions;
  };

  const getPageCount = (): number => {
    let selectedActions = getSelectedActions();
    if (selectedActions.length == 0) {
      return 1;
    }
    return Math.ceil(selectedActions.length / PAGE_SIZE);
  };

  const getActivePageNumber = (): number => {
    if (tabValue == 1) {
      return activeActionsPage;
    } else if (tabValue == 2) {
      return finishedActionsPage;
    }
    return archivedActionsPage;
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    if (tabValue == 1) {
      setActiveActionsPage(value);
    } else if (tabValue == 2) {
      setFinishedActionsPage(value);
    } else {
      setArchivedActionsPage(value);
    }
  };

  const getCountOfActionsOnCurrentPage = (): number => {
    let pageNumber = getActivePageNumber();
    let pageCount = getPageCount();

    if (pageNumber < pageCount) {
      return PAGE_SIZE;
    }

    return getSelectedActions().length - (pageNumber - 1) * PAGE_SIZE;
  };

  return (
    <>
      <NavigationContainer>
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          <MButton
            onClick={() => setTabValue(1)}
            color={tabValue == 1 ? "primary" : "secondary"}
          >
            {t("active")}
          </MButton>
          <MButton
            onClick={() => setTabValue(2)}
            color={tabValue == 2 ? "primary" : "secondary"}
          >
            {t("finished")}
          </MButton>
          <MButton
            onClick={() => setTabValue(3)}
            color={tabValue == 3 ? "primary" : "secondary"}
          >
            {t("archived")}
          </MButton>
        </ButtonGroup>
        <StyledPagination
          count={getPageCount()}
          page={getActivePageNumber()}
          onChange={handlePageChange}
        />
      </NavigationContainer>

      {tabValue == 1 &&
        activeActions
          .slice(
            (getActivePageNumber() - 1) * PAGE_SIZE,
            getActivePageNumber() * PAGE_SIZE
          )
          .map((basicActionDetails) => (
            <BasicActionTile
              basicActionDetailsDTO={basicActionDetails}
              setNeedToUpdateActions={setNeedToUpdateActions}
              key={basicActionDetails.id}
            />
          ))}
      {tabValue == 2 &&
        finishedeActions
          .slice(
            (getActivePageNumber() - 1) * PAGE_SIZE,
            getActivePageNumber() * PAGE_SIZE
          )
          .map((basicActionDetails) => (
            <BasicActionTile
              basicActionDetailsDTO={basicActionDetails}
              setNeedToUpdateActions={setNeedToUpdateActions}
              key={basicActionDetails.id}
            />
          ))}
      {tabValue == 3 &&
        archivedActions
          .slice(
            (getActivePageNumber() - 1) * PAGE_SIZE,
            getActivePageNumber() * PAGE_SIZE
          )
          .map((basicActionDetails) => (
            <BasicActionTile
              basicActionDetailsDTO={basicActionDetails}
              setNeedToUpdateActions={setNeedToUpdateActions}
              key={basicActionDetails.id}
            />
          ))}
      {getCountOfActionsOnCurrentPage() >=
        REQUIRED_ACTIONS_ON_SCREEN_FOR_BOTTOM_PAGINATION && (
        <StyledPagination
          count={getPageCount()}
          page={getActivePageNumber()}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};

export default UserActions;
