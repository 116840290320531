import ActionFormStepProps from "./models/ActionFormStepProps";
import { ReactElement } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import ErrorPage from "../../../pages/ErrorPage";

const ActionFormStep = ({ step }: ActionFormStepProps): ReactElement => {
  switch (step) {
    case 0:
      return <StepOne />;
    case 1:
      return <StepTwo />;
    case 2:
      return <StepThree />;
    case 3:
      return <StepFour />;
    default:
      return <ErrorPage />;
  }
};

export default ActionFormStep;
