import { ProfileWizardStep3Data } from "../components/app/ProfileWizard/models/ProfileWizardData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GET_ADMIN_INTERESTS_BY_ID_URL } from "./endpoints";
import UserDetailsDTO from "./models/UserDetailsDTO";
import axiosInstance from "./axiosInstance";

const interestsParams = (
  body: ProfileWizardStep3Data,
  id: string
): AxiosRequestConfig =>
  ({
    url: GET_ADMIN_INTERESTS_BY_ID_URL(id),
    method: "PATCH",
    data: body
  } as AxiosRequestConfig);

export const adminPostInterests = async (
  body: ProfileWizardStep3Data,
  id: string
): Promise<AxiosResponse<UserDetailsDTO>> =>
  await axiosInstance.request(interestsParams(body, id));
