import { ReactElement } from "react";
import useLanguagePicker from "../../../hooks/useLanguagePicker";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { MenuItem } from "@material-ui/core";
import LanguageOption from "../LanguageOption";
import LanguageCode from "../../../locale/types/LanguageCode";
import { LanguagePickerContainer, MSelect } from "./LanguagePickerContainer";

/**
 * Language picker used to easily choose the language of the application.
 *
 * When a language is chosen, all translatable elements of the user interface are updated appropriately.
 */
const LanguagePicker = (): ReactElement => {
  const [language, setLanguage, supportedLanguages] = useLanguagePicker();

  return (
    <LanguagePickerContainer>
      <MSelect
        disableUnderline
        IconComponent={KeyboardArrowDownIcon}
        value={language}
        defaultValue={language}
        onChange={(event) => setLanguage(event.target.value as LanguageCode)}
      >
        {Object.keys(supportedLanguages).map((value) => {
          return (
            <MenuItem key={value} value={value}>
              <LanguageOption languageCode={value as LanguageCode} />
            </MenuItem>
          );
        })}
      </MSelect>
    </LanguagePickerContainer>
  );
};
export default LanguagePicker;
