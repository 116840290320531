import { ReactElement } from "react";
import DetailSpanProps from "./models/DetailSpanProps";
import {
  DetailSpanContainer,
  DetailTextContent,
  DetailTextTitle
} from "./VolunteerListElements";

const DetailSpan = ({ title, content }: DetailSpanProps): ReactElement => {
  return (
    <DetailSpanContainer>
      <DetailTextTitle>{title}</DetailTextTitle>
      <DetailTextContent>{content}</DetailTextContent>
    </DetailSpanContainer>
  );
};

export default DetailSpan;
