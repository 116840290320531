import { ReactElement, useContext, useState } from "react";
import DocumentListCore from "../../shared/DocumentListCore";
import fileDownload from "js-file-download";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import DocumentNoFileDTO from "../../shared/DocumentListCore/models/DocumentNoFileDTO";
import { ForeignDocumentListProps } from "./models/ForeignDocumentListProps";
import { getUserDetailsById } from "../../../api/getUserDetailsById";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import { getDocumentById } from "../../../api/getDocumentById";
import { deleteDocumentById } from "../../../api/deleteDocumentById";
import DocumentDTO from "../../shared/DocumentListCore/models/DocumentDTO";
import { uploadDocumentById } from "../../../api/uploadDocumentById";
import Role from "../../../constants/roles";

const ForeignDocumentList = ({
  userId
}: ForeignDocumentListProps): ReactElement => {
  const { user } = useAppSelector((state) => state.UserState);
  const [documentTitles, setDocumentTitles] = useState<DocumentNoFileDTO[]>([]);
  const [targetUserRoles, setTargetUserRoles] = useState<Role[]>([]);
  const [needUpdate, setNeedUpdate] = useState<boolean>(true);

  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);

  const fetchDocuments = () => {
    getUserDetailsById(userId)
      .then((res) => {
        setDocumentTitles(res.data.documents);
        setTargetUserRoles(res.data.roles);
      })
      .catch(() => {
        setSnackbarErrorMessageKey("downloadFailure");
      });
  };

  const downloadDocumentFile = (fileName: string) => {
    getDocumentById(fileName, userId)
      .then((res) => {
        let finalFileName = fileName.endsWith(".pdf")
          ? fileName
          : `${fileName}.pdf`;
        fileDownload(res.data, finalFileName);
      })
      .catch(() => setSnackbarErrorMessageKey("downloadFailure"));
  };

  const deleteDocumentFile = (fileName: string) => {
    deleteDocumentById(fileName, userId)
      .then(() => {
        setNeedUpdate(true);
      })
      .catch(() => setSnackbarErrorMessageKey("documentDeleteFailure"));
  };

  const uploadDocument = (documentDTO: DocumentDTO) => {
    uploadDocumentById(documentDTO, userId)
      .then(() => {
        setNeedUpdate(true);
      })
      .catch(() => {
        setSnackbarErrorMessageKey("uploadDocumentFailure");
      });
  };

  const userHasEditingRights = () => {
    return (
      (user?.roles?.includes(Role.ADMINISTRATOR) &&
        !targetUserRoles.includes(Role.ADMINISTRATOR)) ||
      false
    );
  };

  return (
    <DocumentListCore
      documentTitles={documentTitles}
      deleteDocument={deleteDocumentFile}
      canDelete={userHasEditingRights()}
      canApprove={userHasEditingRights()}
      fetchDocuments={fetchDocuments}
      needUpdate={needUpdate}
      setNeedUpdate={setNeedUpdate}
      uploadDocument={uploadDocument}
      downloadDocument={downloadDocumentFile}
      userId={userId}
    />
  );
};

export default ForeignDocumentList;
