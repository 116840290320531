import { TextField, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { ReactElement } from "react";
import RegisterFormData from "../../../pages/Register/models/RegisterFormData";
import {
  MButtonMaxWidth,
  MButtonWide
} from "../../shared/buttons/ButtonElements";
import Copyright from "../../shared/Copyright";
import {
  ColmAlignLeftContainer,
  FlexSBContainer,
  SignWrapper
} from "../../shared/layout/LayoutElements";
import { AnchorLink } from "../Login/FormElements";
import { useTranslation } from "react-i18next";
import StepTwoProps from "./models/StepTwoProps";

const StepTwo = ({ backStep }: StepTwoProps): ReactElement => {
  const { errors, isSubmitting } = useFormikContext<RegisterFormData>();
  const { t } = useTranslation();
  return (
    <SignWrapper>
      <ColmAlignLeftContainer>
        <Typography variant="subtitle1">
          {t("step")} <strong>2</strong> {t("of")} <strong>2</strong>
        </Typography>
        <Typography variant="h5">{t("protectYourself")}</Typography>
        <Typography variant="subtitle2">{t("youFly")}</Typography>
      </ColmAlignLeftContainer>
      <TextField
        variant="filled"
        label={t("email")}
        fullWidth
        name="email"
        error={errors.email != null}
        helperText={errors.email}
      />
      <TextField
        variant="filled"
        label={t("password")}
        fullWidth
        name="password"
        error={errors.password != null}
        helperText={errors.password}
        type="password"
      />
      <TextField
        variant="filled"
        label={t("repeatPassword")}
        fullWidth
        name="passwordRepeat"
        error={errors.passwordRepeat != null}
        helperText={errors.passwordRepeat}
        type="password"
      />
      <FlexSBContainer>
        <MButtonWide variant="contained" color="secondary" onClick={backStep}>
          {t("goBack")}
        </MButtonWide>
        <MButtonMaxWidth
          type="submit"
          name="submit"
          variant="contained"
          color="secondary"
          disabled={isSubmitting}
        >
          {t("registerAttempt")}
        </MButtonMaxWidth>
      </FlexSBContainer>
      <AnchorLink to="/prijava">
        <Typography variant="body2">{t("loginHint")}</Typography>
      </AnchorLink>
      <Copyright />
    </SignWrapper>
  );
};

export default StepTwo;
