import { DELETE_PERK_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export const deletePerkParams = (id: number): AxiosRequestConfig =>
  ({
    url: DELETE_PERK_URL(id),
    method: "DELETE"
  } as AxiosRequestConfig);

export const deletePerk = async (id: number): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(deletePerkParams(id));
};
