import { ListItemIcon } from "@material-ui/core";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ProfileLink from "./ProfileLink";
import { ReactElement } from "react";
import { LinkButtonContainer } from "../buttons/ButtonElements";

const ProfileLinkArray = (): ReactElement => {
  return (
    <>
      <LinkButtonContainer>
        <ProfileLink>
          <ListItemIcon>
            <AccountCircleRoundedIcon />
          </ListItemIcon>
        </ProfileLink>
      </LinkButtonContainer>
    </>
  );
};

export default ProfileLinkArray;
