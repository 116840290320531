import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ACTION_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import ActionDetailsDTO from "../pages/ActionDetails/models/ActionDetailsDTO";
import PatchActionDTO from "./models/PatchActionDTO";

export const patchActionParams = (
  patchActionDTO: PatchActionDTO,
  actionId: number
): AxiosRequestConfig =>
  ({
    url: ACTION_URL(actionId),
    method: "PATCH",
    data: patchActionDTO
  } as AxiosRequestConfig);

export const patchAction = async (
  patchActionDTO: PatchActionDTO,
  actionId: number
): Promise<AxiosResponse<ActionDetailsDTO>> => {
  return await axiosInstance.request(
    patchActionParams(patchActionDTO, actionId)
  );
};
