import EmailFormData from "../components/app/RestartPassword/models/EmailFormData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { CHANGE_PASSWORD_EMAIL_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";

const changePasswordEmailParams = (body: EmailFormData): AxiosRequestConfig =>
  ({
    url: CHANGE_PASSWORD_EMAIL_URL,
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const changePasswordEnterEmail = async (
  body: EmailFormData
): Promise<AxiosResponse<void>> =>
  await axiosInstance.request(changePasswordEmailParams(body));
