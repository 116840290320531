import styled, { css } from "styled-components";
import {
  mPrimary,
  mSecondary,
  mWarning,
  white
} from "../../../constants/colorPallete";

export const CategoryListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

interface CategoryContainerProps {
  isSelected?: boolean;
}

export const CategoryContainer = styled.div<CategoryContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 18px;
  background-color: ${mPrimary};
  color: ${white};
  font-weight: bold;
  height: 32px;
  font-size: 15px;
  gap: 5px;
  padding: 5px 10px 5px 10px;
  margin: 5px;
  cursor: pointer;
  :hover {
    background-color: ${mWarning};
  }
  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${mSecondary}; ;
    `}
`;

export const FirstRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;
