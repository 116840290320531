import { Dialog } from "@material-ui/core";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import EditIcon from "@material-ui/icons/Edit";
import LibraryBooksRoundedIcon from "@material-ui/icons/LibraryBooksRounded";
import ThumbsUpDownRoundedIcon from "@material-ui/icons/ThumbsUpDownRounded";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { getUserDetailsById } from "../../../api/getUserDetailsById";
import UserDetailsDTO from "../../../api/models/UserDetailsDTO";
import ProfileParams from "../../../pages/Profile/models/ProfileParams";
import { useAppSelector } from "../../../store/reducers/userDetailsReducer";
import FabReactive from "../../shared/FabReactive";
import {
  ButtonContainer,
  FlexSBContainer,
  MainContentContainer
} from "../../shared/layout/LayoutElements";
import ProfilePictureEditor from "../AvatarEditor";
import ForeignProfilePictureEditor from "../ForeignAvatarEditor";
import ProfileWizard from "../ProfileWizard";
import { MWizardPaper } from "../ProfileWizard/ProfileWizardElements";
import MTabs from "../Tabs";
import TabInfoProps from "../Tabs/models/TabInfoProps";
import AboutMe from "./AboutMe";
import InterestsTab from "./InterestsTab";
import {
  BasicInfoContainer,
  ProfileName,
  ProfilePictureWrapper,
  TabContainer
} from "./ProfileInfoElements";
import SkillsTab from "./SkillsTab";
import Role from "../../../constants/roles";
import { MButton } from "../../shared/buttons/ButtonElements";

const ProfileInfo = (): ReactElement => {
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const tabs: TabInfoProps[] = [
    { label: t("aboutMe"), icon: <LibraryBooksRoundedIcon /> },
    { label: t("skills"), icon: <BuildRoundedIcon /> },
    { label: t("interests"), icon: <ThumbsUpDownRoundedIcon /> }
  ];

  const { user } = useAppSelector((state) => state.UserState);
  const [foreignUserInfo, setForeignUserInfo] = useState<UserDetailsDTO>();
  const { id } = useParams<ProfileParams>();

  useEffect(() => {
    if (id) {
      getUserDetailsById(id).then((res) => {
        setForeignUserInfo(res.data);
      });
    }
  }, []);

  const openProfileWizard = () => {
    setOpenDialog(!openDialog);
  };
  const closeProfileWizard = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <Dialog
        onClose={(event, reason) => {
          //we dont let user exit by clicking away. (easy mistake when putting in data)
          if (reason !== "backdropClick") {
            closeProfileWizard();
          }
        }}
        open={openDialog}
        scroll="body"
        PaperComponent={MWizardPaper}
        disableEscapeKeyDown
      >
        <ProfileWizard close={closeProfileWizard} />
      </Dialog>
      <MainContentContainer>
        <FlexSBContainer>
          <BasicInfoContainer>
            <ProfilePictureWrapper>
              {id ? (
                <ForeignProfilePictureEditor userId={id as string} />
              ) : (
                <ProfilePictureEditor />
              )}
            </ProfilePictureWrapper>
            <ProfileName>
              {id
                ? `${foreignUserInfo?.firstName} ${foreignUserInfo?.lastName} `
                : `${user?.firstName} ${user?.lastName}`}
            </ProfileName>
          </BasicInfoContainer>
          {(!id || user?.roles.includes(Role.ADMINISTRATOR)) && (
            <ButtonContainer>
              <FabReactive
                icon={<EditIcon style={{ fontSize: "18px" }} />}
                label={t("edit")}
                onClick={openProfileWizard}
              />
            </ButtonContainer>
          )}
        </FlexSBContainer>
        <TabContainer>
          <MTabs tabInfo={tabs}>
            {id ? <AboutMe user={foreignUserInfo} /> : <AboutMe user={user} />}
            {id ? (
              <SkillsTab user={foreignUserInfo} />
            ) : (
              <SkillsTab user={user} />
            )}
            {id ? (
              <InterestsTab user={foreignUserInfo} />
            ) : (
              <InterestsTab user={user} />
            )}
          </MTabs>
        </TabContainer>
        {id && (
          <MButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.push(`/korisnik/akcije/${id}`)}
          >
            {t("viewUserActions")}
          </MButton>
        )}
      </MainContentContainer>
    </>
  );
};

export default ProfileInfo;
