import { DOCUMENT_CATEGORIES_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import i18n from "../locale/i18n";
import DocumentCategoriesDTO from "../components/app/DocumentList/models/DocumentCategoriesDTO";

const ACCEPT_LANGUAGE_HEADER = "Accept-Language";

export const downloadDocumentParams = (
  languageCode: string
): AxiosRequestConfig =>
  ({
    url: DOCUMENT_CATEGORIES_URL,
    headers: {
      [ACCEPT_LANGUAGE_HEADER]: languageCode
    },
    method: "GET"
  } as AxiosRequestConfig);

export const getDocumentCategories = async (): Promise<
  AxiosResponse<DocumentCategoriesDTO>
> => {
  return await axiosInstance.request(downloadDocumentParams(i18n.language));
};
