import { ReactElement } from "react";
import { ConditionalHOCProps } from "./models/ConditionalHOCProps";

/**
 * Can be used to conditionally render different components, based on
 * the given condition.
 *
 * {@see ConditionalHOCProps}
 */
const ConditionalHOC = ({
  condition,
  onTrue: YesComponent = () => <></>,
  onFalse: NoComponent = () => <></>
}: ConditionalHOCProps): ReactElement => {
  return condition ? <YesComponent /> : <NoComponent />;
};

export default ConditionalHOC;
