import { Route, Switch, useHistory } from "react-router-dom";
import Home from "./pages/Home";
import { ReactElement, useEffect } from "react";
import LoginPage from "./pages/Login";
import RegisterPage from "./pages/Register";
import ActionFeedPage from "./pages/ActionFeed";
import ActionDetailsPage from "./pages/ActionDetails";
import ProfilePage from "./pages/Profile";
import ErrorPage from "./pages/ErrorPage";
import RestartPasswordPage from "./pages/RestartPassword";
import FeedbackMessagePage from "./pages/FeedbackMessagePage";
import Volunteers from "./pages/Volunteers";
import ActionForm from "./pages/ActionForm";
import UserActionsPage from "./pages/UserActionsPage";
import ReportPage from "./pages/ReportPage";
import AuthenticatedRoute from "./hoc/AuthenticatedRoute";
import AuthorizedRoute from "./hoc/AuthorizedRoute";
import Role from "./constants/roles";
import ActionUsersPage from "./pages/ActionUsers";
import { AnimatePresence } from "framer-motion";
import CategoryEdit from "./pages/CategoryEdit";
import useAuth from "./hooks/useAuth";
import { useAppSelector } from "./store/reducers/userDetailsReducer";
import axiosInstance from "./api/axiosInstance";
import { ConfirmEmailPage } from "pages/ConfirmEmail/ConfirmEmail";

const Routes = (): ReactElement => {
  const { handleLogout } = useAuth();
  const history = useHistory();
  const { logged } = useAppSelector((state) => state.UserState);

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (logged && error.response.status === 401) {
          handleLogout();
          history.push("/prijava", {
            snackbarData: {
              severity: "error",
              messageKey: "loginSessionExpired"
            }
          });
        }
        return Promise.reject(error);
      }
    );
  }, [logged]);

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch>
        <Route exact component={Home} path="/" />
        <Route exact component={LoginPage} path="/prijava" />
        <Route exact component={RegisterPage} path="/registracija" />
        <AuthenticatedRoute exact component={ActionFeedPage} path="/akcije" />
        <AuthenticatedRoute exact component={ProfilePage} path="/profil" />
        <Route exact component={ErrorPage} path="/greska" />
        <AuthorizedRoute
          role={Role.ADMINISTRATOR}
          exact
          component={CategoryEdit}
          path="/kategorije"
        />
        <AuthorizedRoute
          role={Role.ADMINISTRATOR}
          exact
          component={ReportPage}
          path="/akcije/izvjestaj"
        />
        <AuthorizedRoute
          role={Role.COORDINATOR}
          exact
          component={Volunteers}
          path="/volonteri"
        />
        <AuthorizedRoute
          role={Role.COORDINATOR}
          exact
          component={ActionForm}
          path="/akcije/nova"
        />
        <AuthenticatedRoute
          exact
          component={ActionDetailsPage}
          path="/akcije/:id"
        />
        <Route exact component={ActionUsersPage} path="/akcije/:id/korisnici" />
        <AuthorizedRoute
          role={Role.COORDINATOR}
          exact
          component={ProfilePage}
          path="/profil/:id"
        />
        <AuthenticatedRoute
          exact
          component={UserActionsPage}
          path="/korisnik/akcije"
        />
        <AuthenticatedRoute
          exact
          component={UserActionsPage}
          path="/korisnik/akcije/:id"
        />
        <Route
          exact
          component={RestartPasswordPage}
          path="/promjena-lozinke/:id?"
        />
        <Route
          exact
          component={ConfirmEmailPage}
          path="/potvrda-email-adrese/:token?"
        />
        <Route exact component={FeedbackMessagePage} path="/informacija" />
        <Route component={ErrorPage} path="*" />
      </Switch>
    </AnimatePresence>
  );
};

export default Routes;
