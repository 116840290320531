import { ReactElement } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ResponsiveHOCProps } from "./models/ResponsiveHOCProps";

/**
 * Responsive HOC which can be used to conditionally render components, based
 * on screen size (width).
 *
 * If any of the components is not passed as a prop, empty component will be
 * used instead. This allows for using the HOC to show/hide a single component,
 * by passing only one component as a prop (on whichever side of the breakpoint)
 *
 * {@see ResponsiveHOCProps}
 */
const ResponsiveHOC = ({
  breakpoint,
  smallScreenComponent: SmallComponent = () => <></>,
  bigScreenComponent: BigComponent = () => <></>
}: ResponsiveHOCProps): ReactElement => {
  const { windowWidth } = useWindowDimensions();

  return windowWidth < breakpoint ? <SmallComponent /> : <BigComponent />;
};

export default ResponsiveHOC;
