import { ReactElement, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateReviewFormData from "./models/CreateReviewFormData";
import { postCreateReview } from "../../../api/createReview";
import ReviewContext from "../ReviewList/ReviewContext";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import ProfileParams from "../../../pages/Profile/models/ProfileParams";
import CreateReviewRequestBody from "../../../api/models/CreateReviewRequestBody";
import { MButton, MButtonContrast } from "../../shared/buttons/ButtonElements";
import AddIcon from "@material-ui/icons/Add";
import FabReactive from "../../shared/FabReactive";

const CreateReviewForm = (): ReactElement => {
  const { t } = useTranslation();

  const { reviews, setReviews } = useContext(ReviewContext);
  const [formDisplayed, setFormDisplayed] = useState(false);
  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  const [reviewText, setReviewText] = useState("");
  const [error, setError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { id } = useParams<ProfileParams>();

  const validationSchema = yup.object().shape({
    text: yup
      .string()
      .required(t("required"))
      .max(400, t("maxLength", { count: 400 }))
  });

  const onSubmit = async (values: CreateReviewFormData) => {
    postCreateReview({ ...values, targetUserId: id } as CreateReviewRequestBody)
      .then((res) => {
        setReviews([...reviews, res.data]);
        setFormDisplayed(false);
      })
      .catch(() => {
        setSnackbarErrorMessageKey("genericError");
      });
  };

  const handleSubmit = () => {
    setButtonDisabled(true);
    setError("");
    validationSchema
      .validate({
        text: reviewText
      } as CreateReviewFormData)
      .then(() => {
        onSubmit({ text: reviewText } as CreateReviewFormData).then(() => {
          setReviewText("");
          setButtonDisabled(false);
        });
      })
      .catch((error) => {
        setError(error.message);
        setButtonDisabled(false);
      });
  };

  const handleClose = () => {
    setFormDisplayed(false);
    setReviewText("");
    setError("");
    setButtonDisabled(false);
  };

  const handleAttemptAddReview = () => {
    setFormDisplayed(true);
    setError("");
  };

  return (
    <>
      <FabReactive
        label={t("addReview")}
        onClick={handleAttemptAddReview}
        icon={<AddIcon />}
      />
      <Dialog
        open={formDisplayed}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{t("addReview")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            name="review"
            label={t("reviewTextLabel")}
            fullWidth
            minRows={3}
            multiline
            variant="filled"
            value={reviewText}
            onChange={(value) => setReviewText(value.target.value)}
            error={!!error}
            helperText={error}
          />
          <DialogActions>
            <MButtonContrast
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={buttonDisabled}
            >
              {t("submitCreateReview")}
            </MButtonContrast>
            <MButton
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              {t("cancelCreateReview")}
            </MButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateReviewForm;
