import styled from "styled-components";
import { Accordion, Button } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { theme } from "../../../styles/style";
import { white } from "../../../constants/colorPallete";

interface userListProps {
  coordinator: boolean;
}

interface colorProps {
  buttonColor: string;
}

export const ActionDetailsOuterContainer = styled.div`
  width: 1030px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 80%;
  ${theme.breakpoints.down("md")} {
    width: 790px;
  }
  ${theme.breakpoints.down("sm")} {
    width: 480px;
  }
  ${theme.breakpoints.down("xs")} {
    width: 360px;
  }
  @media (max-width: 400px) {
    width: 300px;
  }
`;
export const ActionDetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background: #ffffff;
  border-radius: 20px;
  padding: 50px 60px;
  box-shadow: 10px 10px;
  gap: 40px;
  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 980px) {
    flex-direction: column;
    align-items: center;
    padding: 18px 30px;
  }
  @media (max-width: 600px) {
    padding: 12px 24px;
  }
`;
export const ActionBottomCardContainer = styled(ActionDetailsContainer)`
  @media (max-width: 600px) {
    padding: 18px 2px;
  }
`;

export const ActionDetailsLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: flex-start;
  gap: 25px;
  @media (max-width: 980px) {
    justify-content: center;
  }
  flex-grow: 1;
  flex-basis: 60%;
`;
export const ActionDetailsRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
`;
export const ActionDetailsLocationTitle = styled.p`
  font-weight: 600;
  color: white;
  margin-block: 5px;
`;

export const BoldRequiredText = styled.h4`
  font-weight: bold;
  font-size: x-large;
  margin-block: 0px;
`;
export const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;
export const CategoryComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
`;
export const CategoryQuantity = styled.p`
  background: ${(props) => props.color};
  border-radius: 18px;
  font-size: 15px;
  padding: 5px;
  margin: 5px;
  font-weight: bold;
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
export const TabContainer = styled.div`
  box-sizing: border-box;
`;
export const UserListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  @media (max-width: 980px) {
    justify-content: center;
  }
`;
export const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: solid 3px lightgrey;
  gap: 10px;
`;
export const UserDetailsRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
export const CategoryMini = styled.p`
  background: lightgreen;
  border-radius: 10px;
  font-size: 12px;
  padding: 3px;
  text-align: center;
  margin: 0px;
  font-weight: 600;
`;
export const UserIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;
export const UserDetailsRoleText = styled.p`
  color: ${(p: userListProps) => (p.coordinator ? "orange" : "green")};
  font-style: italic;
  margin: 0px;
`;
export const UserDetailsNameText = styled.p`
  font-weight: 500;
  margin: 0px;
`;
export const UserDetailsNoMargin = styled.p`
  margin: 0px;
`;
export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  ${theme.breakpoints.down("sm")} {
    position: sticky;
    bottom: 100px;
  }
`;
export const ActionDetailsButton = styled(Button)`
  padding: 10px;
  border-radius: 10px;
  background: ${(props: colorProps) => props.buttonColor};
  font-size: large;
  color: white;
`;
export const CategoryDesktopContainer = styled.div`
  @media (max-width: 1280px) {
    display: none;
  }
`;
export const CategoryMobileContainer = styled.div`
  @media (min-width: 1280.45px) {
    display: none;
  }
  background: #ffffff;
  border-radius: 20px;
  padding: 12px 0px;
  box-shadow: 10px 10px;
`;
export const LocationIcon = styled(LocationOnIcon)`
  color: white;
`;

export const StyledAccordion = styled(Accordion)`
  background-color: ${white};
  border-radius: 6px;
  //color: ${white};
`;
