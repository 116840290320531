import Role from "../constants/roles";

export const getMostSignificantRole = (roles: Role[] = []): Role => {
  if (roles.includes(Role.ADMINISTRATOR)) {
    return Role.ADMINISTRATOR;
  } else if (roles.includes(Role.COORDINATOR)) {
    return Role.COORDINATOR;
  } else {
    return Role.VOLUNTEER;
  }
};
