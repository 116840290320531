import { ReactElement } from "react";
import { LeftNavContainer } from "../../../shared/layout/LayoutElements";
import { LinkWrapper } from "../../../shared/buttons/ButtonElements";
import { LogoText } from "../NavBarElements";

const NavBarLogo = (): ReactElement => (
  <LeftNavContainer>
    <LinkWrapper to="/">
      <LogoText variant="h3">Mali zmaj</LogoText>
    </LinkWrapper>
  </LeftNavContainer>
);

export default NavBarLogo;
