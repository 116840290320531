import {
  Requirement,
  User
} from "../../../pages/ActionDetails/models/ActionDetailsDTO";
import { ReactElement } from "react";
import {
  BoldRequiredText,
  CategoryComponent,
  CategoryList,
  CategoryQuantity
} from "./ActionDetailsElements";
import { Category } from "../../shared/action/ActionSharedElements";
import { useTranslation } from "react-i18next";
import { userContainsPerk } from "../../shared/action/perkUtilFunctions";
import Scrollbars from "react-custom-scrollbars-2";

interface categoriesProps {
  requirements: Requirement[];
  confirmedUsers: User[];
  listOfPerkIdsUserIsOn: number[];
}

const getColor = (value: number): string => {
  if (value == 0) {
    return "#E57373";
  } else if (value < 0.5) {
    return "#D1C342";
  } else if (value < 1) {
    return "#A2DB2A";
  } else {
    return "#4CAF50";
  }
};

const Categories = ({
  listOfPerkIdsUserIsOn,
  requirements,
  confirmedUsers
}: categoriesProps): ReactElement => {
  const { t } = useTranslation();
  const getCategoryComponent = (category: Requirement) => {
    let users = confirmedUsers.filter((user) =>
      userContainsPerk(user.perks, category.perk)
    ).length;
    return (
      <CategoryComponent key={category.perk.id}>
        <Category isEnrolled={listOfPerkIdsUserIsOn.includes(category.perk.id)}>
          {category.perk.name}
        </Category>
        <CategoryQuantity
          color={getColor(users / category.quantity)}
        >{`${users}/${category.quantity}`}</CategoryQuantity>
      </CategoryComponent>
    );
  };
  return (
    <CategoryList>
      <BoldRequiredText>{t("requiredPerks")}</BoldRequiredText>
      <Scrollbars autoHeight>
        {requirements.map((requirement) => getCategoryComponent(requirement))}
      </Scrollbars>
    </CategoryList>
  );
};

export default Categories;
