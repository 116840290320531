import { ProfileWizardStep1Data } from "../components/app/ProfileWizard/models/ProfileWizardData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GET_ADMIN_PERSONAL_INFO_BY_ID_URL } from "./endpoints";
import UserDetailsDTO from "./models/UserDetailsDTO";
import axiosInstance from "./axiosInstance";

const personalInfoParams = (
  body: ProfileWizardStep1Data,
  id: string
): AxiosRequestConfig =>
  ({
    url: GET_ADMIN_PERSONAL_INFO_BY_ID_URL(id),
    method: "PATCH",
    data: body
  } as AxiosRequestConfig);

export const adminPostPersonalInfo = async (
  body: ProfileWizardStep1Data,
  id: string
): Promise<AxiosResponse<UserDetailsDTO>> =>
  await axiosInstance.request(personalInfoParams(body, id));
