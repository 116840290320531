import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
export const MSpinner = styled.img`
  animation: ${rotate} 1.5s ease-in-out infinite;
  width: 65px;
  height: 65px;
`;

export const BlackBackround = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000006d;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
`;
