import { ReactElement, useState } from "react";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import Report from "../../components/app/Report";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import SnackbarErrorContext from "../../components/shared/SnackbarErrorContext";

const ReportPage = (): ReactElement => {
  const [snackbarErrorMessageKey, setSnackbarErrorMessageKey] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <Snackbar
        open={snackbarErrorMessageKey.length !== 0}
        autoHideDuration={10000}
        onClose={() => setSnackbarErrorMessageKey("")}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          {t(snackbarErrorMessageKey)}
        </MuiAlert>
      </Snackbar>
      <PageContainer>
        <SnackbarErrorContext.Provider
          value={{
            snackbarErrorMessageKey,
            setSnackbarErrorMessageKey
          }}
        >
          <Report />
        </SnackbarErrorContext.Provider>
      </PageContainer>
    </>
  );
};

export default ReportPage;
