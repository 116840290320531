import styled from "styled-components";

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
`;

export const ReviewerName = styled.div`
  color: #189e18;
  font-size: 24px;
  font-weight: 600;
`;

export const CreationDate = styled.div`
  border-top: 1px solid black;
  font-size: 12px;
`;

export const ReviewText = styled.div`
  font-size: 16px;
`;
