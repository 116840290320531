import { useTranslation } from "react-i18next";
import { ScrollNavLink } from "../../app/NavBar/NavBarElements";
import { ReactElement } from "react";
import { getScrollAttributes } from "../../../utils/getScrollAttributes";

const AboutUsLink = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <ScrollNavLink
      activeClass="selectedLink"
      {...getScrollAttributes("whoAreWe")}
    >
      {t("aboutUs")}
    </ScrollNavLink>
  );
};

export default AboutUsLink;
