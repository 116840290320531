import { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPerks } from "../../../api/getPerks";
import { valueNotSet } from "../../../constants/errorConstants";
import { Perk } from "../../../pages/ActionFeed/models/ActionDTO";
import { Category } from "../../shared/action/ActionSharedElements";
import { ColmAlignLeftContainer } from "../../shared/layout/LayoutElements";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import { MInfoText, MLabel } from "../../shared/text/TextElements";
import { CategoriesComponent } from "../ActionFeed/ActionFeedElements";
import ProfileTabProps from "./models/ProfileTabProps";
import { MInfoContainer, PersonalInfoContainer } from "./ProfileInfoElements";

const SkillsTab = ({ user }: ProfileTabProps): ReactElement => {
  const [perks, setPerks] = useState<Perk[]>([]);
  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  const { t } = useTranslation();

  useEffect(() => {
    getPerks()
      .then((response) => {
        setPerks(response.data);
      })
      .catch(() => {
        setSnackbarErrorMessageKey("genericError");
      });
  }, []);

  return (
    <ColmAlignLeftContainer>
      <PersonalInfoContainer>
        <MLabel>{t("employmentStatus")}</MLabel>
        <MInfoText>
          {user?.employmentStatus
            ? t(user.employmentStatus.toLowerCase())
            : valueNotSet}
        </MInfoText>
      </PersonalInfoContainer>
      <PersonalInfoContainer>
        <MLabel>{t("profession")}</MLabel>
        <MInfoText>
          {user?.profession ? user.profession : valueNotSet}
        </MInfoText>
      </PersonalInfoContainer>
      <PersonalInfoContainer>
        <MInfoContainer>
          <MLabel>{t("skills")}</MLabel>
          {user?.perkIds ? (
            <CategoriesComponent>
              {perks
                .filter((perk) =>
                  user.perkIds?.some((perkId) => perkId === perk.id)
                )
                .map((perk) => (
                  <Category key={perk.id}>{perk.name}</Category>
                ))}
            </CategoriesComponent>
          ) : (
            valueNotSet
          )}
        </MInfoContainer>
      </PersonalInfoContainer>
    </ColmAlignLeftContainer>
  );
};

export default SkillsTab;
