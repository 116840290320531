import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosInstance from "./axiosInstance";
import { SPECIFIC_DAILY_WORK_RECORD } from "./endpoints";

export const deleteDailyWorkRecordProps = (
  dailyWorkRecordId: number
): AxiosRequestConfig =>
  ({
    url: SPECIFIC_DAILY_WORK_RECORD(dailyWorkRecordId),
    method: "DELETE"
  } as AxiosRequestConfig);

export const deleteDailyWorkRecord = async (
  dailyWorkRecordId: number
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(
    deleteDailyWorkRecordProps(dailyWorkRecordId)
  );
};
