import { Box } from "@material-ui/core";
import TabPanelProps from "./models/TabPanelProps";
import { ReactElement } from "react";
import { TabPanelContent } from "./TabElements";

const TabPanel = (props: TabPanelProps): ReactElement => {
  const { children, value, index, ...other } = props;

  return (
    <>
      <TabPanelContent
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        //accesibility
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box paddingTop={2}>{children}</Box>}
      </TabPanelContent>
    </>
  );
};

export default TabPanel;
