import { ReactElement } from "react";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import ActionForm from "../../components/app/ActionForm";

const ActionFormPage = (): ReactElement => {
  return (
    <>
      <PageContainer>
        <ActionForm />
      </PageContainer>
    </>
  );
};

export default ActionFormPage;
