import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GET_DOCUMENT_BY_ID_URL } from "./endpoints";

export const downloadDocumentByIdParams = (
  fileName: string,
  userId: string
): AxiosRequestConfig =>
  ({
    url: GET_DOCUMENT_BY_ID_URL(userId, fileName),
    method: "GET",
    responseType: "blob"
  } as AxiosRequestConfig);

export const getDocumentById = async (
  fileName: string,
  userId: string
): Promise<AxiosResponse<BlobPart>> => {
  return await axiosInstance.request(
    downloadDocumentByIdParams(fileName, userId)
  );
};
