import { ReactElement } from "react";

import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { postLogin } from "../../api/login";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import Login from "../../components/app/Login";
import { useDispatch } from "react-redux";
import axiosInstance from "../../api/axiosInstance";
import { USER_DETAILS_URL } from "../../api/endpoints";
import LoginFormData from "./models/LoginFormData";
import { saveJwtFromHeader } from "../../utils/jwt";
import { useHistory } from "react-router-dom";
import LoginDTO from "./models/LoginDTO";
import ErrorInfo from "../../constants/ErrorInfo";
import { UNAUTHORIZED } from "../../constants/errorConstants";
import { useTranslation } from "react-i18next";
import { getImage } from "../../api/getImage";
import { blobToBase64 } from "../../utils/base64Util";
import {
  saveProfilePicture,
  saveUserDetails
} from "../../store/actions/userDetailsActions";
import UserDetailsDTO from "../../api/models/UserDetailsDTO";
import { JWT_HEADER_KEY } from "../../constants/apiConstants";

const LoginPage = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    email: yup.string().required(t("required")),
    password: yup.string().required(t("required"))
  });

  const initialValues = {
    // TODO - refactor into constants?
    email: "",
    password: ""
  } as LoginFormData;

  const onSubmit = (
    values: LoginFormData,
    { setErrors, setSubmitting }: FormikHelpers<LoginFormData>
  ) => {
    postLogin(values as LoginDTO)
      .then((res) => {
        saveJwtFromHeader(res.headers[JWT_HEADER_KEY]);
        getImage()
          .then((res) => {
            blobToBase64(res.data).then((base64Result) => {
              dispatch(saveProfilePicture(base64Result as string));
            });
          })
          .catch(() => {
            console.log("Failed to fetch profile picture");
          });
        axiosInstance
          .get<UserDetailsDTO>(USER_DETAILS_URL)
          .then((res) => {
            dispatch(saveUserDetails(res.data));
            history.push("/akcije");
          })
          .catch((error) => {
            history.push("/greska", {
              status: error?.response?.data?.status,
              message: error?.response?.data?.message,
              description: error?.response?.data?.description
            } as ErrorInfo);
          });
      })
      .catch((error) => {
        if (error?.response?.status == UNAUTHORIZED) {
          setErrors({
            email: t("invalidCredentials"),
            password: t("invalidCredentials")
          });
          setSubmitting(false);
        } else {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        }
      });
  };

  return (
    <>
      <PageContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
        >
          {({ handleSubmit, handleChange }: FormikProps<LoginFormData>) => (
            <form onSubmit={handleSubmit} onChange={handleChange}>
              <Login />
            </form>
          )}
        </Formik>
      </PageContainer>
    </>
  );
};

export default LoginPage;
