import { ProfileWizardStep3Data } from "../components/app/ProfileWizard/models/ProfileWizardData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosInstance from "./axiosInstance";
import { INTERESTS_URL } from "./endpoints";
import UserDetailsDTO from "./models/UserDetailsDTO";

export const interestsParams = (
  body: ProfileWizardStep3Data
): AxiosRequestConfig =>
  ({
    url: INTERESTS_URL,
    method: "PATCH",
    data: body
  } as AxiosRequestConfig);

export const postInterests = async (
  body: ProfileWizardStep3Data
): Promise<AxiosResponse<UserDetailsDTO>> =>
  await axiosInstance.request(interestsParams(body));
