import AboutUsLink from "../components/shared/links/AboutUsLink";
import OurWorkLink from "../components/shared/links/OurWorkLink";
import ActionListLink from "../components/shared/links/ActionListLink";
import VolonteerListLink from "../components/shared/links/VolonteerListLink";
import CreateActionLink from "../components/shared/links/CreateActionLink";
import MyActionListLink from "../components/shared/links/MyActionListLink";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import GroupIcon from "@material-ui/icons/Group";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import NoteAddRoundedIcon from "@material-ui/icons/NoteAddRounded";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import CategoryIcon from "@material-ui/icons/Category";
import SubjectIcon from "@material-ui/icons/Subject";
import Role from "./roles";
import LinkWithIcon from "../components/shared/links/models/LinkWithIcon";
import CategoryEditLink from "../components/shared/links/CategoryEditLink";
import ActionReportLink from "../components/shared/links/ActionReportLink";
import WorkIcon from "@material-ui/icons/Work";
import HowWeWorkLink from "../components/shared/links/HowWeWorkLink";
// there are repeating elements in the record, but that is due to be solved in future refactoring.
// it depends on refactoring of links as constants, and that is considered a low priority for now.
export const roleBasedLinkMapping: Record<Role, LinkWithIcon[]> = {
  [Role.GUEST]: [
    {
      component: AboutUsLink,
      icon: ContactSupportIcon
    },
    {
      component: HowWeWorkLink,
      icon: GroupIcon
    },
    {
      component: OurWorkLink,
      icon: WorkIcon
    }
  ],
  [Role.VOLUNTEER]: [
    {
      component: ActionListLink,
      icon: FavoriteRoundedIcon
    },
    {
      component: MyActionListLink,
      icon: FolderSpecialIcon
    }
  ],
  [Role.COORDINATOR]: [
    {
      component: ActionListLink,
      icon: FavoriteRoundedIcon
    },
    {
      component: VolonteerListLink,
      icon: GroupIcon
    },
    {
      component: CreateActionLink,
      icon: NoteAddRoundedIcon
    },
    {
      component: MyActionListLink,
      icon: FolderSpecialIcon
    }
  ],
  [Role.ADMINISTRATOR]: [
    {
      component: ActionListLink,
      icon: FavoriteRoundedIcon
    },
    {
      component: VolonteerListLink,
      icon: GroupIcon
    },
    {
      component: CreateActionLink,
      icon: NoteAddRoundedIcon
    },
    {
      component: MyActionListLink,
      icon: FolderSpecialIcon
    },
    {
      component: CategoryEditLink,
      icon: CategoryIcon
    },
    {
      component: ActionReportLink,
      icon: SubjectIcon
    }
  ]
};
