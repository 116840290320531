import { createContext, Dispatch, SetStateAction } from "react";
import PerkDTO from "../../../api/models/PerkDTO";

interface PerkContextProps {
  selectedPerks: PerkDTO[];
  setSelectedPerks: Dispatch<SetStateAction<PerkDTO[]>>;
  perks: PerkDTO[];
  setPerks: Dispatch<SetStateAction<PerkDTO[]>>;
  isRepetitive: boolean;
  setIsRepetitive: Dispatch<SetStateAction<boolean>>;
}

const PerkContext = createContext<PerkContextProps>({} as PerkContextProps);

export default PerkContext;
