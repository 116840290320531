import { AxiosRequestConfig, AxiosResponse } from "axios";
import { DAILY_WORK_RECORDS } from "./endpoints";
import axiosInstance from "./axiosInstance";
import CreateDailyWorkRecordDTO from "./models/CreateDailyWorkRecordDTO";

export const postDailyWorkRecordParams = (
  actionId: number,
  isApproved: boolean,
  userId: string,
  workDate: Date,
  workingHours: number
): AxiosRequestConfig =>
  ({
    url: DAILY_WORK_RECORDS,
    method: "POST",
    data: {
      actionId,
      isApproved,
      workDate,
      workingHours,
      userId
    } as CreateDailyWorkRecordDTO
  } as AxiosRequestConfig);

export const postDailyWorkRecord = async (
  actionId: number,
  isApproved: boolean,
  userId: string,
  workDate: Date,
  workingHours: number
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(
    postDailyWorkRecordParams(
      actionId,
      isApproved,
      userId,
      workDate,
      workingHours
    )
  );
};
