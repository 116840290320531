import { ReactElement } from "react";
import { PageContainer } from "../../components/shared/layout/LayoutElements";
import ActionDetails from "../../components/app/ActionDetails";

const ActionDetailsPage = (): ReactElement => {
  return (
    <>
      <PageContainer>
        <ActionDetails />
      </PageContainer>
    </>
  );
};

export default ActionDetailsPage;
