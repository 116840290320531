import { ReactElement } from "react";
import { List, ListItem, ListItemIcon } from "@material-ui/core";
import { roleBasedLinkMapping } from "../../../../constants/navbarLinkMapping";
import { v4 as uuid } from "uuid";
import useAuth from "../../../../hooks/useAuth";

const SmallLinkGroup = (): ReactElement => {
  const { role } = useAuth();
  return (
    <>
      <List component="nav">
        {roleBasedLinkMapping[role].map(
          ({ component: LinkComponent, icon: IconComponent }) => (
            <ListItem button key={uuid()}>
              <ListItemIcon>
                <IconComponent />
              </ListItemIcon>
              <LinkComponent />
            </ListItem>
          )
        )}
      </List>
    </>
  );
};

export default SmallLinkGroup;
