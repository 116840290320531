import {
  LOGOUT,
  SAVE_PICTURE,
  SAVE_USER_DETAILS,
  userDetailsActionTypes
} from "../actions/userDetailsActionTypes";
import ProfilePlaceholderImage from "../../assets/img/profile_placeholder.png";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import UserDetailsDTO from "../../api/models/UserDetailsDTO";

export interface UserState {
  user?: UserDetailsDTO | undefined;
  logged: boolean;
  profilePicture: string;
}

export const initialUserState: UserState = {
  user: undefined,
  logged: false,
  profilePicture: ProfilePlaceholderImage
};

export default (
  state: UserState = initialUserState,
  action: userDetailsActionTypes
): UserState => {
  switch (action.type) {
    case SAVE_USER_DETAILS:
      return {
        ...state,
        user: action.payload,
        logged: true
      };
    case SAVE_PICTURE:
      return {
        ...state,
        profilePicture: action.payload
      };
    case LOGOUT:
      return {
        logged: false,
        profilePicture: ProfilePlaceholderImage
      };
    default:
      return state;
  }
};

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
