import { Tooltip } from "@material-ui/core";
import { ReactElement, ReactNode, useState } from "react";
import { ToolTipContainer } from "./ToolTipElements";

interface MToolTip {
  children: ReactNode;
  message: string;
}

const MToolTip = ({ children, message }: MToolTip): ReactElement => {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true
      }}
      arrow
      disableFocusListener
      leaveTouchDelay={2700}
      onClose={handleTooltipClose}
      onMouseEnter={handleTooltipOpen}
      open={open}
      title={message}
    >
      <ToolTipContainer onClick={handleTooltipOpen}>
        {children}
      </ToolTipContainer>
    </Tooltip>
  );
};

export default MToolTip;
