import { FieldHookConfig, useField } from "formik";
import { TextField, TextFieldProps } from "@material-ui/core";
import { ReactElement } from "react";

const InputField = (
  props: FieldHookConfig<string> & TextFieldProps
): ReactElement => {
  const [field, { error }] = useField(props);

  return (
    <TextField
      type="text"
      error={!!error}
      helperText={error}
      {...field}
      {...props}
    />
  );
};

export default InputField;
