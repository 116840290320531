import RegisterDTO from "../pages/Register/models/RegisterDTO";
import RegisterResponseDTO from "../pages/Register/models/RegisterResponseDTO";
import { REGISTER_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export const registerParams = (body: RegisterDTO): AxiosRequestConfig =>
  ({
    url: REGISTER_URL,
    method: "POST",
    data: body
  } as AxiosRequestConfig);

export const postRegister = async (
  body: RegisterDTO
): Promise<AxiosResponse<RegisterResponseDTO>> => {
  return await axiosInstance.request(registerParams(body));
};
