import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GET_ALL_ACTIONS_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import ActionDTO from "../pages/ActionFeed/models/ActionDTO";
import ActionDetailsDTO from "../pages/ActionDetails/models/ActionDetailsDTO";

export const actionParams = (): AxiosRequestConfig =>
  ({
    url: GET_ALL_ACTIONS_URL,
    method: "GET"
  } as AxiosRequestConfig);

export const actionDetailsParams = (id: number): AxiosRequestConfig =>
  ({
    url: GET_ALL_ACTIONS_URL + `/${id}`,
    method: "GET"
  } as AxiosRequestConfig);

export const getActions = async (): Promise<AxiosResponse<ActionDTO[]>> => {
  return await axiosInstance.request(actionParams());
};

export const getActionDetails = async (
  id: number
): Promise<AxiosResponse<ActionDetailsDTO>> => {
  return await axiosInstance.request(actionDetailsParams(id));
};
