import styled from "styled-components";
import { theme } from "../../../styles/style";

export const ActionUsersList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  padding: 40px;
`;
export const ActionUserRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
`;

export const VolunteerTilePicture = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  ${theme.breakpoints.down("md")} {
    width: 50px;
    height: 50px;
  }
`;

export const PerkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 40%;
  padding: 10px;
`;
export const UserActionLeftContainer = styled.div`
  display: flex;
  gap: 6px;
  align-content: center;
  justify-content: center;
`;

export const PerkPill = styled.p`
  display: flex;
  margin: auto;
  box-sizing: border-box;
  text-align: center;
  background-color: #64ffae;
  padding: 3px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
`;
