import styled from "styled-components";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Link as ScrollLink } from "react-scroll";
import { mPrimary, white } from "../../../constants/colorPallete";
import {
  mH3,
  mH5,
  mH6,
  mParagrafFontSize,
  navHeight
} from "../../../constants/cssConstants";
import background from "../../../assets/img/AbstractGradient.png";
import NavLinkStyledProps from "./models/NavLinkStyledProps";

export const NavBarWrapper = styled.header`
  display: flex;
  position: fixed;
  top: 0px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${navHeight};
  background-color: ${white};
  z-index: 900;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
`;
export const NavBarContainer = styled.header`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
`;
export const SideDrawerContainer = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
export const SideDrawerButtonContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
export const NavLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 36px;
`;
export const HideButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;
export const RegisteredUserRightNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  gap: 8px;
`;

export const LogoText = styled(Typography)`
  white-space: nowrap;
  font-size: ${mH3};
  @media (max-width: 980px) {
    font-size: ${mH5};
  }
`;
export const NavLink = styled(Link)<NavLinkStyledProps>`
  display: flex;
  white-space: nowrap;
  width: 100%;
  font-weight: ${(props) => (props.highlighted ? 600 : 500)};
  text-decoration: none;
  color: ${(props) => (props.highlighted ? mPrimary : "inherit")};
  justify-content: space-between;
`;
export const ScrollNavLink = styled(ScrollLink)<NavLinkStyledProps>`
  display: flex;
  white-space: nowrap;
  width: 100%;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  justify-content: space-between;
`;
export const MessageOfSupport = styled.h5`
  color: "#000";
  font-weight: 500;
  white-space: nowrap;
  font-size: ${mH5};
  @media (max-width: 980px) {
    font-size: ${mH6};
  }
  @media (max-width: 600px) {
    font-size: ${mParagrafFontSize};
  }
`;
export const SidebarBGImageContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 90px;
`;
