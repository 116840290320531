import { motion } from "framer-motion";
import styled from "styled-components";
import { white } from "../../../constants/colorPallete";
import {
  mH1,
  mH2,
  mH4,
  mH5,
  mH6,
  mHeroDesktop,
  mTitleTextShadow
} from "../../../constants/cssConstants";
import { theme } from "../../../styles/style";
import { ScrollNavLink } from "../NavBar/NavBarElements";

export const HeroContentContainer = styled.div`
  width: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-left: 120px;
  ${theme.breakpoints.down("md")} {
    width: calc(100% - 80px);
    padding-left: 80px;
  }
  ${theme.breakpoints.down("sm")} {
    width: 100%;
    padding-top: 24px;
    padding-left: 0px;
  }
`;

export const HeroButtoContainer = styled.div`
  width: 200px;
`;

export const HeroSection = styled(motion.section)`
  position: relative;
  height: 1000px;
  width: 100%;
  overflow-y: hidden;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`;
export const HeroWrapper = styled(motion.div)`
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 6px;
  margin: 0px 12px;
`;

export const HeroInfoLeftAlignContainer = styled(HeroWrapper)`
  max-width: 400px;
  margin-left: 16%;
  display: flex;
  padding: 6px 30px;
  flex-direction: column;
  align-content: center;
  text-align: center;
  ${theme.breakpoints.down("sm")} {
    max-width: 300px;
    padding: 0px 24px;
    margin: auto;
  }
`;
export const HeroInfoRightAlignContainer = styled(HeroWrapper)`
  max-width: 400px;
  margin-right: 16%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: flex-end;
  ${theme.breakpoints.down("sm")} {
    max-width: 300px;
    padding: 0px 24px;
    margin: auto;
  }
`;
export const QuoteContainer = styled.div`
  display: flex;
  position: relative;
  width: calc(100% - 48px);
  padding: 0px 24px;
  text-align: center;
  transform: translateY(36px);
`;
export const HeroTitle = styled.h1`
  margin: 0px;
  font-size: ${mHeroDesktop};
  color: ${white};
  text-shadow: ${mTitleTextShadow};
  ${theme.breakpoints.down("sm")} {
    font-size: ${mH1};
  }
  ${theme.breakpoints.down("xs")} {
    font-size: ${mH2};
  }
`;
export const HeroSubTitle = styled.h3`
  font-size: ${mH5};
  color: ${white};
  ${theme.breakpoints.down("xs")} {
    font-size: ${mH6};
  }
`;

export const InspirationQuote = styled(HeroSubTitle)`
  text-align: center;
  width: 100%;
  margin: 60px 24px 0px 24px;
  text-shadow: ${mTitleTextShadow};
`;
export const HeroInfoTitle = styled.h3`
  font-size: ${mH4};
  margin: 36px 0px 0px 0px;
  ${theme.breakpoints.down("xs")} {
    font-size: ${mH5};
  }
`;
export const HeroInfoBody = styled.h6`
  font-size: 15px;
  font-weight: 600;
  margin: 36px 12px;
`;
export const ArrowMessage = styled(motion.p)`
  color: ${white};
  font-size: ${mH6};
  font-weight: 600;
`;
export const ArrowContainer = styled(ScrollNavLink)`
  display: flex;
  flex-direction: column;
  width: auto;
  position: absolute;
  align-items: center;
  text-align: center;
  bottom: 24px;
  &:hover {
    transform: scale(1.1);
    text-shadow: ${mTitleTextShadow};
  }
`;
export const DragonSvgDiv = styled.div`
  -webkit-filter: drop-shadow(0px -4px 3px rgba(0, 0, 0, 0.2))
    drop-shadow(0px -8px 13px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0px -4px 3px rgba(0, 0, 0, 0.2))
    drop-shadow(0px -8px 13px rgba(0, 0, 0, 0.1));
`;
export const WaveSvgDiv = styled.div`
  //keep in mind shadows are reversed, svg shape is rotated for 180deg, so top shadow is actually on the bottom
  -webkit-filter: drop-shadow(0px -8px 3px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0px -8px 3px rgba(0, 0, 0, 0.2));
`;
