import { ReactElement, useState } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserAccordionListProps from "./models/UserAccordionListProps";
import UserWorkRecords from "../../shared/UserWorkRecords";
import { StyledAccordion } from "./ActionDetailsElements";

const UserAccordionList = ({
  action
}: UserAccordionListProps): ReactElement => {
  const [isExpandedList, setIsExpandedList] = useState<boolean[]>(
    new Array(action.confirmedUsers.length).fill(false)
  );

  return (
    <>
      {action.confirmedUsers.map((user, index) => (
        <StyledAccordion
          expanded={isExpandedList[index]}
          onChange={() => {
            let newIsExpandedList = [...isExpandedList];
            newIsExpandedList[index] = !newIsExpandedList[index];
            setIsExpandedList(newIsExpandedList);
          }}
          elevation={3}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="subtitle1">
              <strong>
                {user.firstName} {user.lastName}
              </strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UserWorkRecords
              userId={user.id}
              action={action}
              higherPrivileges={true}
            />
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </>
  );
};

export default UserAccordionList;
