import DateFnsUtils from "@date-io/date-fns";
import {
  Fab,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Checkbox
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteDailyWorkRecord } from "../../../api/deleteDailyWorkRecord";
import { getDailyWorkRecordsForUser } from "../../../api/getDailyWorkRecordsForUser";
import DailyWorkRecordDTO from "../../../api/models/DailyWorkRecordDTO";
import { patchDailyWorkRecord } from "../../../api/patchDailyWorkRecord";
import { postDailyWorkRecord } from "../../../api/postDailyWorkRecord";
import { compareDates } from "../../../utils/dateTimeUtils";
import ConfirmDialog from "../ConfirmDialog";
import {
  FlexCenterContainer,
  FlexColumnContainer
} from "../layout/LayoutElements";
import SnackbarErrorContext from "../SnackbarErrorContext";
import UserWorkRecordsProps from "./models/UserWorkDetails";
import {
  DateInputContainer,
  EditButtonsContainer,
  InsertWorkContainer,
  NumberAndAddContainer,
  StyledTableCell,
  StyledTablePagination
} from "./UserWorkRecordsElements";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import i18next from "i18next";
import { CROATIAN } from "../../../constants/languageConstants";
import { enUS, hr } from "date-fns/locale";

const UserWorkRecords = ({
  userId,
  action,
  higherPrivileges
}: UserWorkRecordsProps): ReactElement => {
  const { t } = useTranslation();
  const [inputDate, setInputDate] = useState<Date | null>(null);
  const [inputNumberOfHours, setInputNumberOfHours] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [needToUpdateRecords, setNeedToUpdateRecords] = useState(true);
  const [workRecords, setWorkRecords] = useState<DailyWorkRecordDTO[]>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [workRecordToDeleteId, setWorkRecordToDeleteId] = useState<
    number | null
  >(null);

  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);
  const { windowWidth } = useWindowDimensions();
  useEffect(() => {
    if (needToUpdateRecords) {
      getDailyWorkRecordsForUser(userId, action.id)
        .then((res) => {
          setWorkRecords(res.data);
          setNeedToUpdateRecords(false);
        })
        .catch(() => {
          setSnackbarErrorMessageKey("failedToFetchWorkRecords");
        });
    }
  }, [needToUpdateRecords]);

  const handlePatch = (
    workRecordId: number,
    workingHours: number,
    isApproved: boolean
  ): void => {
    patchDailyWorkRecord(workRecordId, isApproved, workingHours)
      .then(() => {
        setNeedToUpdateRecords(true);
      })
      .catch(() => setSnackbarErrorMessageKey("failedToUpdateWorkRecord"));
  };

  const handleDelete = (): void => {
    if (workRecordToDeleteId != null) {
      deleteDailyWorkRecord(workRecordToDeleteId)
        .then(() => {
          setNeedToUpdateRecords(true);
        })
        .catch(() => setSnackbarErrorMessageKey("failedToDeleteWorkRecord"));
    }
  };

  const handleSubmit = (): void => {
    if (inputDate) {
      postDailyWorkRecord(
        action.id,
        higherPrivileges,
        userId,
        inputDate,
        inputNumberOfHours
      )
        .then(() => {
          setInputDate(null);
          setNeedToUpdateRecords(true);
        })
        .catch(() => {
          setSnackbarErrorMessageKey("failedToCreateWorkRecord");
        });
    }
  };

  const disableAlreadyUsedDates = (date: MaterialUiPickersDate): boolean => {
    if (date) {
      return workRecords.some(
        (workRecord) =>
          new Date(Date.parse(workRecord.workDate)).getDate() === date.getDate()
      );
    }
    return false;
  };

  return (
    <>
      <ConfirmDialog
        title={t("confirmActionDelete")}
        open={isConfirmDialogOpen}
        setOpen={setIsConfirmDialogOpen}
        onConfirm={() => handleDelete()}
      />
      <FlexColumnContainer>
        <InsertWorkContainer>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={i18next.language == CROATIAN ? hr : enUS}
          >
            <DateInputContainer>
              <KeyboardDatePicker
                name="filter-date"
                label={t("date")}
                format="dd.MM.yyyy"
                value={inputDate}
                inputVariant="filled"
                keyboardIcon={<EventRoundedIcon className="whiteIcon" />}
                DialogProps={{ PaperComponent: Paper }}
                onChange={setInputDate}
                minDate={action.startDate}
                maxDate={action.endDate}
                fullWidth
                shouldDisableDate={disableAlreadyUsedDates}
              />
            </DateInputContainer>
          </MuiPickersUtilsProvider>
          <NumberAndAddContainer>
            <TextField
              label={t("hours")}
              type="number"
              value={inputNumberOfHours}
              onChange={(event) =>
                setInputNumberOfHours(parseInt(event.target.value))
              }
              variant="filled"
              fullWidth
              name="quantity"
              InputProps={{ inputProps: { min: 1, max: 24 } }}
            />
            <FlexCenterContainer>
              <Fab
                onClick={() => handleSubmit()}
                color="secondary"
                aria-label={"label"}
                size="small"
                variant={"circular"}
                disabled={inputDate === null}
              >
                <AddIcon />
              </Fab>
            </FlexCenterContainer>
          </NumberAndAddContainer>
        </InsertWorkContainer>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("date")}</StyledTableCell>
                <StyledTableCell align="right">
                  {t("workHours")}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {t("isApproved")}
                </StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workRecords
                .sort((a, b) =>
                  compareDates(
                    new Date(Date.parse(b.workDate)),
                    new Date(Date.parse(a.workDate))
                  )
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {new Date(Date.parse(row.workDate)).toLocaleDateString()}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.workingHours}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Checkbox
                        checked={row.isApproved}
                        disabled={!higherPrivileges}
                        onClick={() =>
                          handlePatch(row.id, row.workingHours, !row.isApproved)
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <EditButtonsContainer>
                        <Fab
                          onClick={() => {
                            setWorkRecordToDeleteId(row.id);
                            setIsConfirmDialogOpen(true);
                          }}
                          color="secondary"
                          aria-label={"label"}
                          size="small"
                          variant={"circular"}
                          disabled={row.isApproved && !higherPrivileges}
                        >
                          <DeleteIcon />
                        </Fab>
                      </EditButtonsContainer>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={workRecords.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={windowWidth < 600 ? `` : `${t("itemsLong")}`}
          page={page}
          onPageChange={(event, value) => setPage(value)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t("of")} ${count}`
          }
        />
      </FlexColumnContainer>
    </>
  );
};

export default UserWorkRecords;
