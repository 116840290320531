import { Link } from "react-router-dom";
import styled from "styled-components";
import { mLightBlue } from "../../../constants/colorPallete";

export const IconHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: black;
`;

export const AnchorLink = styled(Link)`
  color: ${mLightBlue};
`;
