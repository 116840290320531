import {
  ButtonsContainer,
  CircularButtonBase,
  CircularImage,
  CustomSlider,
  ImageDialogContainer
} from "./ProfilePictureElements";
import { Dialog, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import AvatarEditor from "react-avatar-editor";
import { MButtonMaxWidth, MButtonWide } from "../buttons/ButtonElements";
import {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useRef
} from "react";
import ProfilePictureCoreProps from "./models/ProfilePictureCoreProps";
import { useTranslation } from "react-i18next";
import { processImageFileToBase64 } from "../../../utils/profileImageUtil";
import {
  AVATAR_EDITOR_BORDER,
  AVATAR_EDITOR_BREAKPOINT,
  AVATAR_EDITOR_COLOR,
  AVATAR_EDITOR_ROTATE
} from "../../../constants/avatarEditorConstants";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const AvatarEditorCore = ({
  base64Image,
  setBase64Image,
  scale,
  setScale,
  errorMessageId,
  setErrorMessageId,
  handleProfilePictureEditorDialogClose,
  handleProfilePictureEditorDialogOpen,
  isPictureReadyToSend,
  setIsPictureReadyToSend,
  showProfilePictureEditorDialog,
  profilePicture,
  editorRef,
  onUpload
}: ProfilePictureCoreProps): ReactElement => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const handleScaleChange = (
    event: ChangeEvent<Record<string, unknown>>,
    newValue: number | number[]
  ) => {
    setScale(newValue as number);
  };

  const handleFileInputChange = (e: SyntheticEvent) => {
    let imageFile = (e.target as HTMLInputElement).files?.item(0) as File;
    processImageFileToBase64({
      imageFile,
      setBase64Image,
      setErrorMessageId,
      handleProfilePictureEditorDialogClose
    });
  };

  useEffect(() => {
    setIsPictureReadyToSend(base64Image.length > 0);
  }, [base64Image]);

  useEffect(() => {
    if (showProfilePictureEditorDialog) {
      fileInput?.current?.click();
    }
  }, [showProfilePictureEditorDialog]);

  const { windowWidth } = useWindowDimensions();

  const AVATAR_EDITOR_HEIGHT =
    windowWidth < AVATAR_EDITOR_BREAKPOINT ? 120 : 250;
  const AVATAR_EDITOR_WIDTH =
    windowWidth < AVATAR_EDITOR_BREAKPOINT ? 120 : 250;
  const { t } = useTranslation();

  return (
    <>
      <CircularButtonBase onClick={handleProfilePictureEditorDialogOpen}>
        <CircularImage src={profilePicture} alt={"picture"} />
      </CircularButtonBase>
      <Snackbar
        open={errorMessageId.length !== 0}
        autoHideDuration={10000}
        onClose={() => setErrorMessageId("")}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          {t(errorMessageId)}
        </MuiAlert>
      </Snackbar>
      <Dialog
        open={showProfilePictureEditorDialog}
        onClose={handleProfilePictureEditorDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ImageDialogContainer>
          <AvatarEditor
            ref={editorRef}
            image={base64Image as string}
            width={AVATAR_EDITOR_WIDTH}
            height={AVATAR_EDITOR_HEIGHT}
            border={AVATAR_EDITOR_BORDER}
            color={AVATAR_EDITOR_COLOR} // RGBA
            scale={scale}
            rotate={AVATAR_EDITOR_ROTATE}
          />
          <input
            type={"file"}
            ref={fileInput}
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
          <CustomSlider
            step={0.1}
            min={1}
            max={5}
            value={scale}
            onChange={handleScaleChange}
            aria-labelledby="continuous-slider"
          />
          <ButtonsContainer>
            <MButtonMaxWidth
              type={"submit"}
              variant={"contained"}
              color={"secondary"}
              onClick={() => fileInput?.current?.click()}
            >
              {t("chooseFile")}
            </MButtonMaxWidth>
            <MButtonMaxWidth
              type={"submit"}
              variant={"contained"}
              color={"secondary"}
              onClick={onUpload}
              disabled={!isPictureReadyToSend}
            >
              {t("upload")}
            </MButtonMaxWidth>
            <MButtonWide
              color={"secondary"}
              type={"button"}
              variant={"contained"}
              onClick={handleProfilePictureEditorDialogClose}
            >
              {t("close")}
            </MButtonWide>
          </ButtonsContainer>
        </ImageDialogContainer>
      </Dialog>
    </>
  );
};

export default AvatarEditorCore;
