import { ReactElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ActionUserDTO from "../../../api/models/ActionUserDTO";
import { getActionUsers } from "../../../api/getActionUsers";
import ErrorInfo from "../../../constants/ErrorInfo";
import { ActionUsersList } from "./ActionUsersElements";
import ActionUserTile from "./ActionUserTile";
import { v4 as uuid } from "uuid";
import ConfirmDialogContext from "../../shared/ConfirmDialog/ConfirmDialogContext";
import ConfirmUser from "./ConfirmUser";
import { patchActionUser } from "../../../api/patchActionUser";

interface actionUsersParams {
  id: string;
}

const ActionUsers = (): ReactElement => {
  const { id } = useParams<actionUsersParams>();
  const history = useHistory();
  const [users, setUsers] = useState<ActionUserDTO[]>([]);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ActionUserDTO>();

  useEffect(() => {
    getActionUsers(id)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        history.push("/greska", {
          status: error?.response?.data?.status,
          message: error?.response?.data?.message,
          description: error?.response?.data?.description
        } as ErrorInfo);
      });
  }, []);

  const handleConfirm = (user: ActionUserDTO) => {
    setSelectedUser(user);
    setConfirmDialogOpen(true);
  };

  const handleReject = (user: ActionUserDTO) => {
    patchActionUser({ confirm: false, perkIds: [], userId: user.id }, id)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        history.push("/greska", {
          status: error?.response?.data?.status,
          message: error?.response?.data?.message,
          description: error?.response?.data?.description
        } as ErrorInfo);
      });
  };

  return (
    <ConfirmDialogContext.Provider
      value={{ isConfirmDialogOpen, setConfirmDialogOpen }}
    >
      <ActionUsersList>
        {users.filter((user) => !user.confirmed).length < 1 ? (
          <h1>Nema zahtjeva</h1>
        ) : (
          users
            .filter((user) => !user.confirmed)
            .map((user) => (
              <ActionUserTile
                key={uuid()}
                user={user}
                handleConfirm={handleConfirm}
                handleReject={handleReject}
              />
            ))
        )}
      </ActionUsersList>
      <ConfirmUser user={selectedUser} actionId={id} setUsers={setUsers} />
    </ConfirmDialogContext.Provider>
  );
};

export default ActionUsers;
