import { ReactComponent as FlagIconHR } from "../assets/svg/hr.svg";
import { ReactComponent as FlagIconUK } from "../assets/svg/uk.svg";
import { ComponentProps } from "react";

/**
 * Mapping that maps the icon names to react components.
 */
export const iconMapping = {
  hr: FlagIconHR,
  en: FlagIconUK
} as Record<string, (props: ComponentProps<"svg">) => JSX.Element>;
