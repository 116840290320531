import { ReactElement, useState } from "react";
import {
  BigDescriptionIcon,
  DocumentTileContainer,
  DocumentTitle,
  WhiteWarningIcon
} from "./DocumentListElements";
import { Badge, ButtonBase } from "@material-ui/core";
import ConfirmDialog from "../../shared/ConfirmDialog";
import { useTranslation } from "react-i18next";
import DocumentTileProps from "./models/DocumentTileProps";
import FileEditPopup from "./FileEditPopup";

const DocumentTile = ({
  fileName,
  isApproved,
  deleteDocument,
  downloadDocument,
  handleDocumentStatusPatch,
  canDelete,
  canApprove
}: DocumentTileProps): ReactElement => {
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);

  const { t } = useTranslation();
  return (
    <ButtonBase>
      <ConfirmDialog
        title={t("confirmDelete") + `"${fileName}" ?`}
        open={isConfirmDialogOpen}
        setOpen={setConfirmDialogOpen}
        onConfirm={() => deleteDocument(fileName)}
      />
      <FileEditPopup
        canDelete={canDelete}
        isOpen={isEditDialogOpen}
        setDialogOpen={setEditDialogOpen}
        fileName={fileName}
        canApprove={canApprove}
        documentIsApproved={isApproved}
        downloadDocument={() => downloadDocument(fileName)}
        setDeleteDialogOpen={setConfirmDialogOpen}
        patchApprovalStatus={handleDocumentStatusPatch}
      />
      <DocumentTileContainer onClick={() => setEditDialogOpen(true)}>
        <Badge
          badgeContent={<WhiteWarningIcon />}
          invisible={isApproved}
          overlap={"circular"}
          color="error"
        >
          <BigDescriptionIcon />
        </Badge>
        <DocumentTitle>{fileName}</DocumentTitle>
      </DocumentTileContainer>
    </ButtonBase>
  );
};

export default DocumentTile;
