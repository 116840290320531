// not actual names, but keys for i18n
export const ACTION_FORM_STEPS = [
  "createActionFormStep1",
  "createActionFormStep2",
  "createActionFormStep3",
  "createActionFormStep4"
];

export const INFINITE_CALENDAR_BREAKPOINT = 650;

export const INFINITE_CALENDAR_WIDTH_SMALL = 300;

export const INFINITE_CALENDAR_WIDTH_LARGE = 450;

export const INFINITE_CALENDAR_HEIGHT = 450;

export const DATE_TABLE_ROW_HEIGHT_WITH_MARGIN = 53;

export const DEFAULT_NUMBER_OF_DATES_PER_PAGE = 5;
