import { AxiosRequestConfig, AxiosResponse } from "axios";
import { PERK_TYPES_URL } from "./endpoints";
import axiosInstance from "./axiosInstance";
import CreatePerkTypeDTO from "./models/CreatePerkTypeDTO";

export const postPerkTypeParams = (
  name: string,
  nameEnglish: string
): AxiosRequestConfig =>
  ({
    url: PERK_TYPES_URL,
    method: "POST",
    data: { name, nameEnglish } as CreatePerkTypeDTO
  } as AxiosRequestConfig);

export const postPerkType = async (
  name: string,
  nameEnglish: string
): Promise<AxiosResponse<void>> => {
  return await axiosInstance.request(postPerkTypeParams(name, nameEnglish));
};
