import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getActionDetails } from "../../../api/actions";
import ErrorInfo from "../../../constants/ErrorInfo";
import { getCutTextThreeDots } from "../../../utils/getCutTextThreeDots";
import { Category } from "../../shared/action/ActionSharedElements";
import DatesComponent from "../../shared/action/DatesComponent";
import { filterFilledPerks } from "../../shared/action/perkUtilFunctions";
import ActionTitleClickable from "../../shared/ActionTitle/ActionTitleClickable";
import { MButton, MButtonContrast } from "../../shared/buttons/ButtonElements";
import {
  ButtonContainer,
  EnrolledBanner,
  MainContentContainer,
  WarningContainer
} from "../../shared/layout/LayoutElements";
import LocationLabel from "../../shared/LocationLabel";
import MToolTip from "../../shared/ToolTip";
import WarningAlert from "../../shared/WarningAlert";
import {
  ActionCardContainer,
  ActionCardDataContent,
  ActionCardDescription,
  CategoriesComponent,
  CategoryGroup,
  ItalicText,
  LeftActionCardContainer,
  RightActionCardContainer
} from "./ActionFeedElements";
import { ActionProps } from "./models/ActionCardProps";
import TimesComponent from "../../shared/action/TimesComponent";

const ActionCard = ({
  action,
  openSignUpDialog
}: ActionProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const toActionPage = (id: number) => {
    history.push(`/akcije/${id}`);
  };

  const signUpForAction = (id: number, isUpdating: boolean) => {
    getActionDetails(id)
      .then((response) => {
        if (openSignUpDialog) {
          openSignUpDialog(
            id,
            filterFilledPerks(response.data),
            action.listOfPerkIdsUserIsOn,
            isUpdating
          );
        }
      })
      .catch((error) => {
        history.push("/greska", {
          status: error?.response?.data?.status,
          message: error?.response?.data?.message,
          description: error?.response?.data?.description
        } as ErrorInfo);
      });
  };

  return (
    <MainContentContainer isEnrolledStyle={action.isEnrolled}>
      {action.isEnrolled && (
        <EnrolledBanner
          isConfimedStyle={action.isConfirmed}
          isEnrolledStyle={action.isEnrolled}
        />
      )}
      <ActionCardContainer>
        {action.isEnrolled && !action.isConfirmed && (
          <WarningContainer>
            <MToolTip message={t("actionNeedsApproval")}>
              <WarningAlert />
            </MToolTip>
          </WarningContainer>
        )}
        <LeftActionCardContainer>
          <ActionCardDataContent>
            <ActionTitleClickable title={action.title} id={action.id} />
            <LocationLabel location={action.location} />
            <ActionCardDescription>
              {action.shortDescription.length > 0
                ? action.shortDescription
                : getCutTextThreeDots(action.description, 250)}
            </ActionCardDescription>
          </ActionCardDataContent>
          <DatesComponent
            startDate={action.startDate}
            endDate={action.endDate}
          />
          <TimesComponent
            startTime={action.startTime}
            endTime={action.endTime}
          />
        </LeftActionCardContainer>
        <RightActionCardContainer>
          <CategoryGroup>
            <ItalicText>{t("requiredPerks")}</ItalicText>
            <CategoriesComponent>
              {action.requirements.map((requirement) => (
                <Category
                  key={requirement.perk.id}
                  isEnrolled={action.listOfPerkIdsUserIsOn.includes(
                    requirement.perk.id
                  )}
                  isConfirmed={
                    action.listOfPerkIdsUserIsOn.includes(
                      requirement.perk.id
                    ) && action.isConfirmed
                  }
                >
                  {requirement.perk.name}
                </Category>
              ))}
            </CategoriesComponent>
          </CategoryGroup>
          <ButtonContainer>
            {!action.isConfirmed &&
              (action.isEnrolled ? (
                <MButtonContrast
                  variant="contained"
                  color="secondary"
                  onClick={() => signUpForAction(action.id, true)}
                >
                  {t("update")}
                </MButtonContrast>
              ) : (
                <MButtonContrast
                  variant="contained"
                  color="secondary"
                  onClick={() => signUpForAction(action.id, false)}
                >
                  {t("signUpForAction")}
                </MButtonContrast>
              ))}
            <MButton
              variant="contained"
              color="secondary"
              onClick={() => toActionPage(action.id)}
            >
              {t("moreInfoForAction")}
            </MButton>
          </ButtonContainer>
        </RightActionCardContainer>
      </ActionCardContainer>
    </MainContentContainer>
  );
};

export default ActionCard;
