import axiosInstance from "./axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GET_DAILY_WORK_RECORDS_FOR_ACTION_FOR_USER } from "./endpoints";
import DailyWorkRecordDTO from "./models/DailyWorkRecordDTO";

export const getDailyWorkRecordsForUserParams = (
  userId: string,
  actionId: number
): AxiosRequestConfig =>
  ({
    url: GET_DAILY_WORK_RECORDS_FOR_ACTION_FOR_USER(actionId, userId),
    method: "GET",
    responseType: "json"
  } as AxiosRequestConfig);

export const getDailyWorkRecordsForUser = async (
  userId: string,
  actionId: number
): Promise<AxiosResponse<DailyWorkRecordDTO[]>> => {
  return await axiosInstance.request(
    getDailyWorkRecordsForUserParams(userId, actionId)
  );
};
