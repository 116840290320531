import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  TextField
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { getDocumentCategories } from "../../../api/getDocumentCategories";
import { fileToBase64 } from "../../../utils/base64Util";
import { getCutOriginalFileName } from "../../../utils/getCutFileName";
import SnackbarErrorContext from "../../shared/SnackbarErrorContext";
import { MButtonMaxWidth, MButtonWide } from "../buttons/ButtonElements";
import FabReactive from "../FabReactive";
import {
  DocumentAddContainer,
  DocumentAddFormContainer,
  DocumentIconAndTitleContainer,
  DocumentTitle,
  FullWidthSelect,
  HugeDescriptionIcon
} from "./DocumentListElements";
import DocumentDTO from "./models/DocumentDTO";
import PopupFileUploadProps from "./models/PopupFileUploadProps";

const formStyle = {
  margin: "20px"
};

const PopupFileUpload = ({
  open,
  setUploadDialogOpen,
  uploadDocument
}: PopupFileUploadProps): ReactElement => {
  const [base64File, setBase64File] = useState<string>("");
  const [isFileReadyToSend, setIsFileReadyToSend] = useState<boolean>(false);
  const [suggestedDocumentCategories, setSuggestedDocumentCategories] =
    useState<string[]>([]);
  // numbers because we know other has key 0
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [showCustomFileNameInput, setShowCustomFileNameInput] =
    useState<boolean>(true);
  const [originalFileName, setOriginalFileName] = useState<string>("");

  // For opening file chooser when dialog is opened
  const fileInput = useRef<HTMLInputElement | null>(null);

  const [chosenFileName, setChosenFileName] = useState<string>("");

  const { setSnackbarErrorMessageKey } = useContext(SnackbarErrorContext);

  const handlePopupOpen = () => {
    setUploadDialogOpen(true);
    setSnackbarErrorMessageKey("");
  };

  // Clear data for clean upload of next document
  const handlePopupClose = () => {
    setChosenFileName("");
    setBase64File("");
    setOriginalFileName("");
    setSelectedCategory(0);
    setUploadDialogOpen(false);
  };

  // For controlling file name input
  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChosenFileName(event.target.value);
  };

  const handleSelectFileNameChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedCategory(event.target.value as number);
    if (event.target.value != 0) {
      setChosenFileName(
        suggestedDocumentCategories[(event.target.value as number) - 1]
      );
    }
  };

  const handleFileInputChange = (e: SyntheticEvent) => {
    let file: File | undefined | null = (
      e.target as HTMLInputElement
    ).files?.item(0);
    if (file !== undefined && file !== null) {
      fileToBase64(file).then((result: string) => {
        result = result.split(",")[1]; // necessary for valid format
        setBase64File(result);
      });
      setOriginalFileName(file.name);
    }
  };

  useEffect(() => {
    if (selectedCategory == 0) {
      setShowCustomFileNameInput(true);
    } else {
      setShowCustomFileNameInput(false);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (open)
      getDocumentCategories().then((res) =>
        setSuggestedDocumentCategories(res.data.documentCategories)
      );
  }, [open]);

  useEffect(() => {
    if (
      base64File != null &&
      base64File != "" &&
      chosenFileName.trim().length > 0
    ) {
      setIsFileReadyToSend(true);
    } else {
      setIsFileReadyToSend(false);
    }
  }, [base64File, chosenFileName]);

  // To prevent page reload
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setUploadDialogOpen(false);

    const documentDTO: DocumentDTO = {
      fileName: chosenFileName,
      file: base64File // Spring will automatically convert this to byte[] of the actual file
    };
    uploadDocument(documentDTO);
    handlePopupClose();
  };

  const { t } = useTranslation();

  return (
    <>
      <FabReactive
        icon={<AddIcon />}
        label={t("add")}
        onClick={handlePopupOpen}
      />

      <Dialog
        open={open}
        onClose={handlePopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DocumentAddContainer>
          <DocumentIconAndTitleContainer>
            <HugeDescriptionIcon />
            <DocumentTitle>
              {getCutOriginalFileName(originalFileName)}
            </DocumentTitle>
          </DocumentIconAndTitleContainer>
          <form onSubmit={onSubmit} style={formStyle}>
            <DocumentAddFormContainer>
              <MButtonMaxWidth
                type={"button"}
                variant={"contained"}
                color={"secondary"}
                onClick={() => {
                  if (open) fileInput?.current?.click();
                }}
              >
                {t("chooseFile")}
              </MButtonMaxWidth>
              <input
                type="file"
                onChange={handleFileInputChange}
                style={{ display: "none" }}
                ref={fileInput}
              />
              <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="filled-age-native-simple">
                  {t("category")}
                </InputLabel>
                <FullWidthSelect
                  variant="filled"
                  label={t("documentType")}
                  labelWidth={50}
                  value={selectedCategory}
                  onChange={handleSelectFileNameChange}
                  inputProps={{
                    name: "age",
                    id: "filled-age-native-simple"
                  }}
                >
                  <MenuItem value={0} key={0}>
                    {t("other")}
                  </MenuItem>
                  {suggestedDocumentCategories.map((s, index) => (
                    <MenuItem value={index + 1} key={index + 1}>
                      {s}
                    </MenuItem>
                  ))}
                </FullWidthSelect>
              </FormControl>
              {showCustomFileNameInput && (
                <TextField
                  fullWidth
                  variant="filled"
                  type={"text"}
                  onChange={handleFileNameChange}
                  value={chosenFileName}
                  label={t("documentName")}
                />
              )}
              <MButtonMaxWidth
                disabled={!isFileReadyToSend}
                type={"submit"}
                variant={"contained"}
                color={"secondary"}
              >
                {t("upload")}
              </MButtonMaxWidth>
              <MButtonWide
                color={"secondary"}
                type={"button"}
                variant={"contained"}
                onClick={handlePopupClose}
              >
                {t("close")}
              </MButtonWide>
            </DocumentAddFormContainer>
          </form>
        </DocumentAddContainer>
      </Dialog>
    </>
  );
};

export default PopupFileUpload;
