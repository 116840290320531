export const TRANSLATION_HR: Record<string, string> = {
  loginTitle: "Prijava", // title displayed at the top of the login page
  logout: "Odjavi se",
  registerTitle: "Registracija", // title displayed at the top of the registration page
  registerSuccess: "Uspješna registracija, prijavite se u svoj račun.", // alert after successful registration
  email: "Email", // e-mail label
  password: "Lozinka", // password label
  repeatPassword: "Ponovi lozinku",
  couldNotDeleteAction: "Nije moguće izbrisati akciju.",
  telephone: "Telefon",
  birthDate: "Datum rođenja",
  confirmActionDelete: "Potvrdite brisanje akcije",
  confirmActionDeleteDetails:
    "Brisanje će rezultirati trajnim brisanje akcije {{title}}.",
  confirmActionArchive: "Potvrdite arhiviranje akcije",
  confirmActionArchiveDetails:
    "Više se neće moći upisivati sati za akciju {{title}}",
  confirmActionUnarchive: "Potvrdite povratak akcije iz arhive",
  confirmActionUnarchiveDetails:
    "Omogućiti će se upisivanje sati za akciju {{title}}",
  unarchive: "Vrati iz arhive",
  archive: "Arhiviraj",
  gender: "Spol",
  residentialAddress: "Adresa stanovanja",
  country: "Država",
  hours: "Sati",
  failedToFetchWorkRecords: "Nije uspjelo dohvaćanje radnih zapisa",
  failedToUpdateWorkRecord: "Ažuriranje radnog zapisa nije uspjelo",
  failedToDeleteWorkRecord: "Brisanje radnog zapisa nije uspjelo",
  failedToCreateWorkRecord: "Nije uspjelo stvaranje radnog zapisa",
  rememberMe: "Zapamti me",
  loginAttempt: "Prijavi se", // log in button
  forgotPassword: "Zaboravili ste lozinku?",
  registerHint: "Nemate korisnički račun? Registrirajte se",
  aboutUs: "Tko smo?",
  ourWork: "Što radimo?",
  ourModel: "Kako radimo?",
  volunteerList: "Volonteri",
  actionList: "Volonterske akcije",
  myActionList: "Moje akcije",
  unknown: "Nepoznato",
  isApproved: "Odobreno",
  contactUs: "Kontaktirajte nas",
  registerAttempt: "Registriraj se",
  viewUserActions: "Pregled volonterskih akcija",
  registerActionCall: "Pridruži nam se!",
  firstName: "Ime",
  lastName: "Prezime",
  normal: "Normalna",
  mon: "Pon",
  tue: "Uto",
  wed: "Sri",
  thu: "Čet",
  fri: "Pet",
  sat: "Sub",
  sun: "Ned",
  repetitive: "Ponavljajuća",
  notificationPrompt:
    "Želim dobivati obavijesti o akcijama i novostima Društva Mali zmaj",
  loginHint: "Već imate korisnički račun? Prijavite se",
  confirmPassword: "Potvrdi lozinku",
  forgotPasswordEmailHint: "Unesite vašu email adresu.",
  send: "Pošalji",
  backToLogin: "Vrati se na prijavu",
  enterNewPassword: "Unesite novu lozinku",
  confirm: "Potvrdi",
  clear: "Očisti",
  selectAtLeastOneDate: "Molimo odaberite barem jedan datum",
  copyright: "Autorska prava",
  validEmail: "Molimo unesite važeću email adresu.",
  validPasswordLength: "Lozinka mora sadržavati barem 8 znakova.",
  validPasswordMatch: "Lozinke se ne podudaraju.",
  emailInUse: "Ova email adresa se već koristi.",
  maxLength64: "Ovo polje može sadržavati maksimalno 64 znaka.",
  validPasswordFormat:
    "Lozinka mora sadržavati barem 1 broj, 1 malo slovo i 1 veliko slovo.",
  validFirstName: "Molimo unesite važeće ime.",
  validLastName: "Molimo unesite važeće prezime.",
  required: "Ovo polje je obavezno.",
  invalidCredentials: "Krivo korisničko ime ili lozinka.",
  requiredPerks: "Koga trebamo?",
  startDate: "Datum početka:",
  endDate: "Datum završetka:",
  dow0: "Nedjelja",
  dow1: "Ponedjeljak",
  dow2: "Utorak",
  dow3: "Srijeda",
  dow4: "Četvrtak",
  dow5: "Petak",
  dow6: "Subota",
  step: "Korak",
  of: "od",
  protectYourself: "Samo još da se zaštitiš",
  youFly: "Nakon toga letiš!",
  dragonName: "Kako se zove novi Zmaj?",
  yuchForm: "Nitko ne voli forme, ali smo blizu!",
  lastStep: "Nastavi",
  goBack: "Vrati se",
  actionPageTitle: "Aktualne akcije",
  volunteerListTitle: "Lista volontera",
  somethingsWrong: "Ups! Došlo je do pogreške :(",
  motivationMessageRegister: "Još malo pa si Zmaj!",
  motivationMessageLogin: "Hvala što si tu.",
  returnHome: "Vrati se na početnu",
  signUpForAction: "Prijavi se!",
  signUpForActionButtonTitle: "Prijavi se",
  moreInfoForAction: "Više o akciji",
  written: "napisano",
  reviews: "Recenzije",
  actionDescription: "OPIS",
  actionContacts: "KONTAKTI",
  actionGoBack: "Izađi",
  coordinator: "Koordinator",
  volunteer: "Volonter",
  administrator: "Administrator",
  documents: "Dokumenti",
  downloadFailure: "Neuspješno preuzimanje.",
  uploadFailure: "Neuspješan prijenos.",
  uploadDocumentFailure:
    "Neuspješan prijenos. Odaberite dateteku u PDF formatu manju od 10 MB.",
  documentListFetchFailure: "Neuspješno dohvaćanje dokumenata.",
  add: "Dodaj",
  close: "Zatvori",
  upload: "Prenesi",
  fileName: "Ime dokumenta",
  documentDeleteFailure: "Nemoguće brisanje.",
  edit: "Uredi",
  end: "Završi",
  createAction: "Kreiraj Akciju",
  needsApproval: "Čeka se potvrda",
  actionNeedsApproval:
    "Kordinator treba odobriti Vašu prijavu, bit ćete obaviješteni",
  other: "Ostalo",
  documentType: "Vrsta dokumenta",
  documentName: "Naziv dokumenta",
  confirmDelete: "Jeste li sigurni da želite izbrisati datoteku ",
  confirmLogout: "Jeste li sigurni da se želite odjaviti?",
  profile: "Profil",
  yes: "Da",
  no: "Ne",
  category: "Kategorija dokumenta",
  invalidFileType: "Nevažeća vrsta datoteke.",
  genericError: "Došlo je do pogreške. Molimo, pokušajte ponovo kasnije.",
  reviewCount_0: "{{count}} recenzija",
  reviewCount_1: "{{count}} recenzije",
  reviewCount_2: "{{count}} recenzija",
  noReviews: "Nema recenzija",
  heroTitle: "Postani Zmaj!",
  heroSubtitle: "Ovdje veliki pomažu malima",
  maxLength_0: "Ovo polje može sadržavati maksimalno {{count}} znak",
  maxLength_1: "Ovo polje može sadržavati maksimalno {{count}} znaka",
  maxLength_2: "Ovo polje može sadržavati maksimalno {{count}} znakova",
  createActionFormStep1: "Osnovne informacije",
  createActionFormStep2: "Osnovne informacije na engleskom",
  createActionFormStep3: "Mjesto i vrijeme",
  createActionFormStep4: "Vještine volontera",
  newActionTitle: "Nova akcija:",
  loginSessionExpired: "Sesija je istekla.",
  createActionFormNextStep: "Sljedeći korak",
  createActionFormPreviousStep: "Prethodni korak",
  createActionStep1Title: "*Naziv akcije",
  private: "Privatno",
  createActionStep1ShortDescription: "Kratki opis akcije",
  createActionStep1Description: "*Detaljan opis akcije",
  createActionStep2Title: "Naziv akcije na engleskom",
  createActionStep2ShortDescription: "Kratki opis akcije na engleskom",
  createActionStep2Description: "Detaljan opis akcije na engleskom",
  createActionStep3DateFrom: "Od:",
  createActionStep3DateTo: "Do:",
  createActionStep3Location: "*Lokacija",
  createActionStep4PerkType: "Kategorija",
  createActionStep4Perk: "Vještina",
  createActionDateRangeValidation:
    "Akcija ne može završiti prije nego je počela.",
  createActionStartDateValidation: "Datum početka ne može biti prije danas.",
  createActionRequiredPerksValidation:
    "Potrebno je odabrati najmanje 1 kategoriju.",
  actionSignUpTitle: "Prijava na akciju",
  actionSignUpSuccessful: "Prijava na akciju je uspješna",
  cancel: "Odustani",
  viewProfile: "Pogledaj profil",
  any: "Svi",
  categories: "Kategorije",
  aboutMe: "Podaci",
  skills: "Vještine",
  interests: "Interesi",
  mailSendSuccess: "Mail uspješno poslan. Provjerite svoj sandučić.",
  mailConfirmationSuccess: "E-mail adresa uspješno potvrđena.",
  passwordResetSuccess:
    "Lozinka uspješno promijenjena. Prijavite se za nastavak.",
  addReview: "Dodaj recenziju",
  submitCreateReview: "Dodaj",
  cancelCreateReview: "Odustani",
  reviewTextLabel: "Tekst recenzije",
  noCategory: "Nema kategorije",
  date: "Datum",
  actionListEmptyMessage:
    "Trenutno nema dostupnih volonterskih akcija. Provjerite ponovno kasnije.",
  filters: "Filtri",
  filteredActionListEmptyMessage:
    "Nema volonterskih akcija koje odgovaraju filteru.",
  couldNotUpdatePrivileges: "Ažuriranje privilegija nije uspjelo.",
  confirmPrivilegeChange: "Potvrdi promjenu privilegije.",
  privileges: "Privilegije",
  couldNotFetchUserPrivileges: "Nije moguće dohvatiti korisničke privilegije.",
  active: "Aktivne",
  finished: "Završene",
  archived: "Arhivirane",
  noUsersFound: "Nažalost, nema korisnika koji odgovaraju zadanim kriterijima.",
  dateOfBirth: "Datum rođenja",
  male: "Muško",
  female: "Žensko",
  address: "Adresa",
  phone: "Telefon",
  employmentStatus: "Status zaposlenja",
  student: "Student",
  employed: "Zaposlen",
  unemployed: "Nezaposlen",
  deleteFailed: "Brisanje nije uspjelo.",
  couldNotFetchCategories: "Nije moguće dohvatiti kategorije.",
  confirmCategoryDelete: "Jeste li sigurni da želite izbrisati kategoriju",
  confirmSubcategoryDelete:
    "Jeste li sigurni da želite izbrisati potkategoriju?",
  nameCroatian: "Hrvatski naziv",
  nameEnglish: "Engleski naziv",
  couldNotCreateCategory: "Neuspješno stvaranje kategorije.",
  couldNotCreateSubcategory: "Neuspješno stvaranje potkategorije.",
  retired: "Umirovljen",
  disciple: "Učenik",
  profession: "Struka",
  authenticationRequired: "Prijavite se kako biste pristupili stranici.",
  unauthorized: "Nemate ovlasti pristupiti toj stranici.",
  chooseFile: "Odaberi datoteku",
  documentStatusUpdateFailed: "Ažuriranje statusa dokumenta nije uspjelo.",
  approve: "Potvrdi",
  startTime: "Vrijeme početka:",
  endTime: "Vrijeme završetka:",
  disapprove: "Makni potvrdu",
  download: "Preuzmi",
  delete: "Izbriši",
  M: "Muško",
  F: "Žensko",
  approvedVolunteer: "Potvrđeni volonter",
  unknownUserPrivilege: "Nepoznate korisničke privilegije.",
  totalWorkHours: "Ukupno radno vrijeme",
  workHours: "Radni sati",
  reportSummary: "Sažetak izvješća",
  details: "Detalji",
  ageGroup: "Dobna skupina",
  generateReport: "Generiraj izvješće",
  dateToMustBeAfterDateFrom: "Datum do mora biti nakon datuma od.",
  dateFrom: "Datum od",
  dateTo: "Datum do",
  pleaseSetDateValues: "Odaberite datume.",
  totalActions: "Ukupno akcija",
  numberOfVolunteers: "Broj volontera",
  report: "Izvještaj",
  actionUserRequests: "Zahtjevi",
  acceptActionSignup: "Potvrdi korisnčku prijavu",
  accept: "Potvrdi",
  update: "Ažuriraj",
  decline: "Odbij",
  workTimeDesc: "Radno vrijeme skladišta i rad sa strankama:",
  warehousePhone: "Mobitel skladišta:",
  associationWorkTime: "Radno vrijeme udruge:",
  workDay: "Radnim danima: {{time}}",
  monThu: "Pon-čet: {{time}}",
  fridayHours: "Petkom: {{time}}",
  homeOffice: "Rad od kuće zbog COVID-19",
  whoAreWeTitle: "Tko smo?",
  whoAreWeBody:
    "Počelo je s par prijatelja i 30 čokolada. Sada je preraslo u nešto puno veće. Udruga Mali zmaj više od 10 godina poboljšava kvalitetu života siromašne i nezbrinute djece.",
  HowDoWeTitle: "Kako radimo?",
  HowDoWeBody:
    "Mi smo potpuno neprofitna i volonterska udruga koja se ne financira iz proračuna grada ni države, već ovisimo o donacijama privatnih osoba i tvrtki kao i rezultatima natječaja na koje prijavljujemo svoje programe i projekte.",
  WhatAreWeTitle: "Što radimo?",
  WhatAreWeBody:
    "Skrbimo za preko 150 obitelji i surađujemo s dvije sigurne kuće, malim domom za majke i djecu te prihvatilištem što čini ukupno 500 djece. Kroz različite projekte i radionice uveseljavamo i pomažemo djeci.",
  inspirationQuote: "Za nekom promijeniti svijet dovoljan je jedan čovjek.",
  getToKnowUs: "Upoznaj nas!",
  wizardStepOneTitle:
    "Zašto nam trebaju tvoje informacije? Ubrzavaju dosadnu papirologiju i tebi i nama.",
  wizardStepTwoTitle: "Tu nam reci s čime se baviš i koje vještine imaš.",
  wizardStepThreeTitle: "Izaberi sto bi htio raditi.",
  saveAndExit: "Spremi i izađi",
  quantity: "Količina",
  itemsShort: "Elementi",
  itemsLong: "Elemenata po stranici",
  categoryMenagement: "Upravljanje kategorijama",
  confirmDeleteReview: "Jeste li sigurni da želite izbrisati ovu recenziju?",
  noNotifications: "Nema notifikacija",
  actionApprovalProcessed: "Obrađena prijava na akciju",
  actionTitle: "Naziv akcije",
  actionApprovalApproved: "Odobrene kategorije",
  actionApprovalDenied: "Odbijene kategorije",
  newActionSignupTitle: "Nova prijava na akciju",
  newActionSignupDescription:
    "Novi korisnik, {{firstName}} {{lastName}}, prijavio/la se na akciju {{actionId}}.",
  actionEndedTitle: "Action ended",
  actionEndedDescription:
    "An action that you signed up for has recently ended. Check if you have logged all your working hours.",
  notConfirmed: "Nije potvrđena",
  confirmEmail: "Potvrdi",
  emailMustBeConfirmed: "*e-mail adresa mora biti potvrđena"
};
