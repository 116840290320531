import styled from "styled-components";

export const LocationLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 8px;
  background: #394441;
  border-radius: 8px;
  column-gap: 5px;
`;

export const LocationTitle = styled.p`
  font-weight: 300;
  color: white;
  margin-block: 5px;
`;
