import { ReactElement } from "react";
import {
  MForm,
  PageContainer
} from "../../components/shared/layout/LayoutElements";
import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { postRegister } from "../../api/register";
import { useHistory } from "react-router-dom";
import RegisterFormData from "./models/RegisterFormData";
import RegisterDTO from "./models/RegisterDTO";
import ErrorInfo from "../../constants/ErrorInfo";
import { BAD_REQUEST } from "../../constants/errorConstants";
import { useTranslation } from "react-i18next";
import Register from "../../components/app/Register";
import {
  REGEX_EMAIL,
  REGEX_NAME_SURNAME,
  REGEX_PASSWORD
} from "../../constants/regexConstants";

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  passwordRepeat: "",
  notifications: false
} as RegisterFormData;

const RegisterPage = (): ReactElement => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    // must be within the component in order to use the useTranslation hook
    email: yup
      .string()
      .matches(REGEX_EMAIL, t("validEmail"))
      .required(t("required")),
    password: yup
      .string()
      .min(8, t("validPasswordLength"))
      .max(64, t("maxLength64"))
      .matches(REGEX_PASSWORD, t("validPasswordFormat"))
      .required(t("required")),
    passwordRepeat: yup
      .string()
      .required(t("required"))
      .oneOf([yup.ref("password"), null], t("validPasswordMatch")),
    firstName: yup
      .string()
      .required(t("required"))
      .matches(REGEX_NAME_SURNAME, t("validName"))
      .max(64, t("maxLength64")),
    lastName: yup
      .string()
      .required(t("required"))
      .matches(REGEX_NAME_SURNAME, t("validLastName"))
      .max(64, t("maxLength64"))
  });

  const history = useHistory();
  const onSubmit = (
    values: RegisterFormData,
    { setErrors, setSubmitting }: FormikHelpers<RegisterFormData>
  ) => {
    postRegister(values as RegisterDTO)
      .then(() => {
        history.push("/prijava", {
          snackbarData: {
            severity: "success",
            messageKey: "registerSuccess"
          }
        });
      })
      .catch((error) => {
        if (error?.response?.status == BAD_REQUEST) {
          setErrors({ email: t("emailInUse") });
        } else {
          history.push("/greska", {
            status: error?.response?.data?.status,
            message: error?.response?.data?.message,
            description: error?.response?.data?.description
          } as ErrorInfo);
        }
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <>
      <PageContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
        >
          {({ handleSubmit, handleChange }: FormikProps<RegisterFormData>) => (
            <MForm onSubmit={handleSubmit} onChange={handleChange}>
              <Register />
            </MForm>
          )}
        </Formik>
      </PageContainer>
    </>
  );
};

export default RegisterPage;
